import { useState, useCallback, useMemo } from 'react';
import GrowerAPI from '../api/grower';
import { useStateContext } from '../utils/stateContext';
import { sortData } from '../utils/commonFunctions';
import { defaultData } from './growerDetail/defaultData';
import { toast } from '@tcc/labz-design-system';
import { useTranslation } from 'react-i18next';

const useImportFieldviewData = () => {
  const [loadingFieldViewData, setLoadingFieldViewData] = useState(false);
  const { initialState, setRows, setInitialState, rows } = useStateContext();
  const { t } = useTranslation();

  const importData = useCallback(() => {
    setLoadingFieldViewData(true);
    setInitialState({ ...initialState, selectedRows: [], selectedIndexes: [] });
    return GrowerAPI.getFieldViewData({ user_email: initialState.sharedState.email })
      .then(({ data }) => {
        let sortedData;
        if (data?.status === 200) {
          sortedData = sortData(data?.body, 'field_name').map((x) => ({
            ...x,
            address: 'sub',
          }));
        } else {
          sortedData = defaultData;
        }
        setRows(sortedData);
      })
      .catch((err) => toast.error(t('ALERT.MSG21')))
      .finally(() => {
        setLoadingFieldViewData(false);
      });
  }, [initialState, setInitialState, setRows]);

  const totalArea = useMemo(() => {
    return rows.reduce((sum, field) => sum + (field.area || 0), 0).toFixed(2);
  }, [rows]);

  return { loadingFieldViewData, importData, totalArea };
};

export default useImportFieldviewData;
