export const classNames = {
  container: 'flex w-full',
  form: 'flex w-full p-4 gap-4 h-full justify-between',
  noteText: 'ml-4 mt-4 text-text-1 font-light',
  editIconContainer: 'flex items-center justify-end',
  sectionContainer: 'flex flex-col grow bg-[#F0F9FD] border-[#0091DF4A] rounded-2xl m-2',
  sectionTitle: 'flex items-center justify-between my-2 bg-primary-800 text-white px-4 py-2',
  sectionTitleText: 'text-base ',
  flexWrap: 'flex flex-wrap',
  flexGap: 'flex gap-8 my-2 justify-between',
  flexGapLarge: 'flex gap-12 my-2 mb-4',
  textSm: 'text-sm',
  textBaseBold: 'text-base font-bold',
  textBlue: 'text-sm text-[#0B617F] pl-2 mt-4 px-4 w-[230px]',
  buttonIcon: 'h-5 w-5 text-gray-500 hover:text-gray-700',
  fieldDivider: 'border-[#0091DF4A]',
  fieldContainer: 'flex flex-col w-full border-b border-[#0091DF4A] px-4',
  fieldHeaders: 'font-light',
};
