import React from 'react';
import PredimaLogo from '../../icons/PredimaLogo';

const classNames = {
  predimaLogo: 'w-12 h-12',
  predimaTitle:
    'relative font-helvetica font-light text-[22px] text-text-1 font-weight italic tracking-widest',
  offerName: 'absolute top-[5px] ml-1 font-bold text-lg w-[200px]',
};

interface PredimaOfferLogoProps {
  offerName: 'Leaf' | 'One' | 'Flexx';
}

const PredimaOfferLogo = ({ offerName }: PredimaOfferLogoProps) => {
  return (
    <div className={'flex gap-1 items-center'}>
      <PredimaLogo className={classNames.predimaLogo} />
      <span className={classNames.predimaTitle}>
        PreDiMa
        <span className={classNames.offerName}>{offerName}</span>
      </span>
    </div>
  );
};

export default PredimaOfferLogo;
