import dayjs from 'dayjs';

export const getTreatments1 = (array) => {
  return array.reduce((acc, init) => {
    return {
      ...acc,
      [`${init.pd_window}id`]: init.id,
      [`${init.pd_window}id`]: init.id,
      [`${init.pd_window}pd_window`]: init.pd_window,
      [`${init.pd_window}product`]: init.product,
      [`${init.pd_window}dosage`]: init.dosage,
      [`${init.pd_window}sent`]: init.sent,
    };
  }, {});
};

export const getTreatments = (array) => {
  return array.reduce((acc, init) => {
    return { ...acc, [`${init.t_window}`]: init };
  }, {});
};

export const getTreatments2 = (spray_dates) => {
  return spray_dates.reduce((acc, init) => {
    return { ...acc, [init.spray_window]: init };
  }, {});
};
export const getTreatments3 = (spray_dates) => {
  let rec_spray_date = spray_dates.find((x) => {
    if (x.spray_window === 'T1') {
      return x.rec_spray_date;
    } else if (x.spray_window === 'T2') {
      return x.rec_spray_date;
    } else if (x.spray_window === 'T3') {
      return x.rec_spray_date;
    }
    return '';
  });
  return rec_spray_date?.rec_spray_date ? rec_spray_date?.rec_spray_date : null;
};

export const getRisk = (array) => {
  let date2 = dayjs(new Date()).format('YYYY-MM-DD');
  const properties = ['septoriose', 'rouillebrune', 'rouillejaune', 'fusariose'];
  const highestValues: Record<string, number> = {};

  properties.forEach((property) => {
    const values = array.map((entry) => {
      return dayjs(entry.date).isAfter(date2) ? entry[property] : 0;
    });
    const maxValue = Math.max(...values);
    highestValues[property] = maxValue;
  });
  highestValues['global_risk'] = Math.max(...Object.values(highestValues));
  return highestValues;
};

export const getRiskValues = (array) => {
  let result = array.reduce((acc, item: Record<string, number>) => {
    ['septoriose', 'rouillebrune', 'rouillejaune', 'fusariose', 'global_risk'].forEach((key) => {
      if (key === 'global_risk') {
        acc[key] = acc[key] || [];
        acc[key].push({
          date: item.date,
          value: Math.max(...Object.values(item).filter((x) => !isNaN(x))),
        });
      } else {
        acc[key] = acc[key] || [];
        acc[key].push({ date: item.date, value: item[key] });
      }
    });
    return acc;
  }, {});
  return result;
};

export const getCurrentValue = (array, value) => {
  return array.find((x) => x.bbch_code === value);
};

export function validateMandatoryProperties(array, mandatoryProperties) {
  return array.reduce((isValid, obj) => {
    if (!isValid) {
      return false;
    }
    for (const prop of mandatoryProperties) {
      if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '') {
        return false;
      }
    }
    return true;
  }, true);
}
