import React from 'react';
import { Button } from '@tcc/labz-design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const classNames = {
  buttonsContainer: 'flex justify-center gap-4',
  proceedButton: 'w-[200px] place-self-end',
  backButton: 'w-[200px] place-self-start bg-primary-500',
};

const BackNextButtons = ({
  loading = false,
  onNextClick,
  nextDisabled = false,
  backDisabled = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={classNames.buttonsContainer}>
      <Button
        disabled={loading || backDisabled}
        onClick={() => navigate(-1)}
        className={classNames.backButton}
      >
        {t('OFFERS.BUTTONS.BACK')}
      </Button>
      <Button
        disabled={loading || nextDisabled}
        onClick={onNextClick}
        className={classNames.proceedButton}
      >
        {loading ? t('OFFERS.BUTTONS.UPDATING') : t('OFFERS.BUTTONS.PROCEED')}
      </Button>
    </div>
  );
};

export default BackNextButtons;
