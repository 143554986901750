import { Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';

interface SoilOption {
  nom_arvalis: string;
}

const SoilTypeSelect = ({ value, soilTypes, onChange, region, className }) => {
  return (
    <Autocomplete
      className={className}
      value={value}
      onChange={onChange}
      options={[...new Set(soilTypes.filter((x) => x.region === region))]}
      getOptionLabel={(option: SoilOption) => option.nom_arvalis}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${t('FIELD.SOIL_TYPE')}*`}
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default SoilTypeSelect;
