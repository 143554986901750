import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Box, Button, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useStateContext } from '../../utils/stateContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { DateComponent, ISODateFormat } from '../../utils/commonFunctions';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';
import CustomModal from '../CustomModal';
import { toast } from '@tcc/labz-design-system';

const ActualSprayDateModal = () => {
  const { t } = useTranslation();
  const {
    getData,
    agronomy: {
      selectedField: [selectedField],
      selectedTreatment: [selectedTreatment],
      ActualSprayDateModal: [isActualSprayDateModalOpen, { off: closeActualSprayDateModal }],
      handleSetActualSprayDate,
      handleDeleteTreatments,
    },
  } = useStateContext();

  const validationSchema = Yup.object().shape({
    treatment_date: Yup.date().required(),
    dose_rate: Yup.string().required(),
    product: Yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (selectedField && selectedTreatment) {
      reset({
        ...selectedTreatment,
        treatment_date: selectedTreatment?.treatment_date
          ? dayjs(selectedTreatment?.treatment_date, ISODateFormat)
          : null,
      });
    }
  }, [selectedField, selectedTreatment, reset]);

  const submit = async (data) => {
    handleSetActualSprayDate({
      data,
      t_window: selectedTreatment.t_window,
      onSuccess: async () => {
        await getData();
        toast.success(t('AGRONOMY.BBCH.TREATMENT_DETAILS_ADDED'));
      },
      onFail: () => {
        toast.error(t('ALERT.MSG2'));
      },
    });
    handleClose();
  };

  const handleDelete = () => {
    handleDeleteTreatments({
      treatment: selectedTreatment,
      onSuccess: async () => {
        await getData();
        toast.success(t('AGRONOMY.BBCH.TREATMENT_DETAILS_DELETED'));
      },
      onFail: () => {
        toast.error(t('AGRONOMY.PRODUCT_DETAILS_NOT_FOUND'));
      },
    });
    handleClose();
  };

  const handleClose = () => {
    reset({});
    closeActualSprayDateModal();
  };

  const { t_window } = selectedTreatment;

  return (
    <CustomModal
      title={''}
      open={isActualSprayDateModalOpen}
      handleClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          padding: '1vw',
          height: 'auto',
          bgcolor: 'white',
          borderRadius: '10px',
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(submit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.2vw',
            width: '100%',
            padding: '0vw 1vw',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 550, fontSize: '0.8em', mt: 1 }}>
              {t('AGRONOMY.ACT_DATE')}
            </Typography>
            <CancelIcon onClick={handleClose} fontSize="small" cursor="pointer" />
          </Box>
          <FormControl variant="outlined" size="small" fullWidth>
            <DateComponent
              name="treatment_date"
              label={t('AGRONOMY.PICK_DATE')}
              control={control}
              errors={errors}
            />
            <span style={{ color: 'red', fontSize: '12px' }}>{errors.treatment_date?.message}</span>
          </FormControl>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel htmlFor="outlined-adornment-farmName">{'Product'}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-farmName"
              type="text"
              label="Product"
              {...register('product')}
              error={errors.product ? true : false}
            />
            <span style={{ color: 'red', fontSize: '12px' }}>{errors.product?.message}</span>
          </FormControl>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel htmlFor="outlined-adornment-farmName">{'Dose rate'}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-farmName"
              type="text"
              label="Dose rate"
              {...register('dose_rate')}
              error={errors.dose_rate ? true : false}
            />
            <span style={{ color: 'red', fontSize: '12px' }}>{errors.dose_rate?.message}</span>
          </FormControl>
          <Box>
            <Typography variant="body2" sx={{ m: 0, p: 0 }}>
              T-window
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1vw' }}>
              <Button
                sx={{ width: 30, height: 22 }}
                variant={t_window === 'T1' ? 'contained' : 'outlined'}
                value={t_window}
                onClick={() => {}}
                disabled={t_window !== 'T1'}
              >
                T1
              </Button>
              <Button
                sx={{ width: 30, height: 22 }}
                variant={t_window === 'T2' ? 'contained' : 'outlined'}
                value={t_window}
                disabled={t_window !== 'T2'}
              >
                T2
              </Button>
              <Button
                sx={{ width: 30, height: 22 }}
                variant={t_window === 'T3' ? 'contained' : 'outlined'}
                value={t_window}
                disabled={t_window !== 'T3'}
              >
                T3
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              mb: 2,
              gap: 2,
            }}
          >
            <Button
              onClick={() => {
                handleDelete();
              }}
              sx={{ width: 150, height: 35 }}
              variant="outlined"
              disabled={!getValues()['id']}
            >
              <Typography variant="body2">{t('GENERIC.DELETE')}</Typography>
            </Button>
            <Button
              type="submit"
              sx={{ width: 150, height: 35 }}
              variant="contained"
              disabled={!isValid}
            >
              {t('GENERIC.SAVE')}
            </Button>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};
export default ActualSprayDateModal;
