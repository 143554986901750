import React, { Component } from 'react';
import { Box } from '@mui/material';

export class ErrorBoundaryForComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          >
            <h4>{this.props.message}</h4>
          </Box>
        </React.Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundaryForComponent;
