import { useMemo } from 'react';
import useToggle from './useToggle';

function useBoolean(
  initialValue: boolean = false
): [boolean, { toggle: (value?: boolean) => void; on: () => void; off: () => void }] {
  const [value, toggle] = useToggle(initialValue);

  const handlers = useMemo(
    () => ({
      toggle: (newValue?: boolean) => {
        if (typeof newValue === 'boolean') {
          toggle(newValue);
        } else {
          toggle(!value);
        }
      },
      on: () => toggle(true),
      off: () => toggle(false),
    }),
    [toggle, value]
  );

  return [value, handlers];
}

export default useBoolean;
