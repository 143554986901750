import axios from 'axios';
import { DeleteUserResponse } from './types';

const User = new (class {
  getAuthToken(): string | null {
    const token = localStorage.getItem('token');
    return token ? JSON.parse(token) : null;
  }

  headers() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': 'PreDimalAvamME5uQNXLWIw190',
      authorizationToken: `Bearer ${this.getAuthToken()}`,
    };
  }

  // Convert into arrow function fixes the issue of "this" referece being lost, and avoid the use of bind.
  addUser = async () => {
    const headers = this.headers();
    const res = await axios.post('/api/users/register', {}, { headers });
    return res.data;
  };

  async getUserByEmail({ email }: { email: string }) {
    const res = await axios.get(`/api/users/email/${email}`, { headers: this.headers() });

    return {
      data: res.data,
      status: res.status,
    };
  }

  async deleteUser({ id }: { id: number }): Promise<DeleteUserResponse> {
    const res = await axios.delete(`/users/${id}`, { headers: this.headers() });
    return res.data;
  }
})();

export default User;
