import React from 'react';
import { formatDate, ISODateFormat } from '../../utils/commonFunctions';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Typography } from '@mui/material';
import EmptyCellIndicator from './EmptyCellIndicator';

interface Treatment {
  treatment_date?: string;
}
interface RowData {
  treatments: {
    T1?: Treatment;
    T2?: Treatment;
    T3?: Treatment;
  };
}
interface TreatmentDateCellProps {
  rowData: RowData;
  onClick: (rowData: RowData) => void;
}

const TreatmentDateCell: React.FC<TreatmentDateCellProps> = ({ rowData, onClick }) => {
  const { treatments } = rowData;
  const treatment = treatments?.T3 || treatments?.T2 || treatments?.T1 || {};
  const { treatment_date } = treatment;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
      <Typography fontSize={13} noWrap textAlign={'center'}>
        {treatment_date ? (
          formatDate(treatment_date, { inputFormat: ISODateFormat })
        ) : (
          <EmptyCellIndicator />
        )}
      </Typography>
      <EditOutlinedIcon
        className="tooltiptext1"
        color="primary"
        fontSize="small"
        onClick={() => onClick(rowData)}
        sx={{ position: 'absolute', right: 0 }}
      />
    </Box>
  );
};

export default TreatmentDateCell;
