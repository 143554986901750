import React from 'react';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <span className="flex justify-center items-center w-full h-[450px]">
      {t('GENERIC.PAGE_NOT_FOUND')}
    </span>
  );
};

export default PageNotFound;
