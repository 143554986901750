import { useState } from 'react';
import LoginAPI from '../../api/login';
import UserAPI from '../../api/user';
import { useTranslation } from 'react-i18next';
import { toast } from '@tcc/labz-design-system';
import { useStateContext } from '../../utils/stateContext';
import useProfileForm from './useProfileForm';

const useProfile = () => {
  const { t } = useTranslation();
  const { userDetails, logout } = useStateContext();

  const [, setLanguage] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const handleSave = async (data) => {
    try {
      await LoginAPI.setAccountInfo({
        UID: userDetails?.gigya_uuid,
        email: data.email,
        profile: JSON.stringify({ firstName: data.firstName, lastName: data.lastName }),
        lang: data.lang,
      });
      toast.success(t('PROFILE.SAVED_SUCCESSFULLY'));
    } catch (err) {
      toast.error(t('PROFILE.SAVE_FAILED'));
    }
  };

  const deleteAccount = async () => {
    if (!userDetails?.id || !userDetails?.gigya_uuid) {
      toast.error(t('PROFILE.CANNOT_DELETE'));
      return;
    }

    try {
      await UserAPI.deleteUser({ id: userDetails?.id });
      await LoginAPI.deleteAccount(userDetails?.gigya_uuid);
      toast.success(t('PROFILE.DELETED_SUCCESSFULLY'));
      setTimeout(() => {
        logout();
      }, 3000);
    } catch (err) {
      toast.error(t('PROFILE.CANNOT_DELETE'));
    }
  };

  const form = useProfileForm(handleSave);

  return {
    form,
    setLanguage,
    openModal,
    setOpenModal,
    deleteAccount,
  };
};

export default useProfile;
