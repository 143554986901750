import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { t } from 'i18next';
import React from 'react';
import { useStateContext } from '../../../utils/stateContext';

const classNames = {
  container: 'flex flex-col space-y-4 justify-center',
  row: 'flex items-center space-x-4',
  infoItem: 'flex items-center',
  infoText: 'flex items-center ml-2 gap-4',
  label: 'w-[100px] text-sm text-gray-600',
  value: 'text-sm font-medium',
  divider: 'h-8 w-px bg-gray-300',
  addressRow: 'flex justify-between',
};

const GrowerSummary = () => {
  const { sharedState } = useStateContext();

  const InfoItem = ({ icon, label, value }) => (
    <div className={classNames.infoItem}>
      {icon}
      <div className={classNames.infoText}>
        <p className={classNames.label}>{label}</p>
        <div className={classNames.divider} />
        <p className={classNames.value}>{value || '--'}</p>
      </div>
    </div>
  );

  return (
    <div className={classNames.container}>
      <InfoItem
        icon={<CalendarMonthOutlinedIcon />}
        label={t('GENERIC.START_DATE')}
        value={sharedState.start_date || '--'}
      />
      <InfoItem icon={<HouseOutlinedIcon />} label={t('GENERIC.FARM')} value={sharedState.name} />
      <InfoItem
        icon={<PersonOutlinedIcon />}
        label={t('GENERIC.CONTACT')}
        value={sharedState.contact_name}
      />
      <InfoItem
        icon={<PhoneAndroidOutlinedIcon />}
        label={t('GENERIC.MOBILE_NO')}
        value={sharedState.mobile_no || 'not available'}
      />
      <InfoItem
        icon={<EmailOutlinedIcon />}
        label={t('REGISTRATION.EMAIL')}
        value={sharedState.email}
      />
      <InfoItem
        icon={<PhoneOutlinedIcon />}
        label={t('GENERIC.TELEPHONE')}
        value={sharedState.telephone_no}
      />
      <InfoItem
        icon={<HouseOutlinedIcon />}
        label={t('GENERIC.ADDRESS')}
        value={sharedState.address1}
      />
      {/* <InfoItem 
          icon={<LocalShippingOutlinedIcon/>}
          label={t('GENERIC.DEL_ADDRESS')}
          value={sharedState.address2}
        /> */}
      {/* Uncomment and adjust as needed
        <EditIcon
          onClick={() => {
            setEditable(true);
            navigate('/home/addgrower');
          }}
        />
        */}
    </div>
  );
};

export default GrowerSummary;
