import { Box, Typography } from '@mui/material';
import React from 'react';
import { HtmlTooltip, ISODateFormat, formatDate } from '../../utils/commonFunctions';
import Dot from '../Layout/Dot';

interface RiskValue {
  date?: string;
  value?: string;
}

interface CardProps {
  riskValues?: RiskValue[];
}

const Card: React.FC<CardProps> = ({ riskValues }) => {
  if (!riskValues) return null;
  return (
    <Box sx={{ padding: 1 }}>
      {riskValues.map((item, index) => (
        <Typography sx={{ fontWeight: 500 }} key={index}>
          {formatDate(item?.date, { inputFormat: ISODateFormat })}{' '}
          <Dot color={item?.value} size="0.5em" />
        </Typography>
      ))}
    </Box>
  );
};

interface RiskCellProps {
  rowData: {
    legend?: Record<string, RiskValue[]>;
    risk?: Record<string, string>;
  };
  columnName: string;
}

const RiskCell: React.FC<RiskCellProps> = ({ rowData, columnName }) => (
  <HtmlTooltip title={<Card riskValues={rowData.legend?.[columnName]} />}>
    <Box>
      <Dot color={rowData.risk?.[columnName]} size="0.7em" />
    </Box>
  </HtmlTooltip>
);

export default RiskCell;
