import { useEffect, useState, useMemo } from 'react';

const usePagination = (dataList: any[]) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(dataList.length);
    setPage(0);
  }, [dataList]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = useMemo(() => {
    return dataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [dataList, page, rowsPerPage]);

  return {
    paginatedData,
    page,
    rowsPerPage,
    count,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};

export default usePagination;
