import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../utils/stateContext';
import { toast } from '@tcc/labz-design-system';
import dayjs from 'dayjs';
import { t } from 'i18next';
import useGrowerForm from './useGrowerForm';
import { useState } from 'react';
import GrowerAPI from '../../api/grower';
import { schemas } from '../../api/client';
import { ISODateFormat } from '../../utils/commonFunctions';

type GrowerUpdateContractAndStatusIn = schemas['GrowerUpdateContractAndStatusIn'];

const useGrower = () => {
  const navigate = useNavigate();
  const { sharedState, setInitialState, userDetails, initialState } = useStateContext();
  const [editable, setEditable] = useState<boolean>(false);
  const [editFromReview, setEditFromReview] = useState<boolean>(false);

  const submit = async (data) => {
    const formatDate = (date) => dayjs(date).format(ISODateFormat);

    let body: GrowerUpdateContractAndStatusIn = {
      ...data,
      start_date: formatDate(data.start_date),
      uuid: sharedState?.uuid,
      france_details: {
        ...data?.france_details,
        date_echeance: formatDate(data.france_details.date_echeance),
      },
    };

    const isUpdate = editable || editFromReview;
    const apiMethod = isUpdate ? GrowerAPI.updateGrower : GrowerAPI.addGrower;

    if (!isUpdate) {
      //@ts-ignore
      body = { ...body, created_by: userDetails.id };
    }

    try {
      const response = await apiMethod(body);

      if (response.ok) {
        if (isUpdate && editFromReview) {
          setInitialState({ ...initialState, sharedState: { ...response.body } });
        }

        const successMessage = isUpdate ? t('ALERT.MSG1') : t('ALERT.MSG3');
        toast.success(successMessage);

        setTimeout(() => {
          if (isUpdate && editFromReview) {
            navigate('/home/reviewdetails');
          } else {
            navigate('/home');
          }
          setEditable(false);
          setEditFromReview(false);
        }, 1000);
      } else {
        throw new Error(isUpdate ? t('ALERT.MSG2') : `${t('ALERT.MSG4')} ${data.email}`);
      }
    } catch (error) {
      toast.error(error.message);
      setTimeout(() => {
        setEditable(false);
        navigate('/home');
      }, 1000);
    }
  };

  const form = useGrowerForm(
    submit,
    editable,
    editFromReview,
    sharedState,
    userDetails,
    'GROWER_FORM'
  );

  return {
    form,
    editable,
  };
};

export default useGrower;
