import { useState } from 'react';
import { DefaultValues } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useFormFactory from '../useFormFactory';

type ProfileFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  language: string;
};

const useProfileForm = (onSubmit) => {
  const { t } = useTranslation();
  const [, setLanguage] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('REGISTER.ERR_FIRST')),
    lastName: Yup.string().required(t('REGISTER.ERR_LAST')),
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
    language: Yup.string().required(t('REGISTER.ERR_LANG')),
  });

  const defaultValues: DefaultValues<ProfileFormValues> = {
    firstName: '',
    lastName: '',
    email: '',
    language: 'en',
  };

  const formFactoryProps = {
    defaultValues,
    validationSchema,
    onSubmit,
  };

  const profileForm = useFormFactory(formFactoryProps);

  return {
    ...profileForm,
    openModal,
    setOpenModal,
    setLanguage,
  };
};

export default useProfileForm;
