import { Button } from '@tcc/labz-design-system';
import PredimaOfferLogo from './PredimaOfferLogo';
import { useStateContext } from '../../../utils/stateContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const classNames = {
  offerFooter:
    'flex w-full h-20 p-4 mt-4 justify-between items-center border rounded-xl border-gray-300',
  offerFooterLeft: 'flex flex-col w-1/4',
  fieldNames: 'text-text-1 w-[300px] font-light whitespace-nowrap overflow-hidden text-ellipsis',
  offerFootercCenter: 'flex flex-col items-center',
  offerFooterRight: '',
  offerFooterDivider: 'h-full w-[1px] bg-gray-300',
  price: 'text-4xl text-primary-600 font-bold',
  totalPriceEuroSign: 'text-text-1 text-sm font-light mr-2',
  summary: '',
};

const OfferFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    offers: { selectedOffer, totalPrice, selectedFields },
  } = useStateContext();

  const handleProceedClick = () => {
    navigate('/home/fielddetails');
  };

  return (
    <div className={classNames.offerFooter}>
      <div className={classNames.offerFooterLeft}>
        <span>
          <PredimaOfferLogo offerName={selectedOffer?.name} />
        </span>
        <span className={classNames.fieldNames}>
          {selectedFields.map((field) => field.field_name).join(' + ')}
        </span>
      </div>
      <span className={classNames.offerFooterDivider}></span>
      <div className={classNames.offerFootercCenter}>
        <span className={classNames.summary}>{t('OFFERS.FOOTER.PRICE_TOTAL_AREA')}</span>
        <span className={classNames.price}>
          <span className={classNames.totalPriceEuroSign}>€</span>
          {totalPrice}
        </span>
      </div>
      <span className={classNames.offerFooterDivider}></span>
      <div className={classNames.offerFooterRight}>
        <Button disabled={!selectedFields.length || !selectedOffer} onClick={handleProceedClick}>
          {t('OFFERS.BUTTONS.PROCEED')}
        </Button>
      </div>
    </div>
  );
};

export default OfferFooter;
