import React, { useState } from 'react';
import useSimulator from '../../../hooks/useSimulator';

const classNames = {
  container: 'w-full flex flex-col items-center gap-10 pt-5',
  formFrame: 'bg-white p-5 rounded-lg shadow-custom-modal min-w-xl max-w-xl text-center p-10',
  title: 'text-primary-600 text-xl pt-5 mb-5',
  subtitle: 'text-blue-600',
  form: 'flex flex-col items-center',
  label: 'mt-2 text-blue-800',
  input: 'p-2 mt-1 border border-gray-300 rounded w-1/2',
  button: 'mt-5 p-2 rounded bg-primary-800 text-white cursor-pointer hover:bg-primary-900',
  result: 'mt-5 mb-5 text-center',
  price: 'font-bold',
  table: 'w-full mt-5 border-collapse',
  th: 'border border-gray-300 bg-gray-200 p-2 text-center',
  td: 'border border-gray-300 p-2 text-center',
  difference: 'mt-5 text-center text-xl font-bold',
  hidden: 'hidden',
};

const PreDiMaSimulator: React.FC = () => {
  const [param1, setParam1] = useState<number>(1.1);
  const [param2, setParam2] = useState<string>('Nord');
  const [param3, setParam3] = useState<number>(69);
  const [param4, setParam4] = useState<string>('');

  const {
    result,
    fixedPrices,
    averageCalculated,
    totalCalculated,
    averageFixed,
    totalFixed,
    difference,
    showDifference,
    calculatePrice,
  } = useSimulator();

  const handleCalculate = () => {
    calculatePrice(
      parseFloat(param1.toString()),
      parseFloat(param2),
      parseFloat(param3.toString()),
      parseInt(param4)
    );
  };

  return (
    <div className={classNames.container}>
      <h1 className={classNames.title}>Simulateur PreDiMa</h1>
      <div className={classNames.formFrame}>
        <form id="price-form" className={classNames.form} onSubmit={(e) => e.preventDefault()}>
          <label htmlFor="param1" className={classNames.label}>
            Sensibilité variétale:
          </label>
          <select
            id="param1"
            value={param1}
            onChange={(e) => setParam1(parseFloat(e.target.value))}
            required
            className={classNames.input}
          >
            <option value="1.1">Très sensible</option>
            <option value="1">Moyennement sensible</option>
            <option value="0.9">Faiblement sensible</option>
          </select>

          <label htmlFor="param2" className={classNames.label}>
            Régions:
          </label>
          <select
            id="param2"
            value={param2}
            onChange={(e) => setParam2(e.target.value)}
            required
            className={classNames.input}
          >
            <option value="1.1">Nord</option>
            <option value="1">Ouest</option>
            <option value="0.9">Est</option>
          </select>

          <label htmlFor="param3" className={classNames.label}>
            Offre:
          </label>
          <select
            id="param3"
            value={param3}
            onChange={(e) => setParam3(parseFloat(e.target.value))}
            required
            className={classNames.input}
          >
            <option value="69">PreDiMa Protect Leaf</option>
            <option value="69">PreDiMa Flex</option>
            <option value="49">PreDiMa One</option>
          </select>

          <label htmlFor="param4" className={classNames.label}>
            Ha engagés:
          </label>
          <input
            type="number"
            id="param4"
            value={param4}
            onChange={(e) => setParam4(e.target.value)}
            required
            className={classNames.input}
          />

          <button type="button" onClick={handleCalculate} className={classNames.button}>
            Calculer mon investissement
          </button>
        </form>

        <div id="result" className={classNames.result}>
          <h2>
            Votre investissement PreDiMa:{' '}
            <span id="price" className={classNames.price}>
              {result.toFixed(1)}
            </span>
          </h2>
        </div>

        <table id="price-table" className={classNames.table}>
          <thead>
            <tr>
              <th className={classNames.th}>Year</th>
              <th className={classNames.th}>2025</th>
              <th className={classNames.th}>2026</th>
              <th className={classNames.th}>2027</th>
              <th className={classNames.th}>2028</th>
              <th className={classNames.th}>Average</th>
              <th className={classNames.th}>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="param3-header">Investissement dans la protection de votre blé</td>
              <td id="price-2025">{result.toFixed(1)}</td>
              <td id="price-2026">{result.toFixed(1)}</td>
              <td id="price-2027">{result.toFixed(1)}</td>
              <td id="price-2028">{result.toFixed(1)}</td>
              <td id="average-calculated">{averageCalculated.toFixed(1)}</td>
              <td id="total-calculated">{totalCalculated.toFixed(1)}</td>
            </tr>
            <tr>
              <td>Achat classique</td>
              <td id="fixed-2025">
                {fixedPrices.price2025 ? fixedPrices.price2025.toFixed(1) : 0}
              </td>
              <td id="fixed-2026">
                {fixedPrices.price2026 ? fixedPrices.price2026.toFixed(1) : 0}
              </td>
              <td id="fixed-2027">
                {fixedPrices.price2027 ? fixedPrices.price2027.toFixed(1) : 0}
              </td>
              <td id="fixed-2028">
                {fixedPrices.price2028 ? fixedPrices.price2028.toFixed(1) : 0}
              </td>
              <td id="average-fixed">{averageFixed.toFixed(1)}</td>
              <td id="total-fixed">{totalFixed.toFixed(1)}</td>
            </tr>
          </tbody>
        </table>

        {showDifference && (
          <div id="difference" className={classNames.difference}>
            <h2>
              Difference: <span id="price-difference">{difference.toFixed(1)} €</span>
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreDiMaSimulator;
