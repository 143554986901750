import { useMemo, useState } from 'react';
import { toast } from '@tcc/labz-design-system';
import { t } from 'i18next';
import { getDistributorOffers } from '../../api/offer';
import useFetch from '../useFetch';
import useListToggle from '../useListToggle';
import GrowerAPI from '../../api/grower';
import { useNavigate } from 'react-router-dom';

const useOffers = ({ distributorId = 1 }: { distributorId: number }) => {
  const navigate = useNavigate();
  const [selectedOffer, setSelectedOffer] = useState<any>();
  const [selectedFields, toggleSelectField, isFieldSelected, setSelectedFields] = useListToggle(
    [],
    'uuid'
  );
  const [updatingFieldviewData, setUpdatingFieldviewData] = useState<boolean>(false);

  const [data, error, loading, fetchOffers] = useFetch({
    fetchFunction: async () => getDistributorOffers(distributorId),
    onFail: () => toast.error(t('ALERT.MSG11')),
    immediate: false,
  });

  const totalArea = useMemo(
    () => selectedFields.reduce((sum, field) => sum + (field.area || 0), 0),
    [selectedFields]
  );

  const totalPrice = useMemo(() => {
    if (!selectedOffer) return '0.00';

    const price = totalArea * (selectedOffer?.price || 0);
    return price.toFixed(2);
  }, [selectedFields, selectedOffer, totalArea]);

  const updateFieldviewData = async (fields) => {
    try {
      setUpdatingFieldviewData(true);
      const response = await GrowerAPI.updateFieldviewData(fields);
      if (response.status === 200) {
        toast.success(t('ALERT.MSG9'));
        setTimeout(() => {
          navigate(`/home/deliveryDetails`);
        }, 1000);
      } else {
        toast.error(t('ALERT.MSG21'));
      }
    } catch (error) {
      toast.error(t('ALERT.MSG21'));
    } finally {
      setUpdatingFieldviewData(false);
    }
  };

  return {
    offers: data,
    error,
    loading,
    selectedOffer,
    setSelectedOffer,
    toggleSelectField,
    isFieldSelected,
    updateFieldviewData,
    selectedFields,
    setSelectedFields,
    totalPrice,
    totalArea,
    updatingFieldviewData,
    fetchOffers,
  };
};

export default useOffers;
