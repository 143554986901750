// CrossedEye.js
import React from 'react';

const CrossedEye = ({ width = 25, height = 24, fill = 'currentColor', className = '' }) => {
  return (
    <svg
      viewBox="0 0 25 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9194 11.5322C23.1809 10.0553 22.2117 8.71681 21.0117 7.65527L17.0886 11.5322V11.9476C17.0886 14.763 14.8271 17.0245 12.0117 17.0245H11.5963L9.104 19.5168C10.0271 19.7014 10.9963 19.8399 11.9655 19.8399C17.1809 19.8399 21.704 16.7937 23.8732 12.4091C24.0578 12.086 24.0578 11.8091 23.9194 11.5322ZM22.3962 2.53202L21.4269 1.56279C21.15 1.28586 20.6423 1.33202 20.3192 1.70125L16.95 5.07048C15.4269 4.42433 13.7654 4.10125 12.0115 4.10125C6.79615 4.10125 2.27308 7.1474 0.103846 11.532C-0.0346154 11.8089 -0.0346154 12.132 0.103846 12.3628C1.11923 14.4397 2.64231 16.1474 4.53462 17.4397L1.76538 20.2551C1.44231 20.5782 1.39615 21.0859 1.62692 21.3628L2.59615 22.332C2.87308 22.6089 3.38077 22.5628 3.70385 22.1936L22.2577 3.63971C22.6269 3.31663 22.6731 2.80894 22.3962 2.53202ZM6.93457 11.9475C6.93457 9.13214 9.19611 6.87061 12.0115 6.87061C12.9346 6.87061 13.7653 7.10137 14.5038 7.51676L13.1192 8.90137C12.75 8.80907 12.3807 8.71676 12.0115 8.71676C10.2115 8.71676 8.78072 10.1475 8.78072 11.9475C8.78072 12.3168 8.87303 12.686 8.96534 13.0552L7.58072 14.4398C7.16534 13.7014 6.93457 12.8706 6.93457 11.9475Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CrossedEye;
