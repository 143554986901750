import { Paper, Box, Typography } from '@mui/material';
import React from 'react';
import EmptyCellIndicator from './EmptyCellIndicator';
interface BBCH {
  start_date?: string;
  end_date?: string;
}
interface RowData {
  current_bbch_stage?: string;
  bbch?: BBCH;
}
interface BBCHCellProps {
  rowData: RowData;
}

const BBCHCell: React.FC<BBCHCellProps> = ({ rowData }) => (
  <>
    {rowData.current_bbch_stage ? (
      rowData.current_bbch_stage?.replace('Z', '')
    ) : (
      <EmptyCellIndicator />
    )}
    {rowData.bbch && (
      <Paper className="tooltiptext">
        <Box sx={{ padding: '0vw 0.3vw' }}>
          <Typography variant="body2" fontSize="1em">
            <b>Start</b>
            <br />
            {rowData.bbch.start_date}
          </Typography>
          <Typography variant="body2" fontSize="1em">
            <b>End</b>
            <br />
            {rowData.bbch.end_date}
          </Typography>
        </Box>
      </Paper>
    )}
  </>
);

export default BBCHCell;
