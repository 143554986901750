import { useState } from 'react';

// Define interfaces for the data structure
interface RecSprayDate {
  rec_spray_date: string;
}

interface Product {
  product: string;
}

interface TotalVolume {
  total_volume: number;
}

interface TopDeliveryDate {
  top_delivery_date: string;
}

type OfferName = 'Predima Flexx' | 'Predima Leaf' | 'Predima One';

interface FieldData {
  id: string;
  distributor: string;
  contact_name: string;
  grower_name: string;
  farm_name: string;
  offer_name: OfferName;
  field_name: string;
  hectares: number;
  global_risk: number;
  rec_spray_date: {
    T1: RecSprayDate;
    T2: RecSprayDate;
    T3: RecSprayDate;
  };
  product: {
    T1: Product;
    T2: Product;
    T3: Product;
  };
  total_volume: {
    T1: TotalVolume;
    T2: TotalVolume;
    T3: TotalVolume;
  };
  top_delivery_date: {
    T1: TopDeliveryDate;
    T2: TopDeliveryDate;
    T3: TopDeliveryDate;
  };
}

// Define the hook return type
interface UseLogisticReturn {
  fieldList: FieldData[];
  handleSelectField: (selectedField: FieldData, tWindow: string) => void;
  handleSendToShipment: () => void;
  selectedFields: (FieldData & { tWindow: string; selectedTwindows: string[] })[];
  selectedShipmentFields: (FieldData & { tWindow: string; selectedTwindows: string[] })[];
}

const mockData: FieldData[] = [
  {
    id: '1',
    distributor: 'Distributor A',
    contact_name: 'John Doe',
    grower_name: 'Grower A',
    farm_name: 'Farm A',
    offer_name: 'Predima Flexx',
    field_name: 'Field A',
    hectares: 50,
    global_risk: 1,
    rec_spray_date: {
      T1: { rec_spray_date: '2023-10-01' },
      T2: { rec_spray_date: '2023-10-15' },
      T3: { rec_spray_date: '2023-11-01' },
    },
    product: {
      T1: { product: 'Product A' },
      T2: { product: 'Product B' },
      T3: { product: 'Product C' },
    },
    total_volume: {
      T1: { total_volume: 1000 },
      T2: { total_volume: 1500 },
      T3: { total_volume: 2000 },
    },
    top_delivery_date: {
      T1: { top_delivery_date: '2023-10-05' },
      T2: { top_delivery_date: '2023-10-20' },
      T3: { top_delivery_date: '2023-11-05' },
    },
  },
  {
    id: '2',
    distributor: 'Distributor B',
    contact_name: 'Jane Smith',
    grower_name: 'Grower B',
    farm_name: 'Farm B',
    offer_name: 'Predima Leaf',
    field_name: 'Field B',
    hectares: 75,
    global_risk: 5,
    rec_spray_date: {
      T1: { rec_spray_date: '2023-10-02' },
      T2: { rec_spray_date: '2023-10-16' },
      T3: { rec_spray_date: '2023-11-02' },
    },
    product: {
      T1: { product: 'Product D' },
      T2: { product: 'Product E' },
      T3: { product: 'Product F' },
    },
    total_volume: {
      T1: { total_volume: 1200 },
      T2: { total_volume: 1800 },
      T3: { total_volume: 2500 },
    },
    top_delivery_date: {
      T1: { top_delivery_date: '2023-10-06' },
      T2: { top_delivery_date: '2023-10-22' },
      T3: { top_delivery_date: '2023-11-06' },
    },
  },
  {
    id: '3',
    distributor: 'Distributor B',
    contact_name: 'Jane Smith',
    grower_name: 'Grower B',
    farm_name: 'Farm B',
    offer_name: 'Predima One',
    field_name: 'Field B',
    hectares: 75,
    global_risk: 5,
    rec_spray_date: {
      T1: { rec_spray_date: '2023-10-02' },
      T2: { rec_spray_date: '2023-10-16' },
      T3: { rec_spray_date: '2023-11-02' },
    },
    product: {
      T1: { product: 'Product D' },
      T2: { product: 'Product E' },
      T3: { product: 'Product F' },
    },
    total_volume: {
      T1: { total_volume: 1200 },
      T2: { total_volume: 1800 },
      T3: { total_volume: 2500 },
    },
    top_delivery_date: {
      T1: { top_delivery_date: '2023-10-06' },
      T2: { top_delivery_date: '2023-10-22' },
      T3: { top_delivery_date: '2023-11-06' },
    },
  },
];

const useLogistic = (): UseLogisticReturn => {
  const [selectedFields, setSelectedFields] = useState<
    (FieldData & { tWindow: string; selectedTwindows: string[] })[]
  >([]);

  const [selectedShipmentFields, setSelectedShipmentFields] = useState<
    (FieldData & { tWindow: string; selectedTwindows: string[] })[]
  >([]);

  const handleSelectField = (selectedField: FieldData, tWindow: string) => {
    const fieldIndex = selectedFields.findIndex((field) => field.id === selectedField.id);

    if (fieldIndex > -1) {
      // Field exists, toggle the tWindow
      const updatedField = { ...selectedFields[fieldIndex] };
      const twindows = updatedField.selectedTwindows || [];

      if (twindows.includes(tWindow)) {
        // Remove tWindow if it's already selected
        updatedField.selectedTwindows = twindows.filter((t) => t !== tWindow);

        // If no twindows are left, remove the field completely
        if (updatedField.selectedTwindows.length === 0) {
          setSelectedFields(selectedFields.filter((field) => field.id !== selectedField.id));
          return; // Exit the function
        }
      } else {
        // Add tWindow to the selectedTwindows array
        updatedField.selectedTwindows = [...twindows, tWindow];
      }

      // Update the selectedFields array
      const newSelectedFields = [...selectedFields];
      newSelectedFields[fieldIndex] = updatedField;
      setSelectedFields(newSelectedFields);
    } else {
      // Field does not exist, add it with the selectedTwindows property
      const fieldWithWindow = { ...selectedField, tWindow, selectedTwindows: [tWindow] };
      setSelectedFields([...selectedFields, fieldWithWindow]);
    }
  };

  const handleSendToShipment = () => {
    setSelectedShipmentFields([...selectedFields]);
    setSelectedFields([]);
  };

  return {
    fieldList: mockData,
    handleSelectField,
    handleSendToShipment,
    selectedFields,
    selectedShipmentFields,
  };
};

export default useLogistic;
