import React from 'react';
import MultiToggleButton, { Option } from '../MultiToggleButton';

const options: Option[] = [
  {
    label: 'Active',
    path: '',
  },
  {
    label: 'Ongoing',
    path: '',
  },
  {
    label: 'Completed',
    path: '',
  },
];

const FieldStatusFilter = () => {
  return <MultiToggleButton options={options} />;
};

export default FieldStatusFilter;
