// hooks/useDeliveryForm.ts
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../utils/stateContext';
import * as yup from 'yup';
import useFormFactory from '../useFormFactory';
import { useTranslation } from 'react-i18next';

interface DeliveryFormValues {
  order_del_address: string;
  order_del_city: string;
  order_del_postal_code: string;
  order_contact_name: string;
  order_mobile_no: string;
  order_add_info?: string;
}

const useDeliveryForm = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const { initialState, setInitialState, setGrowerState } = useStateContext();

  const { sharedState } = initialState;
  const { t } = useTranslation('', { keyPrefix: 'DELIVERY_FORM' });
  const onSubmit = async (data) => {
    setGrowerState((prev) => ({ ...prev, ...data }));
    setInitialState((prev) => ({
      ...prev,
      growerState: { ...prev.growerState, ...data },
    }));
    navigate('/home/reviewDetails');
  };

  const validationSchema = yup.object().shape({
    order_del_address: yup.string().required(t('FIELDS.ADDRESS.REQUIRED')),
    order_del_city: yup.string().required(t('FIELDS.CITY.REQUIRED')),
    order_del_postal_code: yup.string().required(t('FIELDS.POSTAL_CODE.REQUIRED')),
    order_contact_name: yup.string().required(t('FIELDS.CONTACT_NAME.REQUIRED')),
    order_mobile_no: yup.string().required(t('FIELDS.MOBILE_NO.REQUIRED')),
    order_add_info: yup.string(),
  });

  const defaultValues: DeliveryFormValues = {
    order_del_address: '',
    order_del_city: '',
    order_del_postal_code: '',
    order_contact_name: '',
    order_mobile_no: '',
    order_add_info: '',
  };

  const sameAsFarmValues = useMemo(
    () => ({
      order_del_address: sharedState.address1,
      order_del_city: sharedState.city1,
      order_del_postal_code: sharedState.postal_code1,
      order_contact_name: sharedState.contact_name,
      order_mobile_no: sharedState.mobile_no,
    }),
    [sharedState]
  );

  const formFactoryProps = {
    defaultValues,
    validationSchema,
    onSubmit,
  };

  const form = useFormFactory(formFactoryProps);
  const { fields, trigger } = form;

  const handleCheckboxChange = () => {
    if (!checked) {
      fields.order_del_address.setValue(sameAsFarmValues.order_del_address);
      fields.order_del_city.setValue(sameAsFarmValues.order_del_city);
      fields.order_del_postal_code.setValue(sameAsFarmValues.order_del_postal_code);
      fields.order_contact_name.setValue(sameAsFarmValues.order_contact_name);
      fields.order_mobile_no.setValue(sameAsFarmValues.order_mobile_no);
      setGrowerState((prev) => ({ ...prev, ...sameAsFarmValues }));
      setInitialState((prev) => ({
        ...prev,
        growerState: { ...prev.growerState, ...sameAsFarmValues },
      }));
    } else {
      fields.order_del_address.setValue('');
      fields.order_del_city.setValue('');
      fields.order_del_postal_code.setValue('');
      fields.order_contact_name.setValue('');
      fields.order_mobile_no.setValue('');
    }
    setChecked(!checked);
    trigger();
  };

  return {
    ...form,
    handleCheckboxChange,
    checked,
  };
};

export default useDeliveryForm;
