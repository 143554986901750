interface User {
  id: number;
  name: string;
  email: string;
  distributor_id: number;
  country_id: number;
  role: number;
  gigya_uuid: string;
  set_active: boolean;
  updated_at: string;
  created_at: string;
}

export interface AddUserResponse {
  status: number;
  message: string;
  user: User;
}

export interface DeleteUserResponse {
  status: number;
  message: string;
}

export enum CountryCode {
  FR = 'FR',
  DE = 'DE',
}
