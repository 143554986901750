import React from 'react';
import PredimaTable from '../PredimaTable';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../utils/stateContext';

const classNames = {
  tableContainer: 'mt-1 h-full',
};

const ProductShipmentTable = () => {
  const { t } = useTranslation();
  const { logistic } = useStateContext();
  const { selectedShipmentFields } = logistic;

  const columns = [
    {
      name: 'distributor',
      label: 'Distributor name',
      color: '#0091DF',
      search: true,
    },
    {
      name: 'contact_name',
      label: 'Contact Name',
      color: '#0091DF',
      search: true,
    },
    {
      name: 'grower_name',
      label: 'Grower name',
      color: '#0091DF',
      search: true,
    },
    {
      name: 'farm_name',
      label: t('GENERIC.FARM'),
      color: '#0091DF',
      search: true,
    },
    {
      name: 'offer_name',
      label: 'Offer name',
      color: '#0091DF',
      search: true,
    },
    {
      name: 'field_name',
      label: t('GENERIC.FIELD'),
      color: '#0091DF',
      search: true,
    },
    {
      name: 'hectares',
      label: t('AGRONOMY.HECTARE'),
      color: '#0091DF',
      search: true,
    },
    {
      name: 'product.T1.product',
      label: t('AGRONOMY.PRODUCT'),
      color: '#0091DF',
      search: true,
    },
    {
      name: 'rec_spray_date.T1.rec_spray_date',
      label: t('AGRONOMY.REC_DATE'),
      color: '#02A8A8',
      date: true,
      fieldSelection: true,
      inputFormat: 'date',
      valueGetter: () => 'a',
    },
    {
      name: 'total_volume.T1.total_volume',
      label: 'Total volume',
      color: '#02A8A8',
      inputFormat: 'number',
    },
    {
      name: 'top_delivery_date.T1.top_delivery_date',
      label: 'Top delivery date',
      color: '#02A8A8',
      date: true,
      inputFormat: 'date',
    },
  ];

  return (
    <div className={classNames.tableContainer}>
      <PredimaTable
        data={selectedShipmentFields}
        columns={columns}
        showFilters={false}
        onSelect={() => {}}
        onSelectAll={() => {}}
        loading={false}
        selectable={false}
        allSelected={false}
        selectedRows={[]}
      />
    </div>
  );
};

export default ProductShipmentTable;
