import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../utils/stateContext';
import { toast } from '@tcc/labz-design-system';
import LoginAPI from '../../api/login';
import UserAPI from '../../api/user';
import useLoginForm from './useLoginForm';

const defaultLocale = 'en';

const useLogin = () => {
  const { t, i18n } = useTranslation();

  const { setUser } = useStateContext();
  const navigate = useNavigate();

  const handleLogin = async ({ email, password }: { email: string; password: string }) => {
    localStorage.clear();

    const loginResponse = await LoginAPI.loginUser({
      loginID: email,
      password,
    });

    if (loginResponse.statusCode === 409 || loginResponse.statusCode !== 200) {
      toast.error(t('ALERT.MSG13'));
      return;
    }

    const tokenResponse = await LoginAPI.getJWT(loginResponse.UID);
    if (tokenResponse.statusCode !== 200) {
      toast.error(t('ALERT.MSG11'));
      return;
    }

    const id_token = tokenResponse.id_token;
    localStorage.setItem('token', JSON.stringify(id_token));

    try {
      const predimaUserResponse = await UserAPI.getUserByEmail({ email });

      localStorage.setItem('userDetails', JSON.stringify(predimaUserResponse.data));

      const accountResponse = await LoginAPI.accountSearch(email);
      const accountLocale = accountResponse.results[0]?.profile?.locale || defaultLocale;
      localStorage.setItem('locale', JSON.stringify(accountLocale));

      setUser(loginResponse);
      await i18n.changeLanguage(accountLocale);
      localStorage.setItem('user', JSON.stringify(loginResponse));

      navigate('/home');
    } catch (error) {
      toast.error(t('ALERT.MSG11'));
      console.error(error);
    }
  };

  const form = useLoginForm(handleLogin);

  return {
    handleLogin,
    form,
  };
};

export default useLogin;
