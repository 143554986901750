import React, { useState, Children } from 'react';

const SlidingTiles = ({
  width = 380,
  height = 380,
  scale = 0.75,
  overlap = 0.85,
  padding = 0,
  children,
}) => {
  const [activeIndex, setActiveIndex] = useState(1);

  const tiles = Children.toArray(children);

  const translateDistance = width * overlap;

  const getPosition = (index) => {
    const position = index - activeIndex;

    const baseClasses = 'absolute transition-all duration-500 rounded-lg shadow-xl cursor-pointer';

    const styles = {
      width: `${width}px`,
      height: `${height}px`,
      padding: `${padding}px`,
    };

    if (position === 0) {
      return {
        className: `${baseClasses} z-30`,
        style: {
          ...styles,
          transform: 'translateX(0) scale(1)',
        },
      };
    } else if (position === -1 || position === 2) {
      return {
        className: `${baseClasses} z-20 opacity-70 hover:opacity-90`,
        style: {
          ...styles,
          transform: `translateX(-${translateDistance}px) scale(${scale})`,
        },
      };
    } else if (position === 1 || position === -2) {
      return {
        className: `${baseClasses} z-20 opacity-70 hover:opacity-90`,
        style: {
          ...styles,
          transform: `translateX(${translateDistance}px) scale(${scale})`,
        },
      };
    }
    return {
      className: `${baseClasses} invisible`,
      style: styles,
    };
  };

  const handleClick = (newIndex) => {
    if (newIndex !== activeIndex) {
      setActiveIndex(newIndex);
    }
  };

  return (
    <div className="flex justify-center items-center w-full">
      <div
        className="relative flex justify-center items-center"
        style={{
          width: `${width * 2}px`,
          height: `${height}px`,
        }}
      >
        {tiles.map((tile, index) => {
          const { className, style } = getPosition(index);
          return (
            <div key={index} className={className} style={style} onClick={() => handleClick(index)}>
              {tile}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SlidingTiles;
