import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@mui/material/styles';
import './i18n/config';
import ErrorBoundry from '../src/utils/ErrorBoundry';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#0B617F',
    },
    secondary: {
      main: '#0091DF',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

const AppRender = () => {
  const { t } = useTranslation();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <ErrorBoundry message={t('ALERT.MSG21')}>
          <App />
        </ErrorBoundry>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

root.render(<AppRender />);
