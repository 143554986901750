import React from 'react';
import PredimaTable from '../PredimaTable';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../utils/stateContext';

const classNames = {
  tableContainer: 'mt-1 h-full',
};

const FieldSelecetionTable = () => {
  const { t } = useTranslation();
  const { logistic } = useStateContext();
  const { fieldList } = logistic;

  const columns = [
    {
      name: 'distributor',
      label: t('GENERIC.DISTRIBUTOR'),
      color: '#0091DF',
      search: true,
      rowSpan: 2,
    },
    {
      name: 'contact_name',
      label: 'Contact Name',
      color: '#0091DF',
      search: true,
      rowSpan: 2,
    },
    {
      name: 'grower_name',
      label: 'Grower name',
      color: '#0091DF',
      search: true,
      rowSpan: 2,
    },
    {
      name: 'farm_name',
      label: t('GENERIC.FARM'),
      color: '#0091DF',
      search: true,
      rowSpan: 2,
    },
    {
      name: 'offer_name',
      label: 'Offer name',
      color: '#0091DF',
      search: true,
      rowSpan: 2,
    },
    {
      name: 'field_name',
      label: t('GENERIC.FIELD'),
      color: '#0091DF',
      search: true,
      rowSpan: 2,
    },
    {
      name: 'hectares',
      label: t('AGRONOMY.HECTARE'),
      color: '#0091DF',
      search: true,
      rowSpan: 2,
    },
    {
      name: 'global_risk',
      label: t('AGRONOMY.GLOBAL_RISK'),
      color: '#0091DF',
      search: true,
      rowSpan: 2,
    },
    { name: 'T1', colSpan: 4, groupHeader: true, color: '#02A8A8' }, // Group header for T1
    { name: 'T2', colSpan: 4, groupHeader: true, color: '#00A6E1' }, // Group header for T2
    { name: 'T3', colSpan: 4, groupHeader: true, color: '#01849D' }, // Group header for T3
    {
      name: 'rec_spray_date.T1.rec_spray_date',
      label: t('AGRONOMY.REC_DATE'),
      color: '#02A8A8',
      group: true,
      fieldSelection: true,
      border: true,
    },
    {
      name: 'product.T1.product',
      label: t('AGRONOMY.PRODUCT'),
      color: '#02A8A8',
      search: true,
      group: true,
      treatment: true,
      inputFormat: 'string',
      tWindow: 'T1',
    },
    {
      name: 'total_volume.T1.total_volume',
      label: 'Total volume',
      color: '#02A8A8',
      inputFormat: 'number',
      group: true,
      treatment: true,
      tWindow: 'T1',
    },
    {
      name: 'top_delivery_date.T1.top_delivery_date',
      label: 'Top delivery date',
      color: '#02A8A8',
      date: true,
      inputFormat: 'date',
      group: true,
      treatment: true,
      tWindow: 'T1',
    },
    {
      name: 'rec_spray_date.T2.rec_spray_date',
      label: t('AGRONOMY.REC_DATE'),
      color: '#00A6E1',
      fieldSelection: true,
      inputFormat: 'date',
      group: true,
    },
    {
      name: 'product.T2.product',
      label: t('AGRONOMY.PRODUCT'),
      color: '#00A6E1',
      search: true,
      group: true,
      treatment: true,
      inputFormat: 'string',
      tWindow: 'T2',
    },
    {
      name: 'total_volume.T2.total_volume',
      label: 'Total volume',
      color: '#00A6E1',
      inputFormat: 'number',
      group: true,
      treatment: true,
      tWindow: 'T2',
    },
    {
      name: 'top_delivery_date.T2.top_delivery_date',
      label: 'Top delivery date',
      color: '#00A6E1',
      date: true,
      inputFormat: 'date',
      group: true,
      treatment: true,
      tWindow: 'T2',
    },
    {
      name: 'rec_spray_date.T3.rec_spray_date',
      label: t('AGRONOMY.REC_DATE'),
      color: '#01849D',
      fieldSelection: true,
      group: true,
    },
    {
      name: 'product.T3.product',
      label: t('AGRONOMY.PRODUCT'),
      color: '#01849D',
      search: true,
      group: true,
      treatment: true,
      inputFormat: 'string',
      tWindow: 'T3',
    },
    {
      name: 'total_volume.T3.total_volume',
      label: 'Total volume',
      color: '#01849D',
      inputFormat: 'number',
      group: true,
      treatment: true,
      tWindow: 'T3',
    },
    {
      name: 'top_delivery_date.T3.top_delivery_date',
      label: 'Top delivery date',
      color: '#01849D',
      date: true,
      inputFormat: 'date',
      group: true,
      treatment: true,
      tWindow: 'T3',
    },
  ];

  return (
    <div className={classNames.tableContainer}>
      <PredimaTable
        data={fieldList}
        columns={columns}
        showFilters={false}
        onSelect={() => {}}
        onSelectAll={() => {}}
        loading={false}
        selectable={false}
        allSelected={false}
        selectedRows={[]}
      />
    </div>
  );
};

export default FieldSelecetionTable;
