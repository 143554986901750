import React from 'react';
import Application from '../../Agronomy/Application';
import Summary from '../../Agronomy/Summary';
import ArvalisDisclaimer from '../../Agronomy/ArvalisDisclaimer';
import DynamicTabs from '../../Layout/shared/DynamicTabs'; // Adjust the import path as necessary
import { useTranslation } from 'react-i18next';

const Agronomy = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS.AGRONOMY' });

  const tabs = [
    {
      id: 'agronomy_summary',
      name: t('SUMMARY'),
      children: <Summary />,
    },
    {
      id: 'agronomy_application',
      name: t('APPLICATION'),
      children: <Application />,
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <DynamicTabs tabs={tabs} />
      <ArvalisDisclaimer />
    </div>
  );
};

export default Agronomy;
