import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import './agronomy.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { formatDate, dateFormat } from '../../utils/commonFunctions';

const defaultCriteria = {
  fromDate: dayjs(null),
  toDate: dayjs(null),
  range: false,
  applied: false,
};

const DateFilter = ({
  column: { name, label },
  onChange = () => {},
  criteria = defaultCriteria,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  const [_criteria, setCriteria] = useState(criteria);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (field, newValue) => {
    setCriteria((prevCriteria) => ({ ...prevCriteria, [field]: newValue }));
  };

  const handleReset = () => {
    onChange(name, {
      ...defaultCriteria,
    });
    setCriteria(defaultCriteria);
    setAnchorEl(null);
  };

  const handleApply = () => {
    if (_criteria.range) {
      if (!_criteria.fromDate.isValid() && !_criteria.toDate.isValid()) return;
    }
    onChange(name, { ..._criteria, applied: true });
    handleClose();
  };

  const getFormatted = (date) => {
    if (!date.isValid()) return '--/--/--';
    return formatDate(date);
  };

  const { toDate, fromDate, range } = _criteria;
  const { applied } = criteria;
  const formattedToDate = getFormatted(toDate);
  const formattedFromDate = getFormatted(fromDate);

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Box
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {!applied && (
            <Typography fontSize={10} noWrap>
              --/--/--{' '}
            </Typography>
          )}
          {applied && (
            <Box>
              {range && (
                <>
                  <Typography noWrap fontSize={10} mb={'0.2rem'}>
                    {formattedFromDate}
                  </Typography>
                  <Typography noWrap fontSize={10}>
                    {formattedToDate}
                  </Typography>
                </>
              )}
              {!range && (
                <Typography noWrap fontSize={10}>
                  No date
                </Typography>
              )}
            </Box>
          )}
          <CalendarMonthIcon fontSize={'small'} color="primary" />
        </Box>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box sx={{ p: 3, width: 'auto' }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={range}
            >
              <Typography variant="body2" fontSize={13}>
                Filter {label} By:
              </Typography>
              <Box display={'flex'} direction={'row'}>
                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  onClick={() => handleChange('range', false)}
                  label={<Typography variant="body2">No date</Typography>}
                />
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" />}
                  onClick={() => handleChange('range', true)}
                  label={<Typography variant="body2">Date range</Typography>}
                />
              </Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, my: 2 }}>
                  <DatePicker
                    views={['month', 'day']}
                    slotProps={{ textField: { size: 'small' } }}
                    label="From"
                    format={dateFormat}
                    value={fromDate}
                    onChange={(newValue) => handleChange('fromDate', newValue)}
                    name="fromDate"
                    disabled={!range}
                  />
                  <DatePicker
                    views={['month', 'day']}
                    slotProps={{ textField: { size: 'small' } }}
                    label="To"
                    format={dateFormat}
                    value={toDate}
                    onChange={(newValue) => handleChange('toDate', newValue)}
                    name="toDate"
                    disabled={!range}
                  />
                </Box>
              </LocalizationProvider>
            </RadioGroup>
            <Box display={'flex'} direction="row" justifyContent={'space-between'} mt={2} gap={1}>
              <Button onClick={handleReset} sx={{ height: 35, flex: 1 }} variant="outlined">
                {t('RESET.TEXT')}
              </Button>
              <Button onClick={handleApply} sx={{ height: 35, flex: 1 }} variant="contained">
                {t('GENERIC.SUBMIT')}
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Menu>
    </Box>
  );
};

export default DateFilter;
