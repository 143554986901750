import { Box, Paper, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { HtmlTooltip, StyledTableCell1 } from '../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import EmptyCellIndicator from './EmptyCellIndicator';

const style = {
  fontSize: 13,
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: 3,
};

interface VarietySowingDateTableProps {
  variety: string;
  sowing_date: string;
}

const VarietySowingDateTable: React.FC<VarietySowingDateTableProps> = ({
  variety,
  sowing_date,
}) => {
  const { t } = useTranslation();

  const headerStyles = { background: '#0091DF', textAlign: 'center' };

  return (
    <Box>
      <Paper sx={{ p: 0.5 }}>
        <Table sx={{ width: 250 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell1 sx={headerStyles} align="center">
                <Typography>{t('FIELD.VARIETY')}</Typography>
              </StyledTableCell1>
              <StyledTableCell1 sx={headerStyles} align="center">
                <Typography>{t('FIELD.SOWING_DATE')}</Typography>
              </StyledTableCell1>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell1 align="center">{variety}</StyledTableCell1>
              <StyledTableCell1 align="center">{sowing_date}</StyledTableCell1>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

interface FieldNameCellProps {
  rowData: {
    order: any;
    id: string;
    field_name: string;
  };
}

const FieldNameCell: React.FC<FieldNameCellProps> = ({ rowData }: FieldNameCellProps) => {
  const { order, id: field_id } = rowData;

  if (!order) return <EmptyCellIndicator />;

  const field = order?.order_fields?.find((field) => field.id === field_id);

  return (
    <HtmlTooltip
      title={<VarietySowingDateTable variety={field?.variety} sowing_date={field?.sowing_date} />}
    >
      <Typography sx={style} noWrap>
        {rowData?.field_name}
      </Typography>
    </HtmlTooltip>
  );
};

export default FieldNameCell;
