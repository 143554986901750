// GrowerDetail.js
import { useStateContext } from '../../../../utils/stateContext';
import { useTranslation } from 'react-i18next';
import { classNames } from './classNames';

const GrowerDetail = () => {
  const { t } = useTranslation();

  const {
    initialState: { sharedState },
  } = useStateContext();

  return (
    <div className={classNames.sectionContainer}>
      <div className={classNames.sectionTitle}>
        <p className={classNames.sectionTitleText}>
          {t('GENERIC.FARM')} {t('GENERIC.DETAILS')}
        </p>
      </div>
      <div className="flex flex-col items-center gap-4 px-4">
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('GENERIC.START_DATE')}</span>
          <span>{sharedState.start_date}</span>
        </div>
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('GENERIC.FARM')}</span>
          <span>{sharedState.name}</span>
        </div>
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('GENERIC.CONTACT')}</span>
          <span>{sharedState.contact_name}</span>
        </div>
      </div>
    </div>
  );
};

export default GrowerDetail;
