import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material/';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const classNames = {
  container: 'flex place-content-end p-1',
  paginationInfo: 'flex items-center gap-1',
  select: 'ml-1 mr-2 border border-gray-300 rounded px-7 h-8 text-xs',
};

interface Props {
  count: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  page: number;
  rowsPerPage: number;
}

const CustomTablePagination = ({
  count,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const start = page * rowsPerPage + 1;
  const end = Math.min(start + rowsPerPage - 1, count);

  return (
    <div className={classNames.container}>
      <div className={classNames.paginationInfo}>
        <Typography variant="body2">{t('GENERIC.ROWS_PER_PAGE')}:</Typography>
        <select
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          className={classNames.select}
        >
          {[10, 20, 30, 50, 75, 100].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <Typography variant="body2">{`${start}–${end} of ${count}`}</Typography>
      </div>
      <div>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        </IconButton>
      </div>
    </div>
  );
};

export default CustomTablePagination;
