import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../../../utils/stateContext';
import { useTranslation } from 'react-i18next';
import { classNames } from './classNames';

const FieldDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    offers: { selectedFields },
  } = useStateContext();

  return (
    <div className={classNames.sectionContainer}>
      <div className={classNames.sectionTitle}>
        <p className={classNames.sectionTitleText}>{t('FIELD.DETAILS')}</p>
        <button
          onClick={() => {
            navigate('/home/fielddetails');
          }}
        >
          {/* Edit Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={classNames.buttonIcon}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            {/* SVG path for the edit icon */}
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
            <path
              fillRule="evenodd"
              d="M4 15a1 1 0 01-1-1v-1.586a1 1 0 01.293-.707l8-8a1 1 0 011.414 1.414l-8 8A1 1 0 014 13.414V14h1.586a1 1 0 01.707.293l8 8a1 1 0 01-1.414 1.414l-8-8A1 1 0 014 15z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className={classNames.fieldContainer}>
        {selectedFields.map((field, index) => (
          <div key={index}>
            <div className={classNames.flexGapLarge}>
              <div>
                <p className={classNames.textSm}>
                  <strong>{field.field_name}</strong>
                </p>
              </div>
            </div>
            <div className={classNames.flexGap}>
              <div>
                <p className={classNames.textSm}>
                  <span className={classNames.fieldHeaders}>{t('GENERIC.SIZE')}</span> <br />
                  {field.area}
                </p>
              </div>
              <div>
                <p className={classNames.textSm}>
                  <span className={classNames.fieldHeaders}>{t('FIELD.VARIETY')}</span> <br />
                  {field.variety}
                </p>
              </div>
              <div>
                <p className={classNames.textSm}>
                  <span className={classNames.fieldHeaders}>{t('FIELD.MIXED')}</span> <br />
                  {field.mixed_variety ? t('GENERIC.YES') : t('GENERIC.NO')}
                </p>
              </div>
            </div>
            {index < selectedFields.length - 1 && <hr className={classNames.fieldDivider} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FieldDetails;
