import { useState } from 'react';

// Define the structure of fixedPrices
interface FixedPrices {
  price2025: number;
  price2026: number;
  price2027: number;
  price2028: number;
}

const useSimulator = () => {
  const [result, setResult] = useState<number>(0);
  const [fixedPrices, setFixedPrices] = useState<FixedPrices>({
    price2025: 0,
    price2026: 0,
    price2027: 0,
    price2028: 0,
  });
  const [averageCalculated, setAverageCalculated] = useState<number>(0);
  const [totalCalculated, setTotalCalculated] = useState<number>(0);
  const [averageFixed, setAverageFixed] = useState<number>(0);
  const [totalFixed, setTotalFixed] = useState<number>(0);
  const [difference, setDifference] = useState<number>(0);
  const [showDifference, setShowDifference] = useState<boolean>(false);

  const calculatePrice = (param1: number, param2: any, param3: number, param4: number) => {
    // Validate if param4 is a multiple of 10
    if (param4 % 10 !== 0) {
      alert('Ha engagés must be a multiple of 10');
      return;
    }

    // Calculate the price for each year
    const price2025 = param1 * param2 * param3 * param4;

    // Update calculated prices
    setResult(price2025);
    setTotalCalculated(price2025 * 4);
    setAverageCalculated(price2025 / (4 * param4));

    // Calculate fixed prices based on parameter 2
    let fixedPrice2025 = 0,
      fixedPrice2026 = 0,
      fixedPrice2027 = 0,
      fixedPrice2028 = 0;

    if (param2 === 'Nord') {
      fixedPrice2025 = 87.7 * param4;
      fixedPrice2026 = 56.4 * param4;
      fixedPrice2027 = 87.7 * param4;
      fixedPrice2028 = 56.4 * param4;
    } else if (param2 === 'Est') {
      fixedPrice2025 = 72.6 * param4;
      fixedPrice2026 = 51.1 * param4;
      fixedPrice2027 = 72.6 * param4;
      fixedPrice2028 = 51.1 * param4;
    } else if (param2 === 'Ouest') {
      fixedPrice2025 = 83.2 * param4;
      fixedPrice2026 = 57.7 * param4;
      fixedPrice2027 = 83.2 * param4;
      fixedPrice2028 = 57.7 * param4;
    }

    // Update fixed prices
    setFixedPrices({
      price2025: fixedPrice2025,
      price2026: fixedPrice2026,
      price2027: fixedPrice2027,
      price2028: fixedPrice2028,
    });

    // Calculate total and average for fixed prices
    const totalFixed = fixedPrice2025 + fixedPrice2026 + fixedPrice2027 + fixedPrice2028;
    setTotalFixed(totalFixed);
    setAverageFixed(totalFixed / (4 * param4));

    // Calculate the difference
    const diff = totalFixed - price2025 * 4;
    setDifference(diff);
    setShowDifference(true);
  };

  return {
    result,
    fixedPrices,
    averageCalculated,
    totalCalculated,
    averageFixed,
    totalFixed,
    difference,
    showDifference,
    calculatePrice,
  };
};

export default useSimulator;
