import { useStateContext } from '../../../../utils/stateContext';
import { useTranslation } from 'react-i18next';
import { classNames } from './classNames';

const DeliveryDetails = () => {
  const { t } = useTranslation();
  const {
    initialState: { growerState },
  } = useStateContext();

  return (
    <div className={classNames.sectionContainer}>
      <div className={classNames.sectionTitle}>
        <p className={classNames.sectionTitleText}>
          {t('GENERIC.DELIVERY')} {t('GENERIC.DETAILS')}
        </p>
      </div>
      <div className="flex flex-col items-center gap-4 px-4">
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('GENERIC.CONTACT')}</span>
          <span>{growerState.order_contact_name}</span>
        </div>
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('GENERIC.MOBILE_NO')}</span>
          <span>{growerState.order_mobile_no}</span>
        </div>
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('FIELD.ADDRESS')}</span>
          <span>{growerState.order_del_address}</span>
        </div>
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('FIRSTMEETING.POSTAL_CODE')}</span>
          <span>{growerState.order_del_postal_code}</span>
        </div>
        <div className={'flex w-full justify-between'}>
          <span className={'font-light'}>{t('FIRSTMEETING.CITY')}</span>
          <span>{growerState.order_del_city}</span>
        </div>
        <span className={'font-light flex place-self-start'}>{t('DELIVERY.ADDITIONAL_INFO')}</span>
      </div>
      <p className={classNames.textBlue}>{growerState.order_add_info}</p>
    </div>
  );
};

export default DeliveryDetails;
