import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MultiToggleButton from '../MultiToggleButton';
import {
  Typography,
  Box,
  AppBar,
  Toolbar,
  Avatar,
  Menu,
  MenuItem,
  Badge,
  Divider,
} from '@mui/material';
import { NotificationsOutlined, OpenInFull } from '@mui/icons-material';

import BrandIcon from '../../images/brandlogo2x.png';
import GrowerAPI from '../../api/grower';
import { useStateContext } from '../../utils/stateContext';
import type { Notification } from '../../api/grower/types';

const Header = () => {
  const { t } = useTranslation();
  const { userData, user, logout, notifications, setNotifications } = useStateContext();

  const [badgeContent, setBadgeContent] = useState(0);

  const getNotifications = async () => {
    const data = await GrowerAPI.getNotifications();
    setNotifications(data);
    setBadgeContent(data.filter((item) => !item.is_read).length);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorNoteEl, setNoteAnchorEl] = React.useState<HTMLElement | null>(null);
  const openNote = Boolean(anchorNoteEl);

  const handleNoteClick = (event: React.MouseEvent<HTMLElement>) => {
    setNoteAnchorEl(event.currentTarget);
    getNotifications();
  };

  const handleNoteClose = () => {
    setNoteAnchorEl(null);
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="inherit" sx={{ height: '9%' }}>
        <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: '1vw',
              width: '70%',
            }}
          >
            <Link to="/home" style={{ width: '15%', padding: '0.5vw' }}>
              <img src={BrandIcon} alt="" />
            </Link>
            <Divider orientation="vertical" variant="middle" flexItem />
            <MultiToggleButton />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '0.5vw' }}>
            <Badge
              badgeContent={badgeContent}
              color="primary"
              sx={{ mr: 2 }}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openNote ? 'true' : undefined}
              onClick={handleNoteClick}
            >
              <NotificationsOutlined color="primary" />
            </Badge>
            <Menu
              anchorEl={anchorNoteEl}
              open={openNote}
              onClose={handleNoteClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '40ch',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: '0 10px',
                }}
              >
                <Typography variant="body2" fontWeight="550" color="primary">
                  {t('NOTIFICATIONS')}
                </Typography>
                <Link to={'/home/notifications'}>
                  <OpenInFull color="primary" />
                </Link>
              </Box>

              {notifications.map((notification: Notification) => (
                <MenuItem key={notification.id} onClick={handleClose}>
                  <Typography variant="body2">
                    {notification.created_date}
                    <br />
                    {notification.body}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
            <Avatar
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ width: 32, height: 32, bgcolor: '#0B617F' }}
            >
              <Typography variant="body2">
                {userData?.profile?.firstName.split('')[0]}
                {userData?.profile?.lastName.split('')[0]}
              </Typography>
            </Avatar>
            <div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Link to={'/profile'}>
                  <MenuItem>{'My Profile'}</MenuItem>
                </Link>
                <MenuItem onClick={logout}>{t('LOGOUT')}</MenuItem>
              </Menu>
            </div>
            <Typography variant="body2" sx={{ fontSize: '0.9em', fontWeight: 'bold' }}>
              {`${userData?.profile?.firstName || user?.profile?.firstName} 
              ${userData?.profile?.lastName || user?.profile?.lastName}`}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
