import React, { useMemo } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { BpCheckbox } from '../../utils/commonFunctions';
import TableSortLabelCustom from '../Layout/TableSortLabelCustom';

const HeaderRow = ({
  sortBy,
  handleSort,
  sortDirection,
  checked,
  columns,
  onSelectAll,
  selectable,
}): JSX.Element => {
  const singleColums = useMemo(() => {
    return columns.filter((column) => !column.groupHeader && !column.group);
  }, [columns]);

  const groupHeaders = useMemo(() => {
    return columns.filter((column) => !!column.groupHeader);
  }, [columns]);

  const groupColumns = useMemo(() => {
    return columns.filter((column) => !!column.group);
  }, [columns]);

  const borders = {
    backgroundColor: '#007aff',
    borderColor: '#DDDDDD',
    borderStyle: 'solid',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    padding: '0px 0.5%',
  };

  return (
    <>
      <TableRow>
        {selectable && (
          <TableCell padding="checkbox" sx={borders}>
            <BpCheckbox checked={checked} onChange={onSelectAll} />
          </TableCell>
        )}
        {singleColums.map((column, i) => (
          <TableCell
            key={`${column.name}${i}`}
            align="center"
            sx={{
              ...borders,
              padding: '0px 0.5%',
              width: column.width,
            }}
            rowSpan={column.rowSpan}
            colSpan={column.colSpan}
          >
            <TableSortLabelCustom
              active={sortBy === column.name}
              sortDirection={sortDirection}
              onClick={() => handleSort(column.name)}
              text={column.label}
            />
          </TableCell>
        ))}
        {groupHeaders && (
          <>
            {groupHeaders.map((groupHeader, i) => (
              <TableCell
                key={`${groupHeader.name}${i}`}
                colSpan={groupHeader.colSpan}
                align="center"
                sx={{
                  ...borders,
                  backgroundColor: `${groupHeader.color}90`,
                  color: 'white',
                }}
              >
                {groupHeader.name}
              </TableCell>
            ))}
          </>
        )}
      </TableRow>
      {groupColumns && (
        <TableRow>
          {groupColumns.map((groupColumn, i) => (
            <TableCell
              key={`${groupColumn.name}${i}`}
              padding="checkbox"
              align="center"
              sx={{
                ...borders,
                backgroundColor: groupColumn.color,
                color: 'white',
              }}
            >
              <Typography fontSize={11}>{groupColumn.label}</Typography>
            </TableCell>
          ))}
        </TableRow>
      )}
    </>
  );
};

export default React.memo(HeaderRow);
