import client from '../client';
import { GetVarietiesSoilTypesResponse } from './types';

const Arvalis = new (class {
  // the return type needs to still be fixed in OpenAPI, currently "unknown"
  async getVarietiesSoilTypes() {
    const { data } = await client.GET('/get_varieties_soil_types');
    return data as GetVarietiesSoilTypesResponse;
  }
})();

export default Arvalis;
