import React from 'react';
import { useTranslation } from 'react-i18next';

const classNames = {
  innerText: 'font-bold text-gray-900 text-sm p-2 text-center',
  stickyFooter: 'bg-white  sticky bottom-0 z-10',
  tableRow: '',
};
interface AggregationsRowProps {
  aggregations: any;
  columns: { name: string; aggregate?: boolean }[];
  trailingCell?: boolean;
}

const Cell = ({ children }) => {
  return (
    <td align="center">
      <span className={classNames.innerText}>{children}</span>
    </td>
  );
};

const TotalsCell = () => {
  const { t } = useTranslation();
  return (
    <Cell key={'totals-cell'}>
      <span className={''}>{t('GENERIC.TOTALS')}</span>
    </Cell>
  );
};

const AggregationsRow: React.FC<AggregationsRowProps> = ({
  aggregations,
  columns,
  trailingCell = true,
}) => {
  const { t } = useTranslation();

  if (!Object.keys(aggregations).length) return null;

  return (
    <tfoot className={classNames.stickyFooter}>
      <tr className={classNames.tableRow}>
        {trailingCell && <TotalsCell />}
        {columns.map((column, i) => {
          const isFirstCell = !trailingCell && i === 0;

          return (
            <Cell key={column.name}>
              {isFirstCell && t('GENERIC.TOTALS')}
              {column.aggregate && aggregations[column.name].toFixed(2)}
            </Cell>
          );
        })}
      </tr>
    </tfoot>
  );
};

export default AggregationsRow;
