import React, { useState } from 'react';
import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import { useTranslation } from 'react-i18next';
import { toast } from '@tcc/labz-design-system';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import OrderSummary from '../../DeliveryDetails/OrderSummary';
import BackNextButtons from '../../Layout/checkoffer/BackNextButtons';
import { classNames } from './components/classNames';
import FieldDetails from './components/FieldDetails';
import DeliveryDetails from './components/DeliveryDetails';
import GrowerDetail from '../reviewDetails/components/GrowerDetail';
import { useStateContext } from '../../../utils/stateContext';
import GrowerAPI from '../../../api/grower';
import { ISODateFormat } from '../../../utils/commonFunctions';

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    initialState,
    userDetails,
    offers: { selectedFields, totalArea, totalPrice, selectedOffer },
    subscription: {
      getAllGrowers: [, , , fetchGrowers],
    },
  } = useStateContext();

  const { sharedState, growerState } = initialState;

  const offer_id = selectedOffer?.id;
  const { handleSubmit } = useForm();

  const submit = () => {
    let body = {
      offer_id,
      order_farm_address: sharedState?.address1,
      order_del_address: growerState.order_del_address,
      order_del_city: growerState.order_del_city,
      order_del_postal_code: growerState.order_del_postal_code,
      order_contact_name: growerState.order_contact_name,
      order_mobile_no: growerState?.order_mobile_no,
      order_price: totalPrice,
      order_add_info: growerState.order_add_info,
      order_total_ha: totalArea,
      order_total_no_fields: selectedFields.length,
      grower_uuid: sharedState.uuid,
      created_by: userDetails?.id,
      offer: selectedOffer,
      order_fields: selectedFields.map(
        ({
          id,
          variety,
          previous_crop,
          sowing_date,
          soil_type,
          tillage,
          mixed_variety,
          region,
          field_name,
          area,
          soil_code,
          centroid_coord,
        }) => {
          return {
            id: id,
            variety: variety,
            previous_crop,
            sowing_date: dayjs(sowing_date).format(ISODateFormat),
            soil_type: soil_type,
            tillage: tillage ? tillage : false,
            mixed_variety: mixed_variety ? mixed_variety : false,
            region,
            field_name,
            area,
            soil_code,
            longitude: centroid_coord.split(', ')[0],
            latitude: centroid_coord.split(', ')[1],
          };
        }
      ),
    };
    setIsSubmitting(true);
    GrowerAPI.addOrder(body)
      .then((response) => {
        if (response.status === 201) {
          toast.success(t('ALERT.MSG18'));
          setTimeout(() => {
            navigate('/home');
          }, 2000);
          return;
        } else {
          toast.error(t('ALERT.MSG12'));
        }
      })
      .catch((err) => {
        toast.error(t('ALERT.MSG12'));
        console.error(err);
      })
      .finally(() => {
        setIsSubmitting(false);
        fetchGrowers();
      });
  };

  return (
    <div className={classNames.container}>
      <OrderSummary />
      <div className="flex flex-col justify-between pb-8 h-full grow">
        <span className={classNames.noteText}>{t('REVIEW.NOTE')}</span>
        <form noValidate onSubmit={handleSubmit(submit)} className={classNames.form}>
          <GrowerDetail />
          <FieldDetails />
          <DeliveryDetails />
        </form>
        <BackNextButtons onNextClick={handleSubmit(submit)} loading={isSubmitting} />
      </div>
    </div>
  );
};
const Tabs = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const REVIEW_DETAILS = t('REVIEW_DETAILS');

  const tabs = [
    {
      id: 'review_details',
      name: REVIEW_DETAILS,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const ReviewDetails = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default ReviewDetails;
