import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@tcc/labz-design-system';
import useProfile from '../../hooks/profile/useProfile';
import FormInput from '../Forms/FormInput';
import Shell from '../Layout/shared/Shell';
import LanguageSelect from '../Forms/FormSelect';
import { useStateContext } from '../../utils/stateContext';

const baseClassNames = {
  button:
    'bg-primary-800 border-primary-800 hover:bg-primary-400 hover:border-primary-900 transition-all',
};

const classNames = {
  ...baseClassNames,
  loginContainer: 'h-full bg-white sm:bg-[#e6e9ed] flex flex-col w-full items-center',
  registerForm:
    'flex flex-col p-8 gap-6 mt-12 w-full sm:w-[450px] md:w-[750px] items-center justify-center bg-white sm:rounded-md sm:shadow-custom-modal ',
  loginText: 'font-helvetica font-light text-[22px] text-[#333333] font-weight',
  fieldError: 'text-red-600 text-sm',
  loginButton: `${baseClassNames.button} w-full text-lg font-medium`,
  links: 'text-primary-800 hover:text-primary-600',

  noAccountText: 'text-xs border-t-2 sm:border-0 pt-1 text-[#656568]',
  inputContainer: 'flex flex-col w-full',
  rowContainer: 'w-full flex flex-col gap-8 md:flex-row md:gap-4',
  inputLabel: 'font-helvetica text-[#333333]',
  registerNowContainer:
    'flex flex-col w-full bg-white sm:bg-[#e6e9ed] flex gap-4 items-center py-4',
  termsContainer: 'flex gap-1',
  registerError: 'flex justify-center w-full text-red-600 text-sm',
  deleteButton: `${baseClassNames.button} bg-transparent text-primary-600 hover:text-white`,
  exportButton: `${baseClassNames.button}`,
};

const PageContent = () => {
  const { t } = useTranslation();
  const {
    form: {
      fields,
      handleSubmit,
      formState: { isValid, isSubmitting },
    },
  } = useProfile();

  const { logout } = useStateContext();

  const languageOptions = [
    { id: 'en', label: t('PROFILE.ENGLISH'), value: 'en' },
    { id: 'fr', label: t('PROFILE.FRENCH'), value: 'fr' },
  ];

  return (
    <div className={classNames.loginContainer}>
      <form onSubmit={handleSubmit} className={classNames.registerForm}>
        <span className={classNames.loginText}>{t('PROFILE.MY_PROFILE')}</span>

        <div className={`${classNames.rowContainer} place-content-end`}>
          <Button>{t('PROFILE.DELETE_BUTTON')}</Button>
          <Button>Export</Button>
          <Button onClick={logout}>{t('LOGOUT')}</Button>
        </div>
        <div className={classNames.rowContainer}>
          <FormInput field={fields.firstName} label={t('REGISTRATION.FIRST')} />

          <FormInput field={fields.lastName} label={t('REGISTRATION.LAST')} />
        </div>
        <div className={classNames.rowContainer}>
          <FormInput field={fields.email} label={t('LOGIN.EMAIL')} />

          <div className={classNames.inputContainer}>
            <LanguageSelect field={fields.language} options={languageOptions} />
          </div>
        </div>
        <Button
          className={classNames.loginButton}
          type="submit"
          disabled={!isValid || isSubmitting}
        >
          {isSubmitting ? t('LOGIN.LOADING') : t('GENERIC.SAVE')}
        </Button>
      </form>
    </div>
  );
};

const Profile = () => {
  return (
    <Shell footer={false}>
      <PageContent />
    </Shell>
  );
};

export default Profile;
