import React, { HTMLProps, ReactNode, useState, ForwardedRef } from 'react';
import { mergeClassNames } from '@tcc/labz-design-system';

import Eye from '../icons/Eye';
import CrossedEye from '../icons/CrossedEye';

type InputProps = HTMLProps<HTMLInputElement> & {
  value?: string;
  placeholder: string;
  className?: string;
  TrailingIcon?: ReactNode;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { value, placeholder, className, TrailingIcon, type, ...props },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setShowPassword((prev) => !prev);
    };

    return (
      <div className={mergeClassNames('relative rounded-md shadow-sm', className)}>
        <input
          {...props}
          ref={ref} // Forward the ref to the input element
          type={type === 'password' && showPassword ? 'text' : type}
          placeholder={placeholder}
          value={value}
          className={mergeClassNames(
            'block w-full rounded-md border-0 py-3 pr-10 text-gray-900',
            'ring-1 ring-inset ring-gray-300 placeholder:text-gray-600',
            'focus:ring-2 focus:ring-inset focus:ring-primary-500 text-sm md:text-base sm:leading-6'
          )}
        />
        <div
          className={mergeClassNames(
            'absolute inset-y-0 right-0 flex items-center pr-3',
            type === 'password' ? 'cursor-pointer' : 'pointer-events-none'
          )}
        >
          {type === 'password' ? (
            <button
              type="button"
              className="cursor-pointer outline-none focus-within:outline-none"
              aria-label="Toggle password visibility"
              onClick={handleTogglePassword}
            >
              {showPassword ? (
                <CrossedEye className="w-5 h-5 text-neutral-600" />
              ) : (
                <Eye className="w-5 h-5 text-neutral-600" />
              )}
            </button>
          ) : (
            <>{TrailingIcon}</>
          )}
        </div>
      </div>
    );
  }
);

Input.displayName = 'Input';

export { Input };
