import React, { FunctionComponent } from 'react';
import {
  Refresh as RefreshIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { formatDateTime } from '../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
interface TableControlsProps {
  lastUpdated?: Date;
  onReload?: () => void;
  onToggleFilters?: () => void;
  onDelete: () => void;
  isFiltering: boolean;
  ActionButton: FunctionComponent;
}
const bgbutton = 'bg-primary-800 hover:bg-primary-400';

const classNames = {
  container: 'flex p-4 absolute top-[10px] right-[-10px] ',
  header: 'flex gap-4 items-center justify-between',
  title: 'font-semibold text-lg',
  lastUpdated: 'text-sm opacity-50',
  buttonContainer: 'flex items-center gap-2',
  button: 'border rounded-md px-2 py-1 text-sm font-semibold',
  addButton: `flex h-8 items-center border border-[#00617F] ${bgbutton}`,
  refreshButton: '',
  searchButton: '',
  deleteButton: '',
  icon: 'text-primary-500 hover:text-blue-400',
  active: 'border border-blue-400',
};

const TableControls = ({
  lastUpdated = new Date(),
  onReload = () => {},
  onToggleFilters = () => {},
  onDelete = () => {},
  isFiltering,
  ActionButton,
}: TableControlsProps) => {
  const { t } = useTranslation();

  const handleOnReload = async () => {
    await onReload();
  };
  const handleOnToggleFilter = () => {
    onToggleFilters();
  };

  const handleOnDelete = () => {
    onDelete();
  };

  return (
    <div className={classNames.container}>
      <div className={classNames.header}>
        <div>
          <p className={classNames.lastUpdated}>
            {t('GENERIC.LAST_UPDATED')} {formatDateTime(dayjs(lastUpdated))}
          </p>
        </div>
        <div className={classNames.buttonContainer}>
          {ActionButton && <ActionButton />}
          <button
            className={`${classNames.button} ${classNames.refreshButton}`}
            onClick={handleOnReload}
          >
            <RefreshIcon className={classNames.icon} />
          </button>
          <button
            className={`${classNames.button} ${classNames.searchButton} ${isFiltering ? classNames.active : ''}`}
            onClick={handleOnToggleFilter}
          >
            <SearchIcon className={classNames.icon} />
          </button>
          <button
            className={`${classNames.button} ${classNames.deleteButton}`}
            onClick={handleOnDelete}
          >
            <DeleteIcon className={classNames.icon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableControls;
