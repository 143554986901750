import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ResetMail from '../../images/resetMail.png';

interface RowData {
  name: string;
  invite_sent?: boolean;
}

interface InviteGrowerCellProps {
  rowData: RowData;
  onClick?: (rowData: RowData) => void;
}

const InviteGrowerCell: React.FC<InviteGrowerCellProps> = ({ rowData, onClick = () => {} }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="body2" fontSize="1em" onClick={() => onClick(rowData)} noWrap>
        {rowData.name}
      </Typography>
      {rowData.invite_sent && (
        <img
          src={ResetMail}
          style={{ width: '1.8em', height: '1.5em' }}
          alt={t('AGRONOMY.RESET_EMAIL')}
        />
      )}
    </Box>
  );
};

export default InviteGrowerCell;
