import React, { ReactNode } from 'react';
import { Tab, TabbedPanes } from '@tcc/labz-design-system';

interface DynamicTab {
  id: string;
  name: string;
  children: ReactNode;
}

interface DynamicTabsProps {
  tabs: DynamicTab[];
}

const DynamicTabs: React.FC<DynamicTabsProps> = ({ tabs }) => {
  const Pane = ({ children }: { children: ReactNode }) => (
    <div className="flex w-full h-auto">{children}</div>
  );

  const tabItems: Tab[] = tabs.map(({ id, name, children }) => ({
    id,
    name,
    pane: () => <Pane>{children}</Pane>,
    default: id === tabs[0].id, // Set the first tab as default
  }));

  return <TabbedPanes tabs={tabItems} className="relative py-4 w-full" />;
};

export default DynamicTabs;
