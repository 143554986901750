import { useMemo } from 'react';

const useAggregations = (dataList: any[], columns: { name: string; aggregate?: boolean }[]) => {
  const aggregations = useMemo(() => {
    const result: { [name: string]: number } = {};

    columns.forEach((column) => {
      if (column.aggregate) {
        const name = column.name;
        const total = dataList.reduce((sum, item) => {
          const value = item[name];
          return sum + (typeof value === 'number' ? value : 0);
        }, 0);
        result[name] = total;
      }
    });

    return result;
  }, [dataList, columns]);

  return aggregations;
};

export default useAggregations;
