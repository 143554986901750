import { Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';

const RegionSelect = ({ value, onChange, soilTypes, className }) => {
  return (
    <Autocomplete
      className={className}
      value={value}
      onChange={onChange}
      options={[...new Set(soilTypes.map((x) => x.region))]}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${t('FIELD.REGION')}*`}
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default RegionSelect;
