import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CSVLink } from 'react-csv';
import {
  Select,
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  InputAdornment,
  Icon,
  OutlinedInput,
} from '@mui/material';
import { ArrowBack, Mail, PersonOutline } from '@mui/icons-material';
import * as Yup from 'yup';

import Header from '../Header';
import { useStateContext } from '../../utils/stateContext';
import CustomModal from '../CustomModal';
import LoginAPI from '../../api/login';
import UserAPI from '../../api/user';
import { toast } from '@tcc/labz-design-system';

const Profile = () => {
  const { t } = useTranslation();

  const { userDetails, logout } = useStateContext();

  const [language, setLanguage] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('REGISTER.ERR_FIRST')),
    lastName: Yup.string().required(t('REGISTER.ERR_LAST')),
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
    lang: Yup.string().required('REGISTER.ERR_LANG'),
  });

  // the salutation was never included in Gigya calls
  // the email change is also most likely not working
  // Gigya returns: this parameter was not recognized as valid for this API method with your security credentials nor was it recognized as a standard Gigya control parameter.
  interface FormData {
    email: string;
    firstName: string;
    lastName: string;
    lang: string;
  }

  const defaultValues: FormData = {
    email: '',
    firstName: '',
    lastName: '',
    lang: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const submit = async (data: FormData) => {
    await LoginAPI.setAccountInfo({
      UID: userDetails?.gigya_uuid,
      email: data.email,
      profile: JSON.stringify({ firstName: data.firstName, lastName: data.lastName }),
      lang: data.lang,
    });
    // add handling success / failure
    // we should set the user data in the state
  };

  const deleteAccount = async () => {
    if (!userDetails?.id || !userDetails?.gigya_uuid) {
      toast.error(t('PROFILE.CANNOT_DELETE'));
    }

    // removing Predima account (disti-api)
    const userResponse = await UserAPI.deleteUser({ id: userDetails?.id });
    if (userResponse.status !== 200) {
      toast.error(t('PROFILE.CANNOT_DELETE'));
    }

    // removing Gigya account
    const loginResponse = await LoginAPI.deleteAccount(userDetails?.gigya_uuid);
    if (loginResponse.statusCode !== 200) {
      toast.error(t('PROFILE.CANNOT_DELETE'));
    }

    toast.success(t('PROFILE.DELETED_SUCCESFULLY'));

    setTimeout(() => {
      // logout cleans up all state data and redirects to the login screen
      logout();
    }, 3000);
  };

  const csvData = [
    ['Gigya uuid', 'Email', 'Name'],
    [`${userDetails?.gigya_uuid}`, `${userDetails?.email}`, `${userDetails?.name}`],
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Header />
      <CustomModal
        title={t('PROFILE.DELETE_ACCOUNT')}
        open={openModal}
        handleClose={() => setOpenModal(false)}
      >
        <Typography>{t('PROFILE.DELETE_ACCOUNT')}</Typography>

        <Typography sx={{ mt: 3, fontWeight: 550, fontSize: 14 }}>
          {t('PROFILE.DELETE_ACCOUNT_CONFIRM')}
        </Typography>
        <Typography sx={{ fontSize: 12, mt: 2, textAlign: 'center' }}>
          {t('PROFILE.DELETE_ALL_DATA')}
        </Typography>
        <Typography sx={{ fontSize: 12, mt: 1, mb: 3 }}>
          {t('PROFILE.DELETE_24_HOUR_WARNING')}
        </Typography>
        <Button
          type="button"
          sx={{ width: 150, height: 30, m: 'auto', display: 'block' }}
          variant="contained"
          onClick={deleteAccount}
        >
          {t('PROFILE.DELETE_CONFIRM_BUTTON')}
        </Button>
      </CustomModal>
      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: '98%',
          height: '85vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1vw',
            borderBottom: '2px solid #DDDDDD',
            padding: '0.5vw',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5vw' }}>
            <Link to={'/home'}>
              <ArrowBack fontSize="small" />
            </Link>
            <p style={{ fontSize: '14px', fontWeight: 550 }}>{t('PROFILE.MY_PROFILE')}</p>
          </Box>
          <Box>
            <CSVLink data={csvData} filename={'user_data.csv'}>
              <Button
                sx={{
                  color: '#00BCFF',
                  border: '1.5px solid #00BCFF',
                  fontSize: '12px',
                  fontWeight: 530,
                  padding: '0.1vw 0.2vw',
                  mr: 2,
                }}
              >
                Export
              </Button>
            </CSVLink>
            <Button
              onClick={() => setOpenModal(true)}
              sx={{
                color: 'red',
                border: '1.5px solid red',
                fontSize: '12px',
                fontWeight: 530,
                padding: '0.1vw 0.2vw',
              }}
            >
              {t('PROFILE.DELETE_BUTTON')}
            </Button>
          </Box>
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit(submit)} sx={{ mt: 2 }}>
          <Grid container columnSpacing={1} rowSpacing={1.5} columns={12} justifyContent="center">
            <Grid item xs={10} sm={5}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="outlined-adornment-firstName">
                  {t('REGISTRATION.FIRST')}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-firstName"
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon color="primary">
                        <PersonOutline />
                      </Icon>
                    </InputAdornment>
                  }
                  label={t('REGISTRATION.FIRST')}
                  autoComplete="firstName"
                  {...register('firstName')}
                  error={!!errors.firstName}
                />
                <span style={{ color: 'red', fontSize: '12px' }}>{errors.firstName?.message}</span>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="outlined-adornment-lastName">
                  {t('REGISTRATION.LAST')}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-lastName"
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon color="primary">
                        <PersonOutline />
                      </Icon>
                    </InputAdornment>
                  }
                  label={t('REGISTRATION.LAST')}
                  autoComplete="lastName"
                  {...register('lastName')}
                  error={!!errors.lastName}
                />
                <span style={{ color: 'red', fontSize: '12px' }}>{errors.lastName?.message}</span>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="outlined-adornment-email">{t('LOGIN.EMAIL')}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email"
                  type="email"
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon color="primary">
                        <Mail />
                      </Icon>
                    </InputAdornment>
                  }
                  label={t('LOGIN.EMAIL')}
                  autoComplete="email"
                  {...register('email')}
                  error={!!errors.email}
                />
                <span style={{ color: 'red', fontSize: '12px' }}>{errors.lang?.message}</span>
              </FormControl>
            </Grid>

            <Grid item xs={10} sm={5}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-language">{t('REGISTRATION.LANGUAGE_PREFERENCE')}</InputLabel>
                <Select
                  labelId="demo-language"
                  id="language"
                  value={language}
                  label="Language Preference"
                  {...register('lang')}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <MenuItem value="">
                    <em>{t('NONE')}</em>
                  </MenuItem>
                  <MenuItem value="en">{t('PROFILE.ENGLISH')}</MenuItem>
                  <MenuItem value="fr">{t('PROFILE.FRENCH')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10} sm={5}></Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Button type="submit" sx={{ width: 250, height: 40, mt: 3, mb: 3 }} variant="contained">
              {t('PROFILE.SAVE_NOW')}
            </Button>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
