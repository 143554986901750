import { useState, useCallback } from 'react';

const useListToggle = <T extends Record<string, any>>(
  initialList: T[] = [],
  compareKey: keyof T
): [T[], (value: T) => void, (value: T) => boolean, (newList: T[]) => void] => {
  const [list, setList] = useState<T[]>(initialList);

  const toggle = useCallback(
    (value: T) => {
      setList((prevList) => {
        const index = prevList.findIndex((item) => item[compareKey] === value[compareKey]);
        if (index !== -1) {
          // Remove the value if it's already in the list
          return prevList.filter((_, i) => i !== index);
        } else {
          // Add the value if it's not in the list
          return [...prevList, value];
        }
      });
    },
    [compareKey]
  );

  const isSelected = useCallback(
    (value: T): boolean => {
      return list.some((item) => item[compareKey] === value[compareKey]);
    },
    [list, compareKey]
  );

  const resetList = useCallback((newList: T[]) => {
    setList(newList);
  }, []);

  return [list, toggle, isSelected, resetList];
};

export default useListToggle;
