import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat, formatNumber } from '../../utils/commonFunctions';
import { useStateContext } from '../../utils/stateContext';
import PredimaTable from '../PredimaTable';
import TableControls from './TableControls';
import { Link } from 'react-router-dom';
import useListToggle from '../../hooks/useListToggle';
import GrowerAPI from '../../api/grower';
import { toast } from '@tcc/labz-design-system';

const bgbutton = 'text-white bg-primary-500 hover:bg-primary-600 hover:border-primary-600';

const classNames = {
  button: 'rounded-md px-4 py-1 text-sm font-semibold',
  addButton: `flex h-8 items-center ${bgbutton}`,
  loadingOverlay:
    'absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-30 h-full',
  container: 'flex flex-col w-full',
};

const SubscriptionTable = () => {
  const {
    subscription: {
      subscriptionList,
      getAllGrowers: [, , , fetchAllGrowers],
      lastUpdated,
    },
    setSelectedSalesPersons,
    updateSharedState,
  } = useStateContext();

  const [selectedGrowers, toggleSelectedGrower] = useListToggle([], 'uuid');
  const [isFiltering, setIsFiltering] = useState(false);

  const { t } = useTranslation();
  const handleSubscriptionCellClick = (rowData) => updateSharedState(rowData);
  const columnWidth = '9.3%';

  const columns = [
    {
      name: 'distributor',
      //   label: t('GENERIC.DISTRIBUTOR'),
      label: 'Distributor',
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'created_user_name',
      //   label: t('HOME.SALES_REP_NAME'),
      label: 'Representative',
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'contact_name',
      label: 'Grower',
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'status',
      label: t('GENERIC.STATUS'),
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'start_date',
      label: t('GENERIC.START_DATE'),
      width: columnWidth,
      date: true,
      inputFormat: dateFormat,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'app_login',
      label: 'App login',
      width: columnWidth,
      boolean: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'name',
      label: 'Farm',
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'field_name',
      label: 'Field',
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'field_area',
      label: 'HA',
      width: columnWidth,
      search: true,
      aggregate: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'price',
      label: 'Price (EUR)',
      width: columnWidth,
      search: true,
      aggregate: true,
      onClick: handleSubscriptionCellClick,
      valueGetter: (rowData) => formatNumber(rowData.price),
    },
    {
      name: 'offer_name',
      label: 'Offer',
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'consent',
      label: 'Compliance',
      width: columnWidth,
      boolean: true,
      compliance: true,
      valueGetter: (rowData) => !!rowData?.compliance,
    },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = subscriptionList.map((n) => n.id);
      setSelectedSalesPersons(newSelected);
      return;
    }
    setSelectedSalesPersons([]);
  };

  const AddGrower = () => {
    return (
      <Link className={`${classNames.button} ${classNames.addButton}`} to={`/home/addgrower`}>
        {t('HOME.ADD_GROWER')}
      </Link>
    );
  };

  const deleteGrowers = () => {
    if (selectedGrowers.length < 1) {
      toast.error(t('ALERT.MSG5'));
    } else {
      const growers = selectedGrowers.map((grower) => grower.id);
      const body = { id: growers };

      GrowerAPI.deleteGrowers(body).then((response) => {
        if (response.status === 200) {
          toast.success(t('ALERT.MSG6'));
          fetchAllGrowers();
        } else {
          toast.error(t('ALERT.MSG5'));
        }
      });
    }
  };

  return (
    <div className={classNames.container}>
      <TableControls
        lastUpdated={lastUpdated}
        ActionButton={AddGrower}
        onReload={fetchAllGrowers}
        onToggleFilters={() => setIsFiltering(!isFiltering)}
        isFiltering={isFiltering}
        onDelete={() => deleteGrowers()}
      />
      <PredimaTable
        rowKey="uuid"
        selectable={true}
        data={subscriptionList}
        columns={columns}
        loading={false}
        showFilters={isFiltering}
        onSelect={toggleSelectedGrower}
        onSelectAll={handleSelectAllClick}
        allSelected={selectedGrowers.length === subscriptionList.length}
        selectedRows={selectedGrowers}
      />
    </div>
  );
};
export default SubscriptionTable;
