import OfferTile from './OfferTile';
import { useStateContext } from '../../../utils/stateContext';
import SlidingTiles from './SlidingTiles';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

const classNames = {
  titlesContainer: 'flex gap-1 w-full h-full justify-center items-center relative',
  tile: 'flex justify-center items-center w-full h-full',
};

const OfferTiles = () => {
  const {
    offers: { offers, selectedOffer, setSelectedOffer, fetchOffers, loading },
  } = useStateContext();

  useEffect(() => {
    fetchOffers();
  }, []);

  if (loading) return <CircularProgress />;

  return (
    <>
      <SlidingTiles>
        {offers?.map((offer) => (
          <div key={offer.id} className={classNames.tile} onClick={() => setSelectedOffer(offer)}>
            <OfferTile offer={offer} selected={selectedOffer?.id === offer.id} />
          </div>
        ))}
      </SlidingTiles>
    </>
  );
};

export default OfferTiles;
