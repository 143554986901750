import { Box, IconButton, Typography } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';

import React from 'react';
import EmptyCellIndicator from './EmptyCellIndicator';

const style = {
  fontSize: 13,
  display: 'inline-block',
  maxWidth: '60%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: 3,
};

type ISentIcon = typeof RocketLaunchIcon | typeof RocketLaunchOutlinedIcon;
type treatmentWindow = 'T1' | 'T2' | 'T3';
interface ProductSendCellProps {
  rowData: any;
  value: string;
  tWindow: treatmentWindow;
  onClick: (rowData: any, tWindow: treatmentWindow) => any;
}

const ProductSendCell = ({
  rowData,
  value,
  tWindow,
  onClick = (rowData, tWindow) => {},
}: ProductSendCellProps) => {
  const sent = rowData?.[`${tWindow}sent`];
  const canSave = Boolean(rowData?.[`${tWindow}id`]);
  const SentIcon: ISentIcon = Boolean(sent) ? RocketLaunchIcon : RocketLaunchOutlinedIcon;

  const handleProductSendClick = () => {
    onClick(rowData, tWindow);
  };

  if (!value) return <EmptyCellIndicator />;

  return (
    <Box sx={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
      <Typography sx={style}>{value}</Typography>
      <IconButton
        disabled={!canSave}
        onClick={handleProductSendClick}
        sx={{ position: 'absolute', right: -20 }}
      >
        <SentIcon
          color={canSave ? 'primary' : 'disabled'}
          fontSize="medium"
          cursor={canSave ? 'pointer' : 'not-allowed'}
        />
      </IconButton>
    </Box>
  );
};

export default ProductSendCell;
