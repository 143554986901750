import { useEffect } from 'react';
import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import GrowerDetailTable from '../../Layout/growerDetail/GrowerDetailTable';
import GrowerSummary from '../../Layout/growerDetail/GrowerSummary';
import GrowerReferences from '../../Layout/growerDetail/GrowerReferenes';
import { useStateContext } from '../../../utils/stateContext';
import { useTranslation } from 'react-i18next';
import BackNextButtons from '../../Layout/checkoffer/BackNextButtons';
import { useNavigate } from 'react-router-dom';

const classNames = {
  container: 'flex gap-8',
  buttonContainer: 'flex items-center justify-center',
};

const Page = () => {
  const navigate = useNavigate();
  const {
    setRows,
    offers: { selectedFields, setSelectedFields },
  } = useStateContext();

  useEffect(() => {
    setSelectedFields([]);
    setRows([]);
  }, []);

  return (
    <div className={classNames.container}>
      <GrowerDetailTable />
      <div className="flex flex-col justify-between pb-8 grow">
        <div className="flex lg:flex-row flex-col justify-center gap-4 grow">
          <GrowerReferences />
          <GrowerSummary />
        </div>
        <div className={classNames.buttonContainer}>
          <BackNextButtons
            onNextClick={() => navigate('/home/checkoffer')}
            backDisabled={true}
            nextDisabled={selectedFields.length < 1}
          />
        </div>
      </div>
    </div>
  );
};

const Tabs = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const GROWER_DETAIL = t('GROWER_DETAIL');
  const tabs = [
    {
      id: 'grower_detail',
      name: GROWER_DETAIL,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const GrowerDetail = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default GrowerDetail;
