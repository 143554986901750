import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ToastContainer } from '@tcc/labz-design-system';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LoginTitle from '../Layout/LoginTitle';
import FormInput from '../Forms/FormInput';
import usePasswordReset from '../../hooks/passwordReset/usePasswordReset';

const classNames = {
  resetContainer: 'h-[100vh] bg-white sm:bg-[#e6e9ed] flex flex-col w-full items-center',
  resetForm:
    'flex flex-col p-8 gap-6 mt-12 w-full sm:w-[450px] items-center justify-center bg-white sm:rounded-md sm:shadow-custom-modal',
  resetTitle: 'mx-auto flex min-h-screen max-w-screen-sm items-center justify-center',
  resetText: 'font-helvetica font-light text-[22px] text-[#333333] font-weight',
  sentConfirmText: 'text-sm text-center',
  resetNote: 'text-gray-800 text-sm',
  resetButton:
    'w-full bg-primary-800 border-primary-800 text-lg font-medium hover:bg-primary-900 hover:border-primary-900 transition-all',
  links: 'text-primary-800 hover:text-primary-600',
  emailIcon: 'text-neutral-600',
  fieldError: 'text-red-600 text-sm absolute right-0',
  bottomFeedback:
    'mx-10 border-t-2 sm:border-0 gap-2 flex flex-col items-center pt-4 text-[#656568]',
};

const ResetPassword = () => {
  const { t } = useTranslation();
  const {
    resetStatus,
    resubmit,
    form: {
      formState: { isSubmitting, isValid },
      fields,
      handleSubmit,
    },
  } = usePasswordReset();

  return (
    <>
      <div className={classNames.resetContainer}>
        <form onSubmit={handleSubmit} className={classNames.resetForm}>
          <LoginTitle />
          <span className={classNames.resetText}>{t('LOGIN.RESET_PASSWORD')}</span>
          <div className="relative w-full">
            <FormInput
              label={t('LOGIN.EMAIL')}
              field={fields.email}
              TrailingIcon={<MailOutlineIcon className={classNames.emailIcon} cursor="pointer" />}
            />
          </div>
          {!resetStatus && (
            <Button
              className={classNames.resetButton}
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? t('LOGIN.LOADING') : t('GENERIC.SEND')}
            </Button>
          )}
          {resetStatus && (
            <Button
              onClick={resubmit}
              className={classNames.resetButton}
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? t('LOGIN.LOADING') : t('FORGOT.RESEND_LINK')}
            </Button>
          )}
          <Link className={classNames.links} to="/">
            {t('RESET.CANCEL')}
          </Link>
        </form>

        {resetStatus && (
          <div className={classNames.bottomFeedback}>
            <span className={classNames.sentConfirmText}>{t('FORGOT.TEXT1')}</span>
            <Link className={classNames.links} to="/">
              {t('GENERIC.LOGIN')}
            </Link>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default ResetPassword;
