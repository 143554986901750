import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import OfferFooter from '../../Layout/checkoffer/OfferFooter';
import OfferTiles from '../../Layout/checkoffer/OfferTiles';
import GrowerDetailTable from '../../Layout/growerDetail/GrowerDetailTable';
import PageTitle from '../../Layout/checkoffer/PageTitle';
import { useTranslation } from 'react-i18next';

const classNames = {
  container: 'flex w-full min-h-[700px] flex-grow gap-4 overflow-hidden',
  offerContainer: 'flex flex-col justify-between items-center p-4 w-full',
  congratulations: 'text-[#F78500] text-xl font-bold uppercase',
  welcomeText: 'text-text-1 font-light',
  titleContainer: 'flex flex-col items-center mb-4',
};

const Page = () => {
  const { t } = useTranslation();
  return (
    <div className={classNames.container}>
      <GrowerDetailTable />
      <div className={classNames.offerContainer}>
        <div className={classNames.titleContainer}>
          <PageTitle>{t('OFFERS.CONGRATULATIONS')}</PageTitle>
          <span className={classNames.welcomeText}>{t('OFFERS.TITLE2')}PreDiMa</span>
        </div>
        <OfferTiles />
        <OfferFooter />
      </div>
    </div>
  );
};

const Tabs = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const OFFER_DETAIL = t('OFFER_DETAIL');
  const tabs = [
    {
      id: 'offer_detail',
      name: OFFER_DETAIL,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const CheckOffer = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default CheckOffer;
