import React from 'react';
import BayerLogo from '../icons/BayerLogo';

const classNames = {
  bayerLogo: 'w-11 h-11',
  predimaLogo: 'w-[80px] h-[80px]',
  titleContainer: 'mx-auto rounded-md flex items-center justify-center w-[300px]',
  titleInnerContainer:
    'flex w-full rounded-md bg-gradient-to-r from-[#56D500] via-[#00314E] to-[#01BEFF] p-[2px]',
  titleContent: 'flex gap-1 items-center bg-white px-4 py-1 rounded-md w-full h-full',
  titlePredima: 'flex flex-grow justify-center tracking-wide text-2xl italic tracking-[7px]',
};

const LoginTitle = () => {
  return (
    <div className={classNames.titleContainer}>
      <div className={classNames.titleInnerContainer}>
        <div className={classNames.titleContent}>
          <BayerLogo className={classNames.bayerLogo} />
          <span className={classNames.titlePredima}>PREDIMA</span>
        </div>
      </div>
    </div>
  );
};

export default LoginTitle;
