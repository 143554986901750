import React, { useEffect } from 'react';
import { useStateContext } from '../../utils/stateContext';
import Shell from '../Layout/shared/Shell';
import { Checkbox } from '@tcc/labz-design-system';

import PinIcon from '../icons/PinIcon';
import ChevronRightIcon from '../icons/ChevronRight';
import MapIcon from '../icons/MapIcon';

const classNames = {
  listContainer: 'flex flex-col w-full rounded',
  listTitle: 'text-primary-700 text-xl font-bold mb-2',
  listItem:
    'h-[80px] w-full flex justify-between place-items-center bg-white border-b border-gray-300 last:border-b-0 p-2',
  itemInfoContainer: 'flex gap-4 place-items-center',
  itemImage: 'w-[60px] h-[60px] rounded bg-gray-300',
  itemDetails: 'flex flex-col',
  itemName: 'text-md text-gray-800 pl-[2px]',
  itemLocation: 'flex text-sm text-gray-400 gap-1',
  pinIcon: 'w-4 h-4 text-gray-400',
  chevronIcon: 'w-10 text-gray-400 cursor-pointer',
  selectContainer: 'flex place-items-center',
  radio: 'bg-gray-400 rounded w-5 h-5',
  thumbNail: 'text-gray-200 w-[100%] h-[100%]',
};

const PageContent = () => {
  const {
    subscriptionList,
    setSelectedSalesPersons,
    setSelectedRows,
    setRows,
    setGrowerState,
    setInitialState,
    defaultState,
    setSelectedIndexes,
    getData,
  } = useStateContext();

  useEffect(() => {
    if (subscriptionList.length <= 0) {
      getData();
      setSelectedRows([]);
      setRows([]);
      setGrowerState({});
      setSelectedIndexes([]);
      setInitialState(defaultState);
      setSelectedSalesPersons([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheck = () => {};

  return (
    <div className={classNames.listContainer}>
      <span className={classNames.listTitle}>Subscription List</span>
      {subscriptionList.map((subscriptor) => (
        <ListItem subscriptor={subscriptor} onCheck={handleCheck} checked={false} />
      ))}
    </div>
  );
};

const ListItem = ({ subscriptor, onCheck, checked }) => {
  return (
    <div className={classNames.listItem}>
      <div className={classNames.itemInfoContainer}>
        <div className={classNames.itemImage}>
          <MapIcon className={classNames.thumbNail} />
        </div>
        <div className={classNames.itemDetails}>
          <span className={classNames.itemName}>{subscriptor.name}</span>
          <span className={classNames.itemLocation}>
            <PinIcon className={classNames.pinIcon} /> {subscriptor.city1}
          </span>
        </div>
      </div>
      <div className={classNames.selectContainer}>
        <Checkbox id={'remember'} defaultChecked={false} onChange={onCheck} value={checked} />
        <ChevronRightIcon className={classNames.chevronIcon} />
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <Shell>
      <PageContent />
    </Shell>
  );
};

export default Home;
