import React, { useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import ApiDocs from '../../api/api-docs';

const ApiDocsPage = () => {
  const [swaggerSpec, setSwaggerSpec] = useState(null);

  useEffect(() => {
    const fetchApiDocs = async () => {
      try {
        const spec = await ApiDocs.fetchOpenApiSpec();
        setSwaggerSpec(spec);
      } catch (error) {
        console.error('Failed to load API docs:', error);
      }
    };

    fetchApiDocs();
  }, []);

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {swaggerSpec ? <SwaggerUI spec={swaggerSpec} /> : <p>Loading API Docs...</p>}
    </div>
  );
};

export default ApiDocsPage;
