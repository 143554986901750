import React from 'react';
import MapIcon from '../../icons/MapIcon';
import UserIcon from '../../icons/UserIcon';
import HomeIcon from '../../icons/HomeIcon';

const classNames = {
  footer:
    'w-full fixed z-10 bottom-0 flex justify-evenly place-items-center text-white p-4 text-center h-[80px] bg-white',
  menuItem: 'flex flex-col text-primary-700 text-sm',
  iconButton:
    'flex justify-center items-center w-10 h-10 transition-transform transform hover:scale-110 hover:bg-gray-200 rounded-full',
  icon: 'w-8 h-8',
};

const Footer = () => {
  return (
    <footer className={classNames.footer}>
      <span className={classNames.menuItem}>
        <button className={classNames.iconButton}>
          <HomeIcon className={classNames.icon} />
        </button>
        <span>Home</span>
      </span>
      <span className={classNames.menuItem}>
        <button className={classNames.iconButton}>
          <MapIcon className={classNames.icon} />
        </button>
        <span>Map</span>
      </span>
      <span className={classNames.menuItem}>
        <button className={classNames.iconButton}>
          <UserIcon className={classNames.icon} />
        </button>
        <span>Profile</span>
      </span>
    </footer>
  );
};

export default Footer;
