import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import client, { schemas } from '../api/client';
import {
  getCurrentValue,
  getRiskValues,
  getRisk,
  getTreatments3,
  getTreatments2,
  getTreatments,
  getTreatments1,
} from '../utils/helpers';
import { dateFormat, ISODateFormat, formatNumber } from '../utils/commonFunctions';
import { toast } from '@tcc/labz-design-system';
import useFetch from './useFetch';
import { useTranslation } from 'react-i18next';
import { components } from '../api/client/schema';

type GrowerOut = components['schemas']['GrowerOut'];

interface GrowerWithLegend extends GrowerOut {
  legend?: any;
  treatments?: any;
}

interface SubscriptionItem {
  created_user_name: string;
  invite_sent?: boolean;
  distributor?: string; // Make distributor optional
  contract: string;
  status: string;
  field_name: string;
  field_area: number;
  price: number;
  observation_date: dayjs.Dayjs | null;
  bbch: any;
  offer_name: string;
  current_bbch_stage: string;
  legend: any;
  risk: any;
  alert_date?: string;
  rec_spray_date1?: any;
  rec_spray_date?: any;
  treatments: any;
  compliance?: boolean | null;
  comments?: any;
}

interface ProductItem {
  created_user_name: string;
  grower_name: string;
  farm_name: string;
  total_area: number;
  order_id: number;
}

const useSubscription = () => {
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const { t } = useTranslation();

  const getAllGrowers = useFetch({
    fetchFunction: async () => await client.GET('/all_growers'),
    onFail: () => toast.error(t('ALERT.MSG11')),
    immediate: false,
  });

  const [data] = getAllGrowers;

  const processField = (
    item: GrowerWithLegend,
    field: schemas['FieldOrderOut']
  ): SubscriptionItem => ({
    ...item,
    created_user_name: item?.created_user?.name || '',
    invite_sent: item?.invite_sent,
    distributor: item?.created_user?.distributor?.name || '', // Safe access
    contract: item.contract || 'Ongoing',
    status: item.status || 'Ongoing',
    field_name: field.field_name,
    field_area: field.area,
    price: formatNumber(field.area * item.order?.offer.price),
    offer_name: item.order.offer?.name,
    observation_date: field.observation_date ? dayjs(field.observation_date, ISODateFormat) : null,
    bbch: getCurrentValue(field.growth_stages, field.current_bbch_stage),
    current_bbch_stage: field.current_bbch_stage || '',
    legend: field.risk?.length && field.risk?.length > 0 ? getRiskValues(field.risk) : {},
    risk: field.risk?.length && field.risk?.length > 0 ? getRisk(field.risk) : {},
    alert_date: field.spray_dates?.[0]?.alert_date,
    rec_spray_date1: field.spray_dates?.length ? getTreatments3(field.spray_dates) : null,
    rec_spray_date: field.spray_dates?.length ? getTreatments2(field.spray_dates) : null,
    treatments: field.treatments?.length ? getTreatments(field.treatments) : {},
    compliance: field.compliance,
    comments: field.compliance_comments || [],
  });

  const processGrower = (grower: GrowerWithLegend): SubscriptionItem => ({
    ...grower,
    created_user_name: grower?.created_user?.name || '',
    distributor: grower?.created_user?.distributor?.name || '',
    contract: grower.contract || 'Ongoing',
    status: grower.status || 'Ongoing',
    field_name: '',
    field_area: 0,
    price: 0,
    offer_name: '',
    observation_date: null,
    risk: {},
    current_bbch_stage: '',
    bbch: '',
    legend: grower?.legend,
    treatments: grower?.treatments,
  });

  const processProduct = (grower: GrowerOut): ProductItem[] => {
    return grower.order?.order_fields
      ? [
          {
            created_user_name: grower?.created_user?.name || '',
            grower_name: grower?.contact_name || '',
            farm_name: grower?.name || '',
            total_area: grower?.order?.order_total_ha || 0,
            order_id: grower?.order?.id || '',
            ...getTreatments1(grower?.order?.product_delivery),
          },
        ]
      : [];
  };

  const { subscriptionList = [], productList = [] } = useMemo(() => {
    if (!data) return { subscriptionList: [], productList: [] };
    const subscriptionList: SubscriptionItem[] = [];
    const productList: ProductItem[] = [];

    data?.data?.forEach((grower) => {
      grower.start_date = dayjs(grower.start_date).format(dateFormat);

      if (grower.order && grower.order?.order_fields) {
        grower?.order?.order_fields.forEach((field) => {
          subscriptionList.push(processField(grower, field));
        });
        productList.push(...processProduct(grower));
      } else {
        subscriptionList.push(processGrower(grower));
      }
    });

    return { subscriptionList, productList };
  }, [data]);

  useEffect(() => {
    setLastUpdated(new Date());
  }, [data]);

  return { subscriptionList, productList, getAllGrowers, lastUpdated };
};

export default useSubscription;
