import React, { useEffect, useState } from 'react';
import GrowerAPI from '../../../api/grower';
import { useStateContext } from '../../../utils/stateContext';
import NotificationsIcon from '../../icons/NotificationsIcon';
import PredimaLogo from '../../icons/PredimaLogo';
import Nav from './Nav';
import { Link } from 'react-router-dom';

const baseClassNames = {
  icon: 'w-8 h-8',
};

const classNames = {
  header: 'flex w-full bg-white min-h-[70px] fixed top-0 z-10 items-center justify-center',
  container: 'container flex w-full justify-between items-center px-8 md:px-0',
  title: 'text-2xl text-primary-700 font-bold',
  user: 'text-md text-gray-600 capitalize text-center',
  leftSection: 'flex w-full md:w-1/4 items-center gap-1 md:gap-2',
  rightSection: 'flex w-full md:w-1/8 items-center justify-end gap-1 md:gap-4',
  navContainer: 'flex h-full flex-grow items-start md:w-1/2', // Center section takes the remaining space
  userIcon: `hidden ${baseClassNames.icon} lg:flex text-gray-500`,
  notificationsIcon: `${baseClassNames.icon} text-primary-500`,
  predimaLogo: 'w-12 h-12',
  predimaTitle:
    'font-helvetica font-light text-[22px] text-[#333333] font-weight italic tracking-widest',
  divider: 'border-l border-gray-300 h-10 mx-4', // Divider with margin
  logoContainer: 'flex items-center gap-1',
  logoutButton: 'h-8 flex items-center',
};

const Header = ({ nav = true }) => {
  const { userDetails, setNotifications } = useStateContext();
  const [, setBadgeContent] = useState(0);

  const getNotifications = async () => {
    const data = await GrowerAPI.getNotifications();
    setNotifications(data);
    setBadgeContent(data.filter((item) => !item.is_read).length);
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames.header}>
      <div className={classNames.container}>
        <div className={classNames.leftSection}>
          <Link to={'/home'} className={classNames.logoContainer}>
            <PredimaLogo className={classNames.predimaLogo} />
            <span className={classNames.predimaTitle}>Predima</span>
          </Link>
        </div>
        <span className={classNames.divider}></span>
        <div className={classNames.navContainer}>{nav && <Nav />}</div>
        <div className={classNames.rightSection}>
          <NotificationsIcon className={classNames.notificationsIcon} />
          <Link to={'/newprofile'} className={classNames.user}>
            {userDetails.name}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
