import dayjs from 'dayjs';
import { useState, useEffect, useMemo } from 'react';
import { getCellValue, stringToBoolean } from '../utils/commonFunctions';

interface DataItem {
  [key: string]: any;
}

const useFilteredList = <T extends DataItem>(initialData: T[], columns) => {
  const [data, setData] = useState<T[]>([]);
  const [criteria, setCriteria] = useState({});

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  const handleUpdateFilter = (columnName) => (event) => {
    const value = event.target.value;
    setCriteria((prevState) => ({
      ...prevState,
      [columnName]: value,
    }));
  };

  const handleUpdateDateFilter = (columnName, criteria) => {
    setCriteria((prevState) => ({
      ...prevState,
      [columnName]: criteria,
    }));
  };

  const handleClearFilters = () => {
    setCriteria({});
  };

  const filteredData = useMemo(() => {
    return data.filter((dataRow) => {
      return columns.every((column) => {
        const filterCriteria = criteria[column.name];
        const searchInput =
          typeof filterCriteria === 'object'
            ? filterCriteria
            : String(filterCriteria || '').toLowerCase();
        const cellValue = getCellValue(dataRow, column);

        if (!searchInput || searchInput === 'all') return true;

        if (column.dropdown) {
          return searchInput === cellValue;
        }

        if (column.boolean) {
          return stringToBoolean(searchInput) === cellValue;
        }

        if (typeof filterCriteria === 'object') {
          const { range, fromDate, toDate, applied } = filterCriteria;
          if (applied) {
            if (range) {
              const date = dayjs(cellValue, column.inputFormat);
              return date.isAfter(fromDate) && date.isBefore(toDate);
            }
            return !cellValue;
          }
          return true;
        }
        if (!cellValue) return false;
        return String(cellValue).toLocaleLowerCase().includes(searchInput);
      });
    });
  }, [data, columns, criteria]);

  return { filteredData, handleUpdateFilter, handleClearFilters, handleUpdateDateFilter, criteria };
};

export default useFilteredList;
