import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { ISODateFormat } from '../../utils/commonFunctions';
import { useStateContext } from '../../utils/stateContext';
import './agronomy.css';
import PredimaTable from '../PredimaTable';

const getTwindowValue = (row) => {
  const treatments = ['T3', 'T2', 'T1'];
  for (let t of treatments) {
    if (row.treatments?.[t]) {
      return t;
    }
  }
  return null;
};

const Summary = () => {
  const { t } = useTranslation();
  const {
    growers,
    setGrowers,
    handleGrowerCheckboxClick,
    subscription: { subscriptionList },
    agronomy: {
      selectedField: [, setSelectedField],
      BBCHModal: [, { on: openBBCHModal }],
      ActualSprayDateModal: [, { on: openActualSprayDateModal }],
      SearchBar: [isSearchBarOpen],
    },
  } = useStateContext();

  const handleEditBBCH = useCallback(
    (row) => {
      setSelectedField({ field_id: row?.field_id, fieldName: row?.field_name });
      openBBCHModal();
    },
    [setSelectedField, openBBCHModal]
  );

  const handleEditActualSprayDate = useCallback(
    (row) => {
      setSelectedField({
        field_id: row?.field_id,
        fieldName: row.field_name,
        treatments: row.treatments,
      });
      openActualSprayDateModal();
    },
    [setSelectedField, openActualSprayDateModal]
  );

  const columnWidth = `${100 / 18}%`;
  const columns = useMemo(() => {
    return [
      { name: 'distributor', label: t('GENERIC.DISTRIBUTOR'), search: true, width: columnWidth },
      {
        name: 'created_user_name',
        label: t('AGRONOMY.SALES_REP_NAME'),
        search: true,
        width: columnWidth,
      },
      {
        name: 'contact_name',
        label: t('SUBSCRIPTION.GROWER_NAME'),
        search: true,
        width: columnWidth,
      },
      { name: 'name', label: t('GENERIC.FARM'), search: true, width: columnWidth, invite: true },
      { name: 'global_risk', label: t('AGRONOMY.GLOBAL_RISK'), risk: true, width: columnWidth },
      {
        name: 'field_name',
        label: t('GENERIC.FIELD'),
        search: true,
        width: columnWidth,
        fieldName: true,
      },
      {
        name: 'bbch',
        label: 'BBCH',
        search: true,
        width: columnWidth,
        valueGetter: (rowData) => rowData.current_bbch_stage?.replace('Z', ''),
      },
      {
        name: 'observation_date',
        label: 'BBCH 30',
        date: true,
        width: columnWidth,
        onClick: (rowData) => handleEditBBCH(rowData),
      },
      {
        name: 'sowing_date',
        label: 'Sowing date',
        date: true,
        width: columnWidth,
      },
      {
        name: 'septoriose',
        label: t('AGRONOMY.SEPTORIA'),
        risk: true,
        width: columnWidth,
        valueGetter: (rowData) => rowData.risk.septoriose,
      },
      {
        name: 'rouillebrune',
        label: t('AGRONOMY.BROWN_RUST'),
        risk: true,
        width: columnWidth,
        valueGetter: (rowData) => rowData.risk.rouillebrune,
      },
      {
        name: 'rouillejaune',
        label: t('AGRONOMY.YELLOW_RUST'),
        risk: true,
        width: columnWidth,
        valueGetter: (rowData) => rowData.risk.rouillejaune,
      },
      {
        name: 'fusariose',
        label: t('AGRONOMY.FUSARIUM'),
        risk: true,
        width: columnWidth,
        valueGetter: (rowData) => rowData.risk.fusariose,
      },
      {
        name: 'treatment_window',
        label: t('ADD_PRODUCT.T_WINDOW'),
        treatmentFiter: true,
        width: columnWidth,
        valueGetter: (rowData) => getTwindowValue(rowData),
      },
      {
        name: 'alert_date',
        label: t('AGRONOMY.ALERT_DATE'),
        date: true,
        width: columnWidth,
        inputFormat: ISODateFormat,
      },
      {
        name: 'rec_spray_date1',
        label: t('AGRONOMY.REC_DATE'),
        date: true,
        width: columnWidth,
        inputFormat: ISODateFormat,
      },
      {
        name: 'treatment_date',
        label: t('AGRONOMY.ACT_DATE'),
        date: true,
        width: columnWidth,
        inputFormat: ISODateFormat,
        onClick: (rowData) => handleEditActualSprayDate(rowData),
        valueGetter: (rowData) =>
          rowData?.treatments?.T3?.treatment_date ||
          rowData?.treatments?.T2?.treatment_date ||
          rowData?.treatments?.T1?.treatment_date,
      },
    ];
  }, [t, columnWidth, handleEditActualSprayDate, handleEditBBCH]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = subscriptionList.map((n) => n.email);
      setGrowers(newSelected);
      return;
    }
    setGrowers([]);
  };

  return (
    <div className={'flex flex-col w-full'}>
      <PredimaTable
        rowKey={'email'}
        data={subscriptionList}
        columns={columns}
        showFilters={isSearchBarOpen}
        onSelectAll={handleSelectAllClick}
        onSelect={handleGrowerCheckboxClick}
        allSelected={growers?.length === subscriptionList?.length}
        selectedRows={growers}
      />
    </div>
  );
};
export default Summary;
