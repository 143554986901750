import React from 'react';
import FieldSelecetionTable from '../../Logistic/FieldSelectionTable';
import ProductShipmentTable from '../../Logistic/ProductShipmentTable';
import FieldStatusFilter from '../../Logistic/FieldStatusFilter';
//import { useStateContext } from '../../../utils/stateContext';
import DynamicTabs from '../../Layout/shared/DynamicTabs';

const FieldSelection = () => {
  //const { logistic } = useStateContext();
  //const { selectedFields, handleSendToShipment } = logistic;
  return (
    //   <ToolBar>
    //     {selectedFields.length > 0 && (
    //       <Button onClick={handleSendToShipment}>
    //         Send {selectedFields.length} fields to shipment
    //       </Button>
    //     )}
    //   </ToolBar>
    <FieldSelecetionTable />
  );
};

const ProductShipment = () => (
  <>
    <FieldStatusFilter />
    <ProductShipmentTable />
  </>
);

const tabs = [
  {
    id: 'field_selection',
    name: 'Field selection',
    default: true,
    children: <FieldSelection />,
  },
  {
    id: 'product_shipment',
    name: 'Product Shipment',
    default: true,
    children: <ProductShipment />,
  },
];

const Logistic = () => {
  return <DynamicTabs tabs={tabs} />;
};

export default Logistic;
