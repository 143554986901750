import { Typography } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import React from 'react';
import { useStateContext } from '../../utils/stateContext';
import { useTranslation } from 'react-i18next';
interface Comment {
  id?: string;
  text?: string;
}
interface ComplianceData {
  compliance: boolean;
  field_id?: string;
  field_name?: string;
  comments?: Comment[];
}
interface ComplianceCellProps {
  data: ComplianceData;
}

const ComplianceCell: React.FC<ComplianceCellProps> = ({
  data: { compliance, field_id, field_name, comments = [] },
}) => {
  const { setOpenComment, setSelectedField } = useStateContext();
  const { t } = useTranslation();
  const YES = t('GENERIC.YES');
  const NO = t('GENERIC.NO');

  return (
    <>
      <Typography sx={{ display: 'inline', fontSize: 13 }}>{compliance ? YES : NO}</Typography>
      <ChatBubbleOutlineIcon
        sx={{
          color: !field_id ? '#d2d2d2' : comments.length > 0 ? '#FF3162' : '#0B617F',
          float: 'right',
          cursor: !field_id ? 'not-allowed' : 'pointer',
        }}
        onClick={() => {
          if (field_id) {
            setSelectedField({
              compliance: compliance,
              id: field_id,
              field_name: field_name,
              comments: comments,
            });
            setOpenComment(true);
          }
        }}
      />
    </>
  );
};

export default ComplianceCell;
