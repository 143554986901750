import { useEffect, useState } from 'react';
import ArvalisAPI from '../api/arvalis';

const useVarietiesSoilTypes = () => {
  const [soilTypes, setSoilTypes] = useState([]);
  const [varieties, setVarieties] = useState([]);

  const fetchData = async () => {
    const data = await ArvalisAPI.getVarietiesSoilTypes();
    await setSoilTypes(data?.soil_types);
    await setVarieties(data?.varieties);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { varieties, soilTypes };
};

export default useVarietiesSoilTypes;
