import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

export interface Option {
  label: string;
  path: string;
}

interface MultiToggleButtonProps {
  options?: Option[];
}

const defaultOptions: Option[] = [
  {
    label: 'Subscription',
    path: '/home/subscription',
  },
  {
    label: 'Agronomy',
    path: '/home/agronomy',
  },
  {
    label: 'Logistic',
    path: '/home/logistic',
  },
];

const classNames = {
  multiToggleButtonClasses:
    'flex border border-[#0b617f] bg-[rgba(0,0,0,0.08)] rounded-[1.2em] w-[35%] overflow-clip relative select-none',
  spanClasses:
    'p-[0.4em] place-content-center flex-grow flex cursor-pointer z-10 transition-all duration-300 delay-200 text-[0.85em]',

  activeSpanClasses: 'text-white',
  selectedPillClasses:
    'absolute bottom-0 left-0 h-full bg-[#0b617f] border border-[#0b617f] rounded-[1.2em] transition-all duration-300 z-0',
};

const MultiToggleButton: React.FC<MultiToggleButtonProps> = ({
  options = defaultOptions,
}: MultiToggleButtonProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [pillPosition, setPillPosition] = useState({ left: 0, width: 0 });
  const optionRefs = useRef<(HTMLSpanElement | null)[]>([]);

  useEffect(() => {
    const currentOption = optionRefs.current[activeIndex];
    if (currentOption) {
      const { offsetLeft, offsetWidth } = currentOption;
      setPillPosition({ left: offsetLeft, width: offsetWidth });
    }
  }, [activeIndex]);

  const handleOptionClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div className={classNames.multiToggleButtonClasses}>
      <div
        className={classNames.selectedPillClasses}
        style={{
          transform: `translateX(${pillPosition.left}px)`,
          width: `${pillPosition.width}px`,
        }}
      />
      {options.map((option, index) => (
        <Link
          key={index}
          to={option.path}
          ref={(el) => (optionRefs.current[index] = el)}
          className={`${classNames.spanClasses} ${activeIndex === index ? classNames.activeSpanClasses : ''}`}
          onClick={() => handleOptionClick(index)}
        >
          {option.label}
        </Link>
      ))}
    </div>
  );
};

export default MultiToggleButton;
