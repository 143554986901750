import React from 'react';

const Eye = ({ width = 25, height = 24, fill = 'currentColor', className = '' }) => {
  return (
    <svg
      viewBox="0 0 25 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9192 12.046C21.75 7.66139 17.2269 4.61523 12.0115 4.61523C6.79615 4.61523 2.27308 7.66139 0.103846 12.046C-0.0346154 12.3229 -0.0346154 12.646 0.103846 12.8768C2.27308 17.2614 6.79615 20.3075 12.0115 20.3075C17.2269 20.3075 21.75 17.2614 23.9192 12.8768C24.0577 12.5998 24.0577 12.3229 23.9192 12.046ZM12.0115 17.5381C9.19611 17.5381 6.93457 15.2766 6.93457 12.4612C6.93457 9.64582 9.19611 7.38428 12.0115 7.38428C14.8269 7.38428 17.0884 9.64582 17.0884 12.4612C17.0884 15.2766 14.8269 17.5381 12.0115 17.5381ZM12.0115 9.23047C10.2115 9.23047 8.78076 10.6612 8.78076 12.4612C8.78076 14.2612 10.2115 15.692 12.0115 15.692C13.8115 15.692 15.2423 14.2612 15.2423 12.4612C15.2423 10.6612 13.8115 9.23047 12.0115 9.23047Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Eye;
