import React, { useCallback, useMemo } from 'react';
import { formatDate, ISODateFormat } from '../../utils/commonFunctions';
import { CircularProgress, TableCell, Typography } from '@mui/material';
import EmptyCellIndicator from './EmptyCellIndicator';
import { useStateContext } from '../../utils/stateContext';
interface TreatmentCellProps {
  rowData: any;
  value: number | string;
  tWindow: 'T1' | 'T2' | 'T3';
  dataFormat?: 'number' | 'date';
  color?: string;
  onClick: (rowData: any, tWindow: string) => void;
}

const TreatmentCell: React.FC<TreatmentCellProps> = ({
  rowData,
  value,
  tWindow,
  dataFormat = 'number',
  color,
  onClick,
}) => {
  const {
    agronomy: {
      selectedField: [selectedField],
      selectedTreatment: [selectedTreatment],
      isSettingActualSprayDate,
      isDeletingTreatments,
    },
  } = useStateContext();
  const getFormattedValue = (value: number | string, dataFormat) => {
    if (dataFormat === 'number') {
      return isNaN(Number(value)) ? <EmptyCellIndicator /> : Number(value).toFixed(2);
    }
    if (dataFormat === 'date' && value) {
      return formatDate(value, { inputFormat: ISODateFormat });
    }
    if (dataFormat === 'string' && value) {
      return value;
    }
    return <EmptyCellIndicator />;
  };

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(rowData, tWindow);
    }
  }, [rowData, tWindow, onClick]);

  const isUpdating = useMemo(() => {
    const isSelectedField = rowData?.field_id && rowData?.field_id === selectedField?.field_id;
    const isBusy = (isSettingActualSprayDate || isDeletingTreatments) && isSelectedField;
    const isUpdating = isBusy && selectedTreatment.t_window === tWindow;
    return isUpdating;
  }, [
    isSettingActualSprayDate,
    isDeletingTreatments,
    selectedField,
    rowData,
    selectedTreatment,
    tWindow,
  ]);

  return (
    <TableCell
      onClick={handleClick}
      sx={{
        cursor: !!onClick ? 'pointer' : 'default',
        position: 'relative',
        ...(!!onClick && {
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: color,
            color: '#FFF',
          },
        }),
      }}
      align="center"
    >
      {isUpdating && <CircularProgress size={20} />}
      {!isUpdating && (
        <Typography fontSize={13} noWrap>
          {getFormattedValue(value, dataFormat)}
        </Typography>
      )}
    </TableCell>
  );
};

export default TreatmentCell;
