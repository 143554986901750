import React from 'react';
import { formatDate } from '../../utils/commonFunctions';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Typography } from '@mui/material';
import EmptyCellIndicator from './EmptyCellIndicator';

interface RowData {
  observation_date?: string;
}

interface ObservationDateCellProps {
  rowData: RowData;
  onClick: (rowData: RowData) => void;
}

const ObservationDateCell: React.FC<ObservationDateCellProps> = ({ rowData, onClick }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <Typography fontSize={13} noWrap>
      {rowData.observation_date ? formatDate(rowData.observation_date) : <EmptyCellIndicator />}
    </Typography>
    <EditOutlinedIcon
      color="primary"
      fontSize={'small'}
      className="tooltiptext1"
      onClick={() => onClick(rowData)}
    />
  </Box>
);

export default ObservationDateCell;
