import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const classNames = {
  navContainer: 'hidden md:flex h-[70px] relative',
  navList: 'flex justify-around items-center',
  navLink: 'py-2 px-4 h-full text-md text-gray-500 hover:text-gray-800',
  activeLink: 'text-gray-800',
  indicator: 'absolute h-[5px] bottom-0 bg-blue-500 transition-all duration-300',
};

const Nav = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [indicatorLeft, setIndicatorLeft] = useState(0);
  const indicatorRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith('/home/agronomy')) {
      setActiveLink('agronomy');
    } else if (path.startsWith('/home/logistic')) {
      setActiveLink('logistic');
    } else {
      setActiveLink('home');
    }
  }, [location]);

  useEffect(() => {
    const activeLinkElement = document.getElementById(activeLink);
    if (activeLinkElement) {
      const { offsetWidth, offsetLeft } = activeLinkElement;
      setIndicatorWidth(offsetWidth);
      setIndicatorLeft(offsetLeft);
    }
  }, [activeLink]);

  return (
    <nav className={classNames.navContainer}>
      <ul className={classNames.navList}>
        <li>
          <Link
            id="home"
            to="/home"
            className={`${classNames.navLink} ${activeLink === 'home' ? classNames.activeLink : ''}`}
          >
            {t('NAV.SUBSCRIPTION')}
          </Link>
        </li>
        <li>
          <Link
            id="agronomy"
            to="/home/agronomy"
            className={`${classNames.navLink} ${activeLink === 'agronomy' ? classNames.activeLink : ''}`}
          >
            {t('NAV.AGRONOMY')}
          </Link>
        </li>
        {/* <li>
          <Link
            id="logistic"
            to="/home/logistic"
            className={`${classNames.navLink} ${activeLink === 'logistic' ? classNames.activeLink : ''}`}
          >
            {t('NAV.LOGISTIC')}
          </Link>
        </li> */}
      </ul>
      <div
        ref={indicatorRef}
        className={classNames.indicator}
        style={{
          width: indicatorWidth,
          left: indicatorLeft,
        }}
      />
    </nav>
  );
};

export default Nav;
