import LoginAPI from '../../api/login';
import UserAPI from '../../api/user';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from '@tcc/labz-design-system';
import useFetch from '../useFetch';
import useRegistrationForm from './useRegistrationForm';

const useRegistration = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRegister = async (data) => {
    localStorage.clear();

    try {
      const accountSearch = await LoginAPI.accountSearch(data.email);
      const userExists = accountSearch.results.length > 0;

      if (!userExists) {
        await initRegistration();
        await registerUser({ data, regToken: initRegistrationData?.regToken });
      }

      const loginResult = await LoginAPI.loginUser({
        loginID: data.email,
        password: data.password,
      });

      if (loginResult.statusCode === 403 || loginResult.statusCode !== 200) {
        if (loginResult.statusCode === 403) toast.error(t('ALERT.MSG13'));
        return;
      }

      const tokenResponse = await LoginAPI.getJWT(loginResult?.UID);

      if (tokenResponse.statusCode !== 200) {
        toast.error(t('ALERT.MSG11'));
        return;
      }

      const id_token = tokenResponse.id_token;
      localStorage.setItem('token', JSON.stringify(id_token));

      await addUser();
      navigate('/home');
    } catch (error) {
      toast.error(t('ALERT.MSG21'));
    }
  };

  const form = useRegistrationForm(handleRegister);

  const handleFetchError = () => toast.error(t('ALERT.MSG21'));

  const [initRegistrationData, , , initRegistration] = useFetch({
    fetchFunction: LoginAPI.initRegistration,
    onFail: () => {
      handleFetchError();
    },
  });

  const [, , , registerUser] = useFetch({
    fetchFunction: LoginAPI.register,
    onFail: (error) => handleFetchError(),
  });

  const [, , , addUser] = useFetch({
    fetchFunction: UserAPI.addUser,
    onSuccess: () => {
      toast.success(t('ALERT.MSG15'));
      setTimeout(() => navigate('/'), 3000);
    },
    onFail: handleFetchError,
  });

  return {
    form,
  };
};

export default useRegistration;
