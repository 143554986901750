import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import CheckIcon from '../icons/CheckIcon';
import CrossIcon from '../icons/CrossIcon';
import { useTranslation } from 'react-i18next';

const classNames = {
  list: 'hidden md:block',
  item: 'flex items-center gap-4 text-gray-500 mt-2',
  itemOn: 'text-green-400',
  itemOff: 'text-red-400',
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('required')
    .min(8, 'length')
    .matches(/[a-z]/, 'lowercase')
    .matches(/[A-Z]/, 'uppercase')
    .matches(/[0-9]/, 'number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'special'),
});

type ErrorFlags = {
  required: boolean;
  minLength: boolean;
  lowercase: boolean;
  uppercase: boolean;
  number: boolean;
  specialCharacter: boolean;
};

const PasswordErrorDisplay = ({ password }: { password: string }) => {
  const { t } = useTranslation();
  const [errorFlags, setErrorFlags] = useState<ErrorFlags>({
    required: false,
    minLength: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialCharacter: false,
  });

  const validatePassword = async (password: string): Promise<ErrorFlags> => {
    const errors: ErrorFlags = {
      required: false,
      minLength: false,
      lowercase: false,
      uppercase: false,
      number: false,
      specialCharacter: false,
    };

    try {
      await validationSchema.validate({ password }, { abortEarly: false });
    } catch (error: any) {
      error.errors.forEach((err: string) => {
        if (err.includes('required')) {
          errors.required = true;
        }
        if (err.includes('length')) {
          errors.minLength = true;
        }
        if (err.includes('lowercase')) {
          errors.lowercase = true;
        }
        if (err.includes('uppercase')) {
          errors.uppercase = true;
        }
        if (err.includes('number')) {
          errors.number = true;
        }
        if (err.includes('special')) {
          errors.specialCharacter = true;
        }
      });
    }

    return errors;
  };

  useEffect(() => {
    const checkPassword = async () => {
      const flags = await validatePassword(password);
      setErrorFlags(flags);
    };

    checkPassword();
  }, [password]);

  const Item = ({ children, state }: { children: string; state: boolean }) => {
    return (
      <li className={`${classNames.item}`}>
        {state ? <CrossIcon /> : <CheckIcon />}
        {children}
      </li>
    );
  };

  return (
    <ul className={classNames.list}>
      <Item state={errorFlags.minLength}>{t('PASSWORD.HINT1')}</Item>
      <Item state={errorFlags.uppercase || errorFlags.lowercase}>{t('PASSWORD.HINT2')}</Item>
      <Item state={errorFlags.number}>{t('PASSWORD.HINT3')}</Item>
      <Item state={errorFlags.specialCharacter}>{t('PASSWORD.HINT4')}</Item>
    </ul>
  );
};

export default PasswordErrorDisplay;
