import React from 'react';
import { TableSortLabel } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';

const style = {
  '&.MuiTableSortLabel-root': {
    color: 'white',
    fontSize: '95%',
    padding: 0,
    margin: '5px 0px',
  },
  '&.MuiTableSortLabel-root:hover': {
    color: 'white',
    padding: 0,
    margin: '5px 0px',
  },
  '&.Mui-active': {
    color: 'white',
    padding: 0,
    margin: '5px 0px',
  },
  '& .MuiTableSortLabel-icon': {
    color: 'white !important',
    padding: 0,
    margin: '5px 0px',
  },
};

const TableSortLabelCustom = ({ sortDirection, onClick, text, active }) => {
  return (
    <TableSortLabel
      active={active}
      sx={style}
      direction={sortDirection}
      IconComponent={ExpandMore}
      onClick={onClick}
    >
      {text}
    </TableSortLabel>
  );
};

export default TableSortLabelCustom;
