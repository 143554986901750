import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import useFilteredList from '../hooks/useFilteredList';
import useSortedList from '../hooks/useSortedList';
import CustomTablePagination from '../utils/CustomTablePagination';
import { BpCheckbox, NoDataAvailable, formatDate, getCellValue } from '../utils/commonFunctions';
import ComplianceCell from './Table/ComplianceCell';
import FilterBar from './Table/FilterBar';
import HeaderRow from './Table/HeaderRow';
import InviteGrowerCell from './Table/InviteGrowerCell';
import ProductCell from './Table/ProductCell';
import ProductSendCell from './Table/ProductSendCell';
import RiskCell from './Table/RiskCell';
import BBCHCell from './Table/BBCHCell';
import ObservationDateCell from './Table/ObservationDateCell';
import TreatmentDateCell from './Table/TreatmentDateCell';
import TreatmentCell from './Table/TreatmentCell';
import EmptyCellIndicator from './Table/EmptyCellIndicator';
import FieldNameCell from './Table/FieldNameCell';
import usePagination from '../hooks/usePagination';
import useAggregations from '../hooks/useAggregations';
import AggregationsRow from './Table/AggregationsRow';

interface PredimaTableColumn {
  [key: string]: any;
  name: string;
}
interface PredimaTableProps {
  data: any[];
  columns: PredimaTableColumn[];
  showFilters: boolean;
  loading: boolean;
  onSelect: (rowId: string) => any;
  onSelectAll: (e: any) => any;
  selectable: boolean;
  width?: string;
  allSelected: boolean;
  selectedRows?: any[];
  rowKey?: string;
}

const PredimaTable = ({
  data = [],
  columns,
  showFilters = false,
  loading = false,
  onSelect = (rowId: string) => {},
  onSelectAll = () => {},
  selectable = true,
  width = '100%',
  allSelected = false,
  selectedRows = [],
  rowKey = 'id',
}: PredimaTableProps): JSX.Element => {
  const { filteredData, ...filterProps } = useFilteredList(data, columns);
  const { sortedData, ...sortingProps } = useSortedList(filteredData, columns);
  const { paginatedData, ...paginationProps } = usePagination(sortedData);

  const aggregations = useAggregations(filteredData, columns);

  const { t } = useTranslation();

  const singleColums = useMemo(() => {
    return columns.filter((column) => !column.groupHeader);
  }, [columns]);

  return (
    <>
      <TableContainer sx={{ height: '100%', maxHeight: 600 }} className="rounded-tr-lg">
        <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: width }}>
          <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
            <HeaderRow
              {...sortingProps}
              columns={columns}
              onSelectAll={onSelectAll}
              checked={allSelected}
              selectable={selectable}
            />
            <FilterBar
              {...filterProps}
              isOpen={showFilters}
              columns={singleColums}
              selectable={selectable}
            />
          </TableHead>
          <TableBody>
            {paginatedData?.map((rowData, index) => {
              return (
                <TableRow
                  key={`${rowData[rowKey]}${index}`}
                  sx={{
                    transition: 'background-color.3s',

                    '&:hover': {
                      backgroundColor: 'rgba(50,50,50,0.1)',
                    },
                    height: 50,
                  }}
                >
                  {selectable && (
                    <TableCell padding="checkbox">
                      <BpCheckbox
                        color="primary"
                        checked={!!selectedRows.find((row) => row[rowKey] === rowData?.[rowKey])}
                        onChange={() => onSelect(rowData)}
                      />
                    </TableCell>
                  )}
                  {singleColums.map((column, i) => {
                    const cellValue = getCellValue(rowData, column);
                    const key = `${column.name}${i}`;

                    if (column.name === 'bbch') {
                      return (
                        <TableCell key={key} align="center" className="tooltip">
                          <BBCHCell rowData={rowData} />
                        </TableCell>
                      );
                    }
                    if (column.name === 'observation_date') {
                      return (
                        <TableCell key={key} align="center" className="tooltip1">
                          <ObservationDateCell rowData={rowData} onClick={column.onClick} />
                        </TableCell>
                      );
                    }
                    if (column.name === 'treatment_date') {
                      return (
                        <TableCell key={key} align="center" className="tooltip1">
                          <TreatmentDateCell rowData={rowData} onClick={column.onClick} />
                        </TableCell>
                      );
                    }
                    if (column.treatment) {
                      return (
                        <TreatmentCell
                          key={key}
                          rowData={rowData}
                          tWindow={column.tWindow}
                          value={cellValue}
                          dataFormat={column.inputFormat}
                          onClick={column.onClick}
                          color={column.color}
                        />
                      );
                    }
                    if (column.compliance) {
                      return (
                        <TableCell key={key} align="center">
                          <ComplianceCell data={rowData} />
                        </TableCell>
                      );
                    }
                    if (column.risk) {
                      return (
                        <TableCell key={key} align="center">
                          <RiskCell rowData={rowData} columnName={column.name} />
                        </TableCell>
                      );
                    }
                    if (column.date) {
                      return (
                        <TableCell key={key} align="center">
                          <Typography fontSize={13} noWrap textAlign={'center'}>
                            {cellValue &&
                              formatDate(cellValue, { inputFormat: column.inputFormat })}
                            {!cellValue && <EmptyCellIndicator />}
                          </Typography>
                        </TableCell>
                      );
                    }
                    if (column.invite) {
                      return (
                        <TableCell key={key} align="center">
                          <InviteGrowerCell rowData={rowData} onClick={column.onClick} />
                        </TableCell>
                      );
                    }
                    if (column.productSend) {
                      return (
                        <TableCell key={key} align="center">
                          <ProductSendCell
                            rowData={rowData}
                            value={cellValue}
                            tWindow={column.tWindow}
                            onClick={() => column.onClick(rowData)}
                          />
                        </TableCell>
                      );
                    }
                    if (column.product) {
                      return (
                        <TableCell
                          key={key}
                          align="center"
                          onClick={() => column.onClick(rowData)}
                          sx={{
                            transition: 'background-color.3s',
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: column.color,
                              color: '#FFF',
                            },
                          }}
                        >
                          <ProductCell rowData={rowData} tWindow={column.tWindow} />
                        </TableCell>
                      );
                    }
                    if (column.fieldName) {
                      return (
                        <TableCell
                          key={key}
                          align="center"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => column.onClick && column.onClick(rowData)}
                        >
                          <FieldNameCell rowData={rowData} />
                        </TableCell>
                      );
                    }
                    if (column.boolean) {
                      return (
                        <TableCell
                          key={key}
                          align="center"
                          onClick={() => column.onClick && column.onClick(rowData)}
                        >
                          <Typography fontSize={13} noWrap>
                            {column.boolean && <>{cellValue ? 'Yes' : 'No'}</>}
                          </Typography>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={key}
                        align="center"
                        onClick={() => column.onClick && column.onClick(rowData)}
                      >
                        <Typography fontSize={13} noWrap>
                          {cellValue || <EmptyCellIndicator />}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <AggregationsRow aggregations={aggregations} columns={columns} />
        </Table>

        {!loading && sortedData.length === 0 && (
          <NoDataAvailable text1={t('HOME.NO_DATA_NOTE')} text2={t('HOME.ADD_GROWER_TEXT')} />
        )}
      </TableContainer>
      <CustomTablePagination {...paginationProps} />
    </>
  );
};

export default PredimaTable;
