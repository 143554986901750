import Header from './Header';
import Footer from './Footer';
import { CircularProgress } from '@mui/material';
import { ToastContainer } from '@tcc/labz-design-system';

export const shellStyles = 'flex flex-col items-center w-full min-h-screen mb-8';
export const headingText = 'font-noto text-sm font-bold leading-tight text-left';
export const pillText = 'font-noto font-medium text-sm';
export const titleText = 'font-noto font-semibold text-lg';
export const mainStyles =
  'flex flex-col w-full pb-4 mt-[80px] md:mt-[70px] overflow-scroll items-center';
const mainContainer = 'container flex h-full';
export const loadingOverlay =
  'absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-30 h-[100vh] w-[100vw]';

const Shell = ({ children, footer = true, header = true, nav = true, loading = false }) => {
  let yMargin = 0;
  if (header) yMargin += 70;
  if (footer) yMargin += 80;

  return (
    <div className={shellStyles}>
      {header && <Header nav={nav} />}
      <main style={{ height: `calc(100vh - ${yMargin}px)` }} className={mainStyles}>
        <div className={mainContainer}>{children}</div>
      </main>
      {footer && <Footer></Footer>}
      {/* Loading Overlay */}
      {loading && (
        <div className={loadingOverlay}>
          <CircularProgress />
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Shell;
