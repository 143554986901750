import React from 'react';

const HomeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 1024 1024"
      className="icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M994.56 411.946667l-469.333333-384h-1.92l-3.84-2.133334-3.626667-1.066666L512 23.466667h-3.84l-3.84 1.066666-3.84 1.92h-1.92l-469.333333 384a21.333333 21.333333 0 1 0 27.093333 33.066667L512 72.106667l455.68 372.906666a21.333333 21.333333 0 1 0 27.093333-33.066666z"
        fill="currentColor"
      />
      <path
        d="M853.333333 449.706667a21.333333 21.333333 0 0 0-21.333333 21.333333v490.666667h-170.666667v-298.666667a21.333333 21.333333 0 0 0-21.333333-21.333333H384a21.333333 21.333333 0 0 0-21.333333 21.333333v298.666667H192v-490.666667a21.333333 21.333333 0 0 0-42.666667 0v512a21.333333 21.333333 0 0 0 21.333334 21.333333h213.333333a21.333333 21.333333 0 0 0 21.333333-21.333333v-298.666667h213.333334v298.666667a21.333333 21.333333 0 0 0 21.333333 21.333333h213.333333a21.333333 21.333333 0 0 0 21.333334-21.333333v-512a21.333333 21.333333 0 0 0-21.333334-21.333333z"
        fill="currentColor"
      />
      <path
        d="M704 492.373333a21.333333 21.333333 0 0 0 0-42.666666h-128a21.333333 21.333333 0 0 0 0 42.666666zM490.666667 492.373333a21.333333 21.333333 0 0 0 0-42.666666h-85.333334a21.333333 21.333333 0 0 0 0 42.666666zM302.08 482.986667a35.84 35.84 0 0 0 2.56 3.2 21.333333 21.333333 0 0 0 7.04 4.48 20.053333 20.053333 0 0 0 16.213333 0 21.333333 21.333333 0 0 0 7.04-4.48l2.56-3.2a16.64 16.64 0 0 0 1.92-3.84 13.226667 13.226667 0 0 0 1.28-3.84 29.653333 29.653333 0 0 0 0-4.266667 22.4 22.4 0 0 0-6.186666-15.146667 21.333333 21.333333 0 0 0-23.253334-4.48 21.333333 21.333333 0 0 0-7.04 4.48 21.333333 21.333333 0 0 0-6.186666 15.146667 32.64 32.64 0 0 0 0 4.266667 13.866667 13.866667 0 0 0 1.28 3.84 15.786667 15.786667 0 0 0 2.773333 3.84z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HomeIcon;
