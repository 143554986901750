import { DefaultValues } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useFormFactory from '../useFormFactory';

type LoginFormValues = {
  email: string;
  password: string;
};

const useLoginForm = (onSubmit) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
    password: Yup.string().required(t('REGISTER.ERR_PASS')),
  });

  const defaultValues: DefaultValues<LoginFormValues> = {
    email: '',
    password: '',
  };

  const formFactoryProps = {
    defaultValues,
    validationSchema,
    onSubmit,
  };

  return {
    ...useFormFactory(formFactoryProps),
  };
};

export default useLoginForm;
