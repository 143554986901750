import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import {
  Box,
  Paper,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FlexBox1, FlexBox } from '../../../utils/commonFunctions';
import FarmHouse from '../../../images/house.png';
import { useStateContext } from '../../../utils/stateContext';
import GrowerAPI from '../../../api/grower';
import { Button } from '@tcc/labz-design-system';
import {
  PersonOutlined,
  PhoneAndroidOutlined,
  PhoneOutlined,
  EmailOutlined,
  Login,
  HouseOutlined,
  LocalShippingOutlined,
} from '@mui/icons-material';
import { toast } from '@tcc/labz-design-system';

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { initialState } = useStateContext();
  const { sharedState } = initialState;
  const [status, setStatus] = useState(sharedState?.contract || 'Ongoing');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value);
  };

  const submit = async () => {
    const response = await GrowerAPI.updateGrower({
      uuid: sharedState?.uuid,
      contract: status,
    });

    if (response.status === 200 || response.status === 201) {
      toast.success(t('ALERT.MSG10'));
      setTimeout(() => {
        navigate('/home');
      }, 3000);
    }
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: '#fff',
          border: '2px solid #E6E9ED',
          borderRadius: '5px',
          margin: '1vw',
          width: '98%',
          height: '88vh',
          overflow: 'auto',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '45%', height: '100%', padding: '1vw' }}>
            <Box sx={{ display: 'flex', gap: '3vw' }}>
              <Paper sx={{ maxWidth: 200 }}>
                <FlexBox1>
                  <img
                    src={FarmHouse}
                    alt=""
                    style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                  />
                  <Typography sx={{ lineHeight: '20px' }}>
                    <b>{sharedState?.order?.order_total_no_fields}</b>
                    <br />
                    <span style={{ fontSize: '80%' }}>{t('TOTAL.FIELDS')}</span>
                  </Typography>
                </FlexBox1>
              </Paper>
              <Paper sx={{ maxWidth: 200 }}>
                <FlexBox1>
                  <img
                    src={FarmHouse}
                    alt=""
                    style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                  />
                  <Typography sx={{ lineHeight: '20px' }}>
                    <b>{sharedState?.order?.order_total_ha}</b>
                    <br />
                    <span style={{ fontSize: '80%' }}>{t('TOTAL.HECTARES')}</span>
                  </Typography>
                </FlexBox1>
              </Paper>
              <Paper sx={{ maxWidth: 200 }}>
                <FlexBox1>
                  <img
                    src={FarmHouse}
                    alt=""
                    style={{ borderRadius: '50%', height: '30px', width: '30px' }}
                  />
                  <Typography sx={{ lineHeight: '20px' }}>
                    <b>{sharedState?.order?.order_price.toFixed(2)}</b>
                    <br />
                    <span style={{ fontSize: '80%' }}>{t('TOTAL.PRICE')} </span>
                  </Typography>
                </FlexBox1>
              </Paper>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.5vw 1vw 0vw 0.5vw',
              }}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: 550 }}>
                {t('PERSONAL.DETAILS')}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} columnSpacing={2}>
                <Grid item xs={4}>
                  <FlexBox
                    children={<PersonOutlined />}
                    text1={t('GENERIC.CONTACT')}
                    text2={sharedState?.contact_name}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FlexBox
                    children={<PhoneAndroidOutlined />}
                    text1={t('GENERIC.MOBILE_NO')}
                    text2={sharedState?.mobile_no || 'not available'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FlexBox
                    children={<PhoneOutlined />}
                    text1={t('GENERIC.TELEPHONE')}
                    text2={sharedState?.telephone_no}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FlexBox
                    children={<EmailOutlined />}
                    text1={t('REGISTRATION.EMAIL')}
                    text2={sharedState?.email}
                  />
                </Grid>
                <Grid item sx={{ ml: '5vw' }}>
                  <FlexBox
                    children={<Login />}
                    text1={t('LOGIN.TEXT')}
                    text2={sharedState?.app_login ? 'Yes' : 'No'}
                  />
                </Grid>
              </Grid>
            </Box>
            <hr style={{ color: '#0091DF4A', display: 'block' }} />
            <Typography sx={{ fontSize: '12px', fontWeight: 550, mt: 2 }}>
              {t('FARM.DETAILS')}
            </Typography>
            <Box sx={{ display: 'flex', gap: '3vw', mt: 1 }}>
              <FlexBox
                children={<HouseOutlined />}
                text1={t('GENERIC.FARM')}
                text2={sharedState?.name}
              />
              <FlexBox
                children={<HouseOutlined />}
                text1={t('GENERIC.ADDRESS')}
                text2={sharedState?.address1}
              />
            </Box>
            <hr style={{ color: '#0091DF4A', display: 'block', margin: '1vw 0vw' }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.5vw 1vw 0vw 0.5vw',
              }}
            >
              <Typography sx={{ fontSize: '12px', fontWeight: 550 }}>
                {t('GENERIC.DELIVERY') + ' ' + t('GENERIC.DETAILS')}
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FlexBox
                    children={<PersonOutlined />}
                    text1={t('GENERIC.CONTACT')}
                    text2={sharedState?.order?.order_contact_name || 'not available'}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FlexBox
                    children={<PhoneAndroidOutlined />}
                    text1={t('GENERIC.MOBILE_NO')}
                    text2={sharedState?.order?.order_mobile_no || 'not available'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FlexBox
                    children={<LocalShippingOutlined />}
                    text1={t('GENERIC.DEL_ADDRESS')}
                    text2={sharedState?.order?.order_del_address || 'not available'}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ width: '55%', height: '100%', padding: '1vw' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 550 }}>
                {t('FIELD.DETAILS')}
              </Typography>
            </Box>
            <Box sx={{ backgroundColor: '#E9F9FF' }}>
              <Box sx={{ display: 'flex', gap: '2vw', padding: '1vw' }}>
                <FormControl size="small" sx={{ width: 200 }}>
                  <InputLabel>{t('GENERIC.CONTRACT') + ' ' + t('GENERIC.STATUS')}</InputLabel>
                  <Select
                    value={status}
                    label={t('GENERIC.CONTRACT') + ' ' + t('GENERIC.STATUS')}
                    onChange={handleChange}
                  >
                    <MenuItem value="Ongoing">Ongoing</MenuItem>
                    <MenuItem value="Signed">Signed</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={submit}>{t('GENERIC.SAVE')}</Button>
              </Box>
              {sharedState?.order?.order_fields.map((field) => {
                return (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '3vw', padding: '1vw' }}>
                      <Typography variant="body2">
                        {t('GENERIC.FIELD')}
                        <br />
                        <b>{field.field_name}</b>
                      </Typography>
                      <Typography variant="body2">
                        {t('GENERIC.SIZE')}
                        <br />
                        {field.area}
                      </Typography>
                      <Typography variant="body2">
                        {t('FIELD.VARIETY')}
                        <br />
                        {field?.variety.variety_name}
                      </Typography>
                      <Typography variant="body2">
                        {t('FIELD.MIXED')}
                        <br />
                        {field?.mixed_variety ? 'Yes' : 'No'}
                      </Typography>
                      <Typography variant="body2">
                        {t('FIELD.PREVIOUS_CROP')}
                        <br />
                        {field?.previous_crop}
                      </Typography>
                      <Typography variant="body2">
                        {t('FIELD.TILLAGE')}
                        <br />
                        {field?.tillage ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                    <hr style={{ color: '#0091DF4A', display: 'block' }} />
                  </>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const Tabs = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const GROWER_OVERVIEW = t('GROWER_OVERVIEW');
  const tabs = [
    {
      id: 'grower_overview',
      name: GROWER_OVERVIEW,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const GrowerOverview = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default GrowerOverview;
