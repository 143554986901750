import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, ToastContainer } from '@tcc/labz-design-system';
import useRegistration from '../../hooks/registration/useRegistration';
import useBoolean from '../../hooks/useBoolean';
import TermsAndConditions from '../../utils/TermsAndConditions';
import LoginTitle from '../Layout/LoginTitle';
import FormInput from '../Forms/FormInput';
import PasswordErrorDisplay from '../Forms/PasswordErrorDisplay';
import LanguageSelect from '../Forms/FormSelect';

const classNames = {
  loginContainer: 'h-[100vh] bg-white sm:bg-[#e6e9ed] flex flex-col w-full items-center',
  registerForm:
    'flex flex-col p-8 gap-8 mt-12 w-full sm:w-[450px] md:w-[750px] items-center justify-center bg-white sm:rounded-md sm:shadow-custom-modal ',
  loginText: 'font-helvetica font-light text-[22px] text-[#333333] font-weight',
  fieldError: 'text-red-600 text-sm',
  loginButton:
    'w-full bg-primary-800 border-primary-800 text-lg font-medium hover:bg-primary-900 hover:border-primary-900 transition-all',
  links: 'text-primary-800 hover:text-primary-600',

  noAccountText: 'text-xs border-t-2 sm:border-0 pt-1 text-[#656568]',
  inputContainer: 'flex flex-col w-full',
  rowContainer: 'w-full flex flex-col gap-8 md:flex-row md:gap-4',
  inputLabel: 'font-helvetica text-[#333333]',
  registerNowContainer:
    'flex flex-col w-full flex gap-4 items-center py-4 bg-white sm:bg-transparent',
  termsContainer: 'flex gap-1',
  registerError: 'flex justify-center w-full text-red-600 text-sm',
  selectInput: 'w-full',
};

const Registration = () => {
  const { t } = useTranslation();
  const {
    form: {
      fields,
      languageOptions,
      handleSubmit,
      formState: { isSubmitting, isValid },
      values,
    },
  } = useRegistration();

  const [termsAccepted, { toggle }] = useBoolean(false);
  const [isTermsOpen, { toggle: toggleTermsModal }] = useBoolean(false);
  const [isPasswordFocused, { toggle: togglePasswordFocused }] = useBoolean(false);

  return (
    <>
      <div className={classNames.loginContainer}>
        <form onSubmit={handleSubmit} className={classNames.registerForm}>
          <LoginTitle />
          <span className={classNames.loginText}>{t('REGISTRATION.TEXT')}</span>

          <div className={classNames.rowContainer}>
            <FormInput field={fields.firstName} label={t('REGISTRATION.FIRST')} />
            <FormInput field={fields.lastName} label={t('REGISTRATION.LAST')} />
          </div>
          <div className={`${classNames.rowContainer} relative`}>
            <FormInput field={fields.email} label={t('LOGIN.EMAIL')} />
            <FormInput
              field={fields.password}
              label={t('LOGIN.PASSWORD')}
              onFocus={() => togglePasswordFocused(true)}
              type="password"
            />
            {isPasswordFocused && (
              <div className={'absolute right-[-40%]'}>
                <PasswordErrorDisplay password={values.password} />
              </div>
            )}
          </div>
          <div className={classNames.rowContainer}>
            <div className={classNames.inputContainer}>
              <LanguageSelect field={fields.language} options={languageOptions} />
            </div>
            <FormInput
              field={fields.confirmPassword}
              label={t('REGISTRATION.CONFIRM')}
              type="password"
            />
          </div>

          <div className={classNames.termsContainer}>
            <Checkbox
              id="terms"
              value={termsAccepted}
              defaultChecked={false}
              onChange={() => toggle(!termsAccepted)}
            />
            <span className="font-helvetica text-[#333333]">{t('REGISTRATION.ACCEPT')}</span>
            <span
              className="text-primary-800 hover:text-primary-600 cursor-pointer"
              onClick={() => toggleTermsModal(true)}
            >
              {t('REGISTRATION.ACS')}
            </span>
          </div>

          <Button
            className={classNames.loginButton}
            type="submit"
            disabled={!termsAccepted || isSubmitting || !isValid}
          >
            {isSubmitting ? t('LOGIN.LOADING') : t('REGISTRATION.REGISTER')}
          </Button>
        </form>
        <div className={classNames.registerNowContainer}>
          <span className={classNames.noAccountText}>{t('GENERIC.ALREADY')}?</span>
          <Link className={classNames.links} to="/">
            {t('GENERIC.LOGIN')}
          </Link>
        </div>
      </div>
      <TermsAndConditions open={isTermsOpen} setOpenTandC={toggleTermsModal} />
      <ToastContainer />
    </>
  );
};

export default Registration;
