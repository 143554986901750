import React from 'react';

const ArvalisLogo = ({ width = '191', height = '35.545', className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 191 35.545"
    width={width}
    height={height}
    className={className}
  >
    <path
      d="M17.543 6.373s2.452.6 5.259 1.093c.188-.9.391-1.857.6-2.782A37.394 37.394 0 0 1 24.674 0c-3.7 1.236-11.83 1.478-11.83 1.478-4.331.239-8.906 2.342-9.921 6.884C2.107 12.031 0 23.195 0 23.195h.011C.253 22.7 4.889 13.311 6.55 10.2c2.118-3.956 6.91-4.725 10.993-3.827"
      fill="#f79f00"
    />
    <path
      d="M22.8 7.466c-.4 1.945-.737 3.652-.893 4.32-1.627 6.846-5.407 10.49-11.244 10.817l-4.755.264-5.9.327-.008.021 10.054 2.209c5.5 1.206 9.957-1.2 13.24-7.134.772-1.413 4.105-8.256 5.616-10.229a31.133 31.133 0 0 1-6.11-.595"
      fill="#00879c"
    />
    <path
      d="M10.663 22.603c5.836-.327 9.617-3.971 11.246-10.816.156-.669.488-2.376.893-4.32-2.807-.488-5.259-1.093-5.259-1.093-4.083-.9-8.875-.129-10.992 3.827C4.889 13.311.254 22.702.011 23.194l5.9-.327Z"
      fill="#116967"
    />
    <path
      d="M37.302.904h5.542L54.169 25.82h-5.7l-1.767-3.94H33.487l-1.767 3.94h-5.743Zm7.63 16.807-3.012-7.076c-.8-1.874-1.807-4.361-1.847-4.437-.04.076-1.044 2.563-1.847 4.437l-3.012 7.076Z"
      fill="#116967"
    />
    <path
      d="M57.142.904h11.165c7.671 0 11.687 4.284 11.687 9.6a8.811 8.811 0 0 1-4.94 7.88l5.422 7.436H74.01l-4.016-5.944a14.368 14.368 0 0 1-2.209.191h-5.02v5.753h-5.622Zm11.325 14.574c3.293 0 5.582-2.257 5.582-4.973 0-2.831-2.289-5.049-5.542-5.049h-5.743v10.022Z"
      fill="#116967"
    />
    <path
      d="M80.998.903h5.743l5.7 14.627c.562 1.415 1.526 4.169 1.566 4.36.081-.191 1-2.945 1.566-4.36L101.356.903h5.7L96.942 25.858h-5.823Z"
      fill="#116967"
    />
    <path
      d="M115.577.904h5.54l11.325 24.916h-5.7l-1.767-3.94h-13.213l-1.767 3.94h-5.743Zm7.63 16.807-3.012-7.076c-.8-1.874-1.807-4.361-1.847-4.437-.04.076-1.044 2.563-1.848 4.437l-3.012 7.076Z"
      fill="#116967"
    />
    <path d="M135.417.903h5.623V21.23h15.062v4.59h-20.683Z" fill="#116967" />
    <path
      d="m169.715 22.875 2.048-4.131a13.923 13.923 0 0 0 8.554 2.716c2.169 0 4.819-.956 4.819-2.907 0-1.912-2.329-2.41-4.618-2.945-4.859-1.071-9.88-2.639-9.88-7.8 0-4.781 4.7-7.153 9.679-7.153a17.068 17.068 0 0 1 9.518 2.639l-2.048 4.131a14.471 14.471 0 0 0-7.47-1.989c-1.366 0-3.936.5-3.936 2.372 0 1.606 1.887 2.18 4.458 2.754C185.458 11.595 191 13.24 191 18.556c0 5.355-5.462 7.688-10.683 7.688-6.225 0-9.157-2.257-10.6-3.366"
      fill="#116967"
    />
    <path
      d="M26.124 29.97c49.9-2.2 99.242-1 149.087 1.794 4.936.3 10.554.634 15.476.994-6.634-.252-13.993-.442-20.664-.586-32.589-.675-65.543-.527-98.117.687-4.206.139-12.493.557-16.753.741-4.245.243-9.9.579-14.158.827-3.668.239-11.059.857-14.772 1.12l-.1-5.575"
      fill="#116967"
    />
    <path d="m159.915 10.762 5.622-3.118v18.128h-5.622Z" fill="#116967" />
    <path d="M159.915.864h5.622v3.6l-5.622 2.988Z" fill="#f79f00" />
  </svg>
);

export default ArvalisLogo;
