import React from 'react';

const BayerLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 633.33333 633.33333"
      {...props} // This allows you to pass additional props like className, style, etc.
    >
      <style type="text/css">
        {`
          .st0 { fill: #10384F; }
          .st1 { fill: #89D329; }
          .st2 { fill: #00BCFF; }
        `}
      </style>
      <g id="g43">
        <g id="g975">
          <path
            style={{ fill: '#89d329', strokeWidth: 8.33333302 }}
            d="M 632.5,296.66667 C 622.5,131.66667 485,0 316.66667,0 148.33333,0 10.833333,131.66667 0.83333329,296.66667 c 0,6.66666 0.83333331,13.33333 1.66666671,20 C 9.1666666,371.66667 30,422.5 61.666666,465 119.16667,543.33333 211.66667,594.16666 316.66667,594.16666 170,594.16666 50,480 40,336.66667 c -0.833334,-6.66667 -0.833334,-13.33334 -0.833334,-20 0,-6.66667 0,-13.33334 0.833334,-20 C 50,153.33333 170,39.166667 316.66667,39.166667 c 104.99999,0 197.49999,50.833333 254.99999,129.166663 31.66667,42.5 52.5,93.33334 59.16667,148.33334 0.83333,6.66666 1.66667,13.33333 1.66667,19.16666 0,-6.66666 0.83333,-13.33333 0.83333,-20 0,-5.83333 0,-12.5 -0.83333,-19.16666"
            className="st1"
          />
          <path
            style={{ fill: '#00bcff', strokeWidth: 8.33333302 }}
            d="M 0.83333329,336.66667 C 10.833333,501.66666 148.33333,633.33333 316.66667,633.33333 485,633.33333 622.5,501.66666 632.5,336.66667 c 0,-6.66667 -0.83334,-13.33334 -1.66667,-20 -6.66667,-55 -27.5,-105.83334 -59.16667,-148.33334 -57.5,-78.33333 -150,-129.166663 -254.99999,-129.166663 146.66666,0 266.66666,114.166663 276.66666,257.500003 0.83333,6.66666 0.83333,13.33333 0.83333,20 0,6.66666 0,13.33333 -0.83333,20 -10,144.16666 -130,257.49999 -276.66666,257.49999 -105,0 -197.5,-50.83333 -255.000004,-129.16666 C 30,422.5 9.1666666,371.66667 2.5,316.66667 1.6666666,310 0.83333329,303.33333 0.83333329,297.5 c 0,6.66667 -0.83333333,13.33333 -0.83333333,20 0,5.83333 0,12.5 0.83333333,19.16667"
            className="st2"
          />
        </g>
        <g id="g28">
          <path
            style={{ fill: '#10384f', strokeWidth: 13.15789413 }}
            d="M 438.1582,96.052734 V 234.21094 h 96.05274 c 23.68421,0 42.10547,-18.42126 42.10547,-42.10547 0,-11.84211 -5.26347,-22.36894 -13.15821,-30.26367 5.26316,-6.57895 9.20961,-15.78948 10.52539,-25 0,-22.36842 -18.42063,-40.789066 -40.78906,-40.789066 z M 469.73633,125 h 57.89453 c 6.57894,0 11.84375,5.26285 11.84375,11.8418 0,6.57894 -5.26481,11.84179 -11.84375,11.84179 h -57.89453 z m 0,52.63086 h 59.21094 c 7.89473,0 13.1582,5.26347 13.1582,13.1582 0,7.89474 -5.26347,13.15821 -13.1582,13.15821 h -59.21094 z"
            transform="scale(0.63333333)"
          />
          <path
            style={{ fill: '#10384f', strokeWidth: 13.15789413 }}
            d="m 484.21094,260.52539 -68.42188,138.1582 h 35.52735 L 461.8418,375 h 77.63281 L 550,398.68359 h 35.52539 L 515.78906,260.52539 Z M 500,296.05273 l 23.68359,50 h -47.36718 z"
            transform="scale(0.63333333)"
          />
          <path
            style={{ fill: '#10384f', strokeWidth: 8.33333302 }}
            d="m 346.66667,271.66667 h 25 L 327.5,328.33333 v 30.83334 H 306.66667 V 328.33333 L 262.5,271.66667 h 25 l 30,40 z"
          />
          <path
            style={{ fill: '#10384f', fillOpacity: 1, strokeWidth: 13.15789413 }}
            d="m 773.68359,428.94727 v 138.1582 h 32.89453 v -52.63086 h 25 l 39.47461,52.63086 h 39.47266 L 869.73633,513.1582 C 886.84159,507.89505 900,492.10598 900,472.36914 c 0,-23.68421 -18.42126,-43.42187 -42.10547,-43.42187 z m 34.21094,28.94726 h 47.36914 c 6.57895,0 13.15821,6.57927 13.15821,13.1582 0,6.57896 -5.26347,13.15821 -13.15821,13.15821 h -47.36914 z"
            transform="scale(0.63333333)"
          />
          <path
            style={{ fill: '#10384f', strokeWidth: 8.33333302 }}
            d="m 64.166666,271.66647 v 87.5002 h 60.833404 c 15,0 26.6668,-11.6668 26.6668,-26.6668 0,-7.50001 -3.33353,-14.167 -8.33354,-19.167 3.33334,-4.16666 5.83276,-10 6.66609,-15.83333 0,-14.16666 -11.6664,-25.83307 -25.83308,-25.83307 z m 19.999482,18.33326 h 36.666542 c 4.16666,0 7.50104,3.33314 7.50104,7.49981 0,4.16666 -3.33438,7.4998 -7.50104,7.4998 H 84.166148 Z m 0,33.33288 h 37.500262 c 5,0 8.33353,3.33353 8.33353,8.33353 0,5 -3.33353,8.33353 -8.33353,8.33353 H 84.166148 Z"
          />
          <path
            style={{ fill: '#10384f', strokeWidth: 8.33333302 }}
            d="m 207.50079,271.66661 -43.33386,87.50019 h 22.50065 l 6.66608,-14.9996 h 49.16745 l 6.66608,14.9996 h 22.49942 l -44.16635,-87.50019 z m 9.99973,22.50065 14.99961,31.66666 h -29.99921 z"
          />
          <path
            style={{ fill: '#10384f', fillOpacity: 1, strokeWidth: 8.25509262 }}
            d="m 462.50001,271.66667 v 18.80846 h -58.33335 v 14.71966 h 56.66667 v 18.80845 h -56.66667 v 16.35517 h 58.33335 v 18.80846 h -79.16668 v -87.5002 z"
          />
          <path
            style={{ fill: '#10384f', fillOpacity: 1, strokeWidth: 8.33333302 }}
            d="m 276.66647,486.66719 v 87.50019 h 20.8332 V 540.8345 h 15.83334 l 25.00058,33.33288 h 24.99936 l -25.83308,-34.1666 c 10.83334,-3.33333 19.167,-13.33308 19.167,-25.83307 0,-15 -11.6668,-27.50052 -26.6668,-27.50052 z m 21.66693,18.33326 h 30.00046 c 4.16666,0 8.33353,4.16687 8.33353,8.33353 0,4.16667 -3.33353,8.33353 -8.33353,8.33353 H 298.3334 Z"
          />
          <path
            style={{ fill: '#10384f', fillOpacity: 1, strokeWidth: 8.25509262 }}
            d="m 356.66668,378.13855 v 18.80846 h -58.33335 v 14.71966 H 355 v 18.80845 h -56.66667 v 16.35517 h 58.33335 v 18.80846 H 277.5 v -87.5002 z"
          />
        </g>
      </g>
    </svg>
  );
};

export default BayerLogo;
