import { Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';

const VarietiesSelect = ({ onChange, value, varieties, className }) => {
  return (
    <Autocomplete
      className={className}
      value={value}
      onChange={onChange}
      options={varieties.map((x) => x.variety_name)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('FIELD.VARIETY') + '*'}
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default VarietiesSelect;
