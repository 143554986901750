import createClient, { Middleware } from 'openapi-fetch';
import type { paths, components } from './schema';

const client = createClient<paths>({ baseUrl: '/api/' });

const getAuthToken = (): string | null => {
  const token = localStorage.getItem('token');
  return token ? JSON.parse(token) : null;
};

const middleware: Middleware = {
  async onRequest({ request }) {
    request.headers.set('Content-Type', 'application/json');
    request.headers.set('x-api-key', 'PreDimalAvamME5uQNXLWIw190');
    request.headers.set('authorizationToken', `Bearer ${getAuthToken()}`);
    return request;
  },
};

client.use(middleware);

export default client;

export type schemas = components['schemas'];
