import { t } from 'i18next';
import { Button, Spinner } from '@tcc/labz-design-system';
import FieldView from '../../../images/fieldview.png';

const classNames = {
  button: 'flex items-center gap-1',
  spinner: 'ml-4 w-5',
};

const ImportFieldviewDataButton = ({ onClick, loading = false }) => {
  return (
    <Button onClick={onClick} className={classNames.button}>
      {t('GROWER.IMPORT_DATA')}

      <img src={FieldView} alt="fieldview" />
      {loading && <Spinner className={classNames.spinner} />}
    </Button>
  );
};

export default ImportFieldviewDataButton;
