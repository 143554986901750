import { t } from 'i18next';
import React from 'react';

const classNames = {
  container: 'flex flex-col w-[40%] justify-center',
  list: 'list-none p-0 m-0 space-y-2',
  listItem: 'flex items-start',
  asterisk: 'text-red-500 mr-2 flex-shrink-0',
  text: 'text-xs text-text-1 font-light',
};

const GrowerReferences = () => {
  const referenceItems = ['GROWER.TYPE', 'GROWER.MAX_FIELD_SIZE'];

  return (
    <div className={classNames.container}>
      <ul className={classNames.list}>
        {referenceItems.map((item, index) => (
          <li key={index} className={classNames.listItem}>
            <span className={classNames.asterisk}>*</span>
            <span className={classNames.text}>{t(item)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GrowerReferences;
