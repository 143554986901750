import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoginAPI from '../../api/login';
import usePasswordResetForm from './usePasswordResetForm';
import { toast } from '@tcc/labz-design-system';

const usePasswordReset = () => {
  const { t } = useTranslation();
  const [resetStatus, setResetStatus] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const submit = async (data) => {
    if (data.email !== '') {
      const userCredentials = {
        loginID: data.email || getValues('email'),
      };

      try {
        const response = await LoginAPI.resetPassword(userCredentials);
        if (response?.statusCode === 200) {
          setResetStatus(true);
        } else {
          toast.error(t('ALERT.MSG16'));
        }
      } catch (err) {
        toast.error(t('ALERT.MSG16'));
      }
    }
  };

  const resubmit = async () => {
    const userCredentials = {
      loginID: getValues('email'),
    };

    try {
      const response = await LoginAPI.resetPassword(userCredentials);
      if (response?.statusCode === 200) {
        toast.success(t('ALERT.MSG17'));
      }
    } catch (err) {
      toast.error(t('ALERT.MSG17'));
    }
  };

  const form = usePasswordResetForm(submit);

  return {
    form,
    resetStatus,
    setResetStatus,
    register,
    handleSubmit,
    errors,
    submit,
    resubmit,
  };
};

export default usePasswordReset;
