import { useState } from 'react';
import useBoolean from './useBoolean';
import GrowerAPI from '../api/grower';
import dayjs from 'dayjs';
import { ISODateFormat } from '../utils/commonFunctions';

const useAgronomy = () => {
  const BBCHModal = useBoolean();
  const ActualSprayDateModal = useBoolean();
  const SearchBar = useBoolean();
  const InviteModal = useBoolean();
  const selectedField = useState<any>();
  const selectedTreatment = useState('');

  const [isUpdatingObservationDate, setIsUpdatingObservationDate] = useState(false);
  const [isSettingActualSprayDate, setIsSettingActualSprayDate] = useState(false);
  const [isDeletingTreatments, setIsDeletingTreatments] = useState(false);

  const handleUpdateObservationDate = async ({ data, onSuccess = () => {}, onFail = () => {} }) => {
    const [{ field_id }] = selectedField;
    try {
      setIsUpdatingObservationDate(true);
      const response = await GrowerAPI.addObservationDate(field_id, {
        observation_date: dayjs(data.observation_date).format(ISODateFormat),
        spike_height: 9,
      });

      if (response.status === 200) {
        onSuccess();
      } else {
        onFail();
      }
    } catch (e) {
      onFail();
    } finally {
      setIsUpdatingObservationDate(false);
    }
  };

  const handleSetActualSprayDate = async ({
    data,
    t_window,
    onSuccess = () => {},
    onFail = () => {},
  }) => {
    const [{ field_id }] = selectedField;
    try {
      setIsSettingActualSprayDate(true);
      const response = await GrowerAPI.setActualSprayDate(field_id, {
        field_id: field_id,
        product: data.product,
        dose_rate: parseFloat(data.dose_rate.replaceAll(',', '.')),
        t_window,
        treatment_date: dayjs(data.treatment_date).format(ISODateFormat),
      });

      if (response.status === 200) {
        await onSuccess();
      } else {
        onFail();
      }
    } catch (e) {
      onFail();
    } finally {
      setIsSettingActualSprayDate(false);
    }
  };

  const handleDeleteTreatments = async ({ treatment, onSuccess = () => {}, onFail = () => {} }) => {
    try {
      setIsDeletingTreatments(true);

      const response = await GrowerAPI.deleteTreatments(treatment);

      if (response.status === 200) {
        await onSuccess();
      } else {
        onFail();
      }
    } catch (e) {
      onFail();
    } finally {
      setIsDeletingTreatments(false);
    }
  };

  return {
    selectedField,
    selectedTreatment,
    BBCHModal,
    ActualSprayDateModal,
    SearchBar,
    InviteModal,
    isUpdatingObservationDate,
    isSettingActualSprayDate,
    isDeletingTreatments,
    handleUpdateObservationDate,
    handleSetActualSprayDate,
    handleDeleteTreatments,
  };
};

export default useAgronomy;
