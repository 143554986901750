import axios from 'axios';
import { GIGYA_URL, API_KEY, SECRET, USER_KEY } from '../../utils/constants';
import {
  AccountSearchResponse,
  InitRegistrationResponse,
  RegisterProps,
  RegisterResponse,
  LoginProps,
  LoginResponse,
  GetJWTResponse,
  ResetPasswordResponse,
  DeleteAccountResponse,
} from './types';

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

const GigyaData = { apiKey: API_KEY, userKey: USER_KEY, secret: SECRET };

const Login = new (class {
  async initRegistration(): Promise<InitRegistrationResponse> {
    const r = await axios.post(`${GIGYA_URL}/accounts.initRegistration`, GigyaData, { headers });
    return r.data;
  }

  async register({ data, initToken }: RegisterProps): Promise<RegisterResponse> {
    const body = {
      ...GigyaData,
      profile: JSON.stringify({ firstName: data.firstName, lastName: data.lastName }),
      email: data.email,
      password: data.password,
      regToken: initToken,
      lang: data.language,
      preferences: JSON.stringify({ terms: { predima_disti_tool: { isConsentGranted: true } } }),
      finalizeRegistration: true,
    };

    const r = await axios.post(`${GIGYA_URL}/accounts.register`, body, { headers });
    return r.data;
  }

  loginUser = async ({ loginID, password }: LoginProps): Promise<LoginResponse> => {
    const body = {
      ...GigyaData,
      loginID,
      password,
    };

    const r = await axios.post(`${GIGYA_URL}/accounts.login`, body, { headers });
    return r.data;
  };

  async resetPassword({ loginID }: { loginID: string }): Promise<ResetPasswordResponse> {
    const body = {
      ...GigyaData,
      loginID,
    };

    const r = await axios.post(`${GIGYA_URL}/accounts.resetPassword`, body, { headers });

    return r.data;
  }

  async deleteAccount(UID: string): Promise<DeleteAccountResponse> {
    const body = {
      ...GigyaData,
      UID,
    };

    const r = await axios.post(`${GIGYA_URL}/accounts.deleteAccount`, body, { headers });
    return r.data;
  }

  async accountSearch(email: string): Promise<AccountSearchResponse> {
    const body = {
      ...GigyaData,
      query: `SELECT * FROM accounts where profile.email="${email}"`,
    };

    const r = await axios.post(`${GIGYA_URL}/accounts.search`, body, { headers });
    return r.data;
  }

  getJWT = async (UID: string): Promise<GetJWTResponse> => {
    const body = {
      ...GigyaData,
      targetUID: UID,
      expiration: 3600,
      fields: 'email',
    };

    const r = await axios.post(`${GIGYA_URL}/accounts.getJWT`, body, { headers });
    return r.data;
  };

  // invocation of this method seems broken
  async setAccountInfo(body) {
    const data = {
      ...GigyaData,
      ...body,
    };

    const r = await axios.post(`${GIGYA_URL}/accounts.setAccountInfo`, data, { headers });
    return r.data;
  }

  // never used - I believe it should be used in the Profile tab, to show the current values
  async getAccountInfo(UID) {
    const query = {
      ...GigyaData,
      UID,
    };

    const r = await axios.post(`${GIGYA_URL}/accounts.getAccountInfo`, query, { headers });
    return r.data;
  }
})();

export default Login;
