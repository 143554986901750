import { useState, useEffect, useCallback } from 'react';

type FetcherProps<T> = {
  fetchFunction: (params?: any) => Promise<T>;
  immediate?: boolean;
  params?: any; // Add params to allow passing parameters to the fetch function
  onSuccess?: (data: T) => void;
  onFail?: (error: string) => void;
};

function useFetch<T>({
  fetchFunction,
  immediate = false,
  params,
  onSuccess,
  onFail,
}: FetcherProps<T>) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(immediate);

  const fetchData = useCallback(
    async (overrideParams?: any) => {
      setLoading(true);
      setError(null);
      try {
        const finalParams = overrideParams !== undefined ? overrideParams : params;
        const result = await fetchFunction(finalParams); // Use finalParams to call the fetch function

        setData(result);
        if (onSuccess) {
          onSuccess(result);
        }
      } catch (err) {
        const errorMessage = err.message || 'An error occurred';
        setError(errorMessage);
        if (onFail) {
          onFail(errorMessage);
        }
      } finally {
        setLoading(false);
      }
    },
    [fetchFunction, onFail, onSuccess, params]
  );

  useEffect(() => {
    if (immediate) {
      fetchData();
    }
  }, [immediate, params, fetchData]); // Add params to the dependency array

  return [data, error, loading, fetchData] as [
    T | null,
    string | null,
    boolean,
    (overrideParams?: any) => Promise<void>,
  ];
}

export default useFetch;
