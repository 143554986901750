import React from 'react';

const PredimaLogo = ({ width = '1493', height = '1261', className = '' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1493 1261"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_1_2)">
      <path
        d="M642.579 519.912C642.579 546.64 620.909 568.309 594.181 568.309C567.453 568.309 545.784 546.64 545.784 519.912C545.784 493.183 567.453 471.515 594.181 471.515C620.909 471.515 642.579 493.183 642.579 519.912Z"
        fill="#26B9F1"
      />
      <path
        d="M222.971 760.525C222.971 787.256 201.301 808.923 174.572 808.923C147.841 808.923 126.175 787.256 126.175 760.525C126.175 733.797 147.841 712.128 174.572 712.128C201.301 712.128 222.971 733.797 222.971 760.525Z"
        fill="#EF296C"
      />
      <path
        d="M470.497 1003.98C470.497 1030.71 448.832 1052.38 422.1 1052.38C395.372 1052.38 373.703 1030.71 373.703 1003.98C373.703 977.248 395.372 955.581 422.1 955.581C448.832 955.581 470.497 977.248 470.497 1003.98Z"
        fill="#5DC233"
      />
      <path
        d="M793.408 337.515V143.476"
        stroke="#5DC233"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1206.64 337.515V143.476"
        stroke="#5DC233"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M793.408 527.283V764.935L1000.02 870.304V632.679L793.408 527.283Z"
        stroke="#5DC233"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M1206.64 527.283V764.935L1000.02 870.304V632.679L1206.64 527.283Z" fill="#EF296C" />
      <path
        d="M1206.64 527.283V764.935L1000.02 870.304V632.679L1206.64 527.283Z"
        stroke="#EF296C"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M793.408 767.884V1005.5L1000.02 1110.88V873.253L793.408 767.884Z"
        stroke="#5DC233"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M1206.64 767.884V1005.5L1000.02 1110.88V873.253L1206.64 767.884Z" fill="#5DC233" />
      <path
        d="M1206.64 767.884V1005.5L1000.02 1110.88V873.253L1206.64 767.884Z"
        stroke="#5DC233"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M1000.02 505.255C993.713 505.255 988.599 500.14 988.599 493.831V11.4267C988.599 5.12001 993.713 -1.87126e-05 1000.02 -1.87126e-05C1006.33 -1.87126e-05 1011.45 5.12001 1011.45 11.4267V493.831C1011.45 500.14 1006.33 505.255 1000.02 505.255Z"
        fill="#5DC233"
      />
      <path
        d="M896.707 337.515V63.8988"
        stroke="#5DC233"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1103.34 337.515V63.8988"
        stroke="#5DC233"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 760.617H781.633L1000.02 871.759L1218.65 760.585H1414.29"
        stroke="#EF296C"
        strokeWidth="25.6208"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M0 1001.19H781.633L1000.02 1112.33L1218.65 1001.16H1414.29"
        stroke="#5DC233"
        strokeWidth="25.6208"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M793.408 286.727V524.361L1000.02 629.728V392.087L793.408 286.727Z"
        stroke="#5DC233"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M1206.64 289.417V524.361L1000.02 629.728V394.777L1206.64 289.417Z" fill="#26B9F1" />
      <path
        d="M1206.64 289.417V524.361L1000.02 629.728V394.777L1206.64 289.417Z"
        stroke="#26B9F1"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M0 520.043H781.633L1000.02 631.184L1218.33 520.176H1414.29"
        stroke="#26B9F1"
        strokeWidth="25.6208"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M803.371 291.855L1000.02 393.085L1206.64 288.072"
        stroke="#5DC233"
        strokeWidth="22.8509"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="4289.92" height="1344.76" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PredimaLogo;
