import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
}
interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
}

const classNames = {
  modalOverlay: 'fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10',
  modalContainer: 'bg-white rounded-lg p-4 relative',
  header: 'flex justify-between items-center mb-2',
  title: 'font-semibold text-lg',
  closeButton: 'cursor-pointer',
  content: 'mt-2',
};

const CustomModal = ({ open, handleClose, title, children }: CustomModalProps) => {
  if (!open) return null;

  return (
    <div className={classNames.modalOverlay} onClick={handleClose}>
      <div className={classNames.modalContainer} onClick={(e) => e.stopPropagation()}>
        <div className={classNames.header}>
          <span className={classNames.title}>{title}</span>
          <CancelIcon className={classNames.closeButton} onClick={handleClose} fontSize="small" />
        </div>
        <div className={classNames.content}>{children}</div>
      </div>
    </div>
  );
};

export default CustomModal;
