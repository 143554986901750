import { Box, Paper, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { HtmlTooltip, StyledTableCell1 } from '../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import EmptyCellIndicator from './EmptyCellIndicator';

const style = {
  fontSize: 13,
  display: 'inline-block',
  maxWidth: '60%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: 3,
};

interface ProductMixTableProps {
  color: string;
  product?: string;
  dosage: string;
  total_area?: number;
}

const ProductMixTable: React.FC<ProductMixTableProps> = ({
  color,
  product,
  dosage,
  total_area,
}) => {
  const { t } = useTranslation();
  if (!product) return null;

  const productArray = product.split('/');
  const dosageArray = dosage.length > 0 ? dosage.split('/') : [];
  const area = dosageArray.map((x) => Number(x) * (total_area || 0));

  const headerStyles = { background: color, textAlign: 'center' };

  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        <Table sx={{ width: 250 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell1 sx={headerStyles} align="center">
                <Typography>{t('PRODUCT_DELIVERY.PRODUCT')}</Typography>
              </StyledTableCell1>
              <StyledTableCell1 sx={headerStyles} align="center">
                <Typography>{t('PRODUCT_DELIVERY.DOSAGE')}</Typography>
              </StyledTableCell1>
              <StyledTableCell1 sx={headerStyles} align="center">
                <Typography>{t('PRODUCT_DELIVERY.TOTAL')}</Typography>
              </StyledTableCell1>
            </TableRow>
          </TableHead>
          <TableBody>
            {productArray.map((product, index) => (
              <TableRow key={`${product}${index}`}>
                <StyledTableCell1 align="center">{product}</StyledTableCell1>
                <StyledTableCell1 align="center">{dosageArray[index]}</StyledTableCell1>
                <StyledTableCell1 align="center">
                  {area[index] ? area[index].toFixed(2) : '--'}
                </StyledTableCell1>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

interface ProductCellProps {
  rowData: {
    [key: string]: any;
    total_area?: number;
  };
  tWindow: 'T1' | 'T2' | 'T3';
}

const ProductCell: React.FC<ProductCellProps> = ({ rowData, tWindow }) => {
  const colors: Record<string, string> = {
    T1: '#02A8A8',
    T2: '#00A6E1',
    T3: '#01849D',
  };

  const product = rowData?.[`${tWindow}product`];

  if (!product) return <EmptyCellIndicator />;

  return (
    <HtmlTooltip
      title={
        <ProductMixTable
          color={colors[tWindow]}
          product={product}
          dosage={rowData?.[`${tWindow}dosage`]}
          total_area={rowData?.total_area}
        />
      }
    >
      <Typography sx={style}>{rowData?.[`${tWindow}product`]}</Typography>
    </HtmlTooltip>
  );
};

export default ProductCell;
