import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Menu, Box, TextField, Button } from '@mui/material';
import { DateComponent } from '../../utils/commonFunctions';

const CommentsFilter = ({ onClose, anchorEl, open, onApply = (filters) => {} }) => {
  const { t } = useTranslation();

  const form = useForm();

  const handleApply = () => {
    const data = form.getValues();
    onApply(data);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '1em',
          alignItems: 'center',
          gap: '1vw',
          flexDirection: 'column',
        }}
      >
        <FormProvider {...form}>
          <Controller
            name="keywords"
            control={form.control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                fullWidth
                id="outlined-required"
                label="Search by keywords"
              />
            )}
          />
          <DateComponent
            name="date"
            label={'By Date'}
            control={form.control}
            errors={form.formState.errors}
          />
          <Button onClick={handleApply} sx={{ width: 250, height: 30 }} variant="contained">
            {t('COMPLIANCE.COMMENTS_FILTER_APPLY')}
          </Button>
        </FormProvider>
      </Box>
    </Menu>
  );
};

export default CommentsFilter;
