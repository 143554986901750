import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GrowerAPI from '../../api/grower';
import { useStateContext } from '../../utils/stateContext';
import Addproduct from './AddProduct';
import PredimaTable from '../PredimaTable';
import { toast } from '@tcc/labz-design-system';

const ProductDelivery = () => {
  const { t } = useTranslation();
  const {
    getData,
    subscription: { productList },
  } = useStateContext();
  //const [searchOpen, setSearchOpen] = useState(false);
  const [openProduct, setOpenProduct] = useState(false);
  const [product, setProduct] = useState({});
  const [edit, setEdit] = useState(false);

  const calculateDosage = (array = '', total_area) => {
    if (array.length > 0) {
      return array
        .split('/')
        .map((x) => (x * total_area).toFixed(2))
        .join(' / ');
    }
    return null;
  };

  const handleProductCellClick = (rowData, tWindow) => {
    setOpenProduct(true);
    setProduct({
      id: rowData?.[`${tWindow}id`],
      pd_window: tWindow,
      order_id: rowData.order_id,
      sent: rowData?.[`${tWindow}sent`],
    });
  };

  const handleProductSendClick = (rowData, tWindow) => {
    const sent = Boolean(rowData?.[`${tWindow}sent`]);

    productSend({
      pd_window: rowData?.pd_window,
      product: rowData?.[`${tWindow}product`],
      dosage: rowData?.[`${tWindow}dosage`],
      order_id: rowData?.order_id,
      id: rowData?.[`${tWindow}id`],
      sent: !sent,
    });
  };

  const columnWidth2 = 'auto';

  const columns = [
    {
      name: 'created_user_name',
      label: t('HOME.SALES_REP_NAME'),
      width: '8%',
      color: '#0091DF',
      search: true,
      sticky: true,
      rowSpan: 2,
    },
    {
      name: 'grower_name',
      label: t('SUBSCRIPTION.GROWER_NAME'),
      width: '8%',
      color: '#0091DF',
      search: true,
      sticky: true,
      rowSpan: 2,
    },
    {
      name: 'farm_name',
      label: t('GENERIC.FARM'),
      width: '8%',
      color: '#0091DF',
      search: true,
      sticky: true,
      rowSpan: 2,
    },
    {
      name: 'total_area',
      label: t('DELIVERY.TOTAL_FARM'),
      width: '6%',
      color: '#0091DF',
      search: true,
      sticky: true,
      rowSpan: 2,
    },
    { name: 'T1', colSpan: 3, groupHeader: true, color: '#02A8A8' },
    { name: 'T2', colSpan: 3, groupHeader: true, color: '#00A6E1' },
    { name: 'T3', colSpan: 3, groupHeader: true, color: '#01849D' },
    {
      name: 'T1product',
      label: `${t('AGRONOMY.PRODUCT')}`,
      width: columnWidth2,
      color: '#02A8A8',
      search: true,
      group: true,
      tWindow: 'T1',
      product: true,
      onClick: (rowData) => handleProductCellClick(rowData, 'T1'),
    },
    {
      name: 'T1dosage',
      label: `${t('AGRONOMY.DOSAGE')} (L/HA)`,
      width: columnWidth2,
      color: '#02A8A8',
      search: true,
      group: true,
    },
    {
      name: 'T1total',
      label: `${'Total product'} (L)`,
      color: '#02A8A8',
      width: columnWidth2,
      search: false,
      group: true,
      tWindow: 'T1',
      productSend: true,
      onClick: (rowData) => handleProductSendClick(rowData, 'T1'),
      valueGetter: (rowData) =>
        rowData?.T1dosage ? calculateDosage(rowData.T1dosage, rowData?.total_area) : null,
    },
    {
      name: 'T2product',
      label: t('AGRONOMY.PRODUCT'),
      color: '#00A6E1',
      width: columnWidth2,
      search: true,
      group: true,
      tWindow: 'T2',
      product: true,
      onClick: (rowData) => handleProductCellClick(rowData, 'T2'),
    },
    {
      name: 'T2dosage',
      label: `${t('AGRONOMY.DOSAGE')} (L/HA)`,
      color: '#00A6E1',
      width: columnWidth2,
      search: true,
      group: true,
    },
    {
      name: 'T2total',
      label: `${'Total product'} (L)`,
      color: '#00A6E1',
      width: columnWidth2,
      search: false,
      group: true,
      tWindow: 'T2',
      productSend: true,
      onClick: (rowData, send) => handleProductSendClick(rowData, 'T2'),
      valueGetter: (rowData) =>
        rowData?.T2dosage ? calculateDosage(rowData.T2dosage, rowData?.total_area) : null,
    },
    {
      name: 'T3product',
      label: `${t('AGRONOMY.PRODUCT')}`,
      color: '#01849D',
      width: columnWidth2,
      search: true,
      group: true,
      tWindow: 'T3',
      product: true,
      onClick: (rowData) => handleProductCellClick(rowData, 'T3'),
    },
    {
      name: 'T3dosage',
      label: `${t('AGRONOMY.DOSAGE')} (L/HA)`,
      color: '#01849D',
      width: columnWidth2,
      search: true,
      group: true,
    },
    {
      name: 'T3total',
      label: `${'Total product'} (L)`,
      color: '#01849D',
      width: columnWidth2,
      search: false,
      group: true,
      tWindow: 'T3',
      productSend: true,
      onClick: (rowData, send) => handleProductSendClick(rowData, 'T3'),
      valueGetter: (rowData) =>
        rowData?.T3dosage ? calculateDosage(rowData.T3dosage, rowData?.total_area) : null,
    },
  ];

  const productSend = (body) => {
    GrowerAPI.addProduct(body).then((response) => {
      if (response.status === 200) {
        toast.success(`Product ${body?.sent ? 'sent' : 'unsent'} successfully`);
        setEdit(false);
        setTimeout(() => {
          getData();
        }, 2000);
      }
    });
  };

  return (
    <div className={'flex flex-col w-full'}>
      <PredimaTable showFilters={false} data={productList} columns={columns} selectable={false} />
      {product && (
        <Addproduct
          openProduct={openProduct}
          setOpenProduct={setOpenProduct}
          product={product}
          setProduct={setProduct}
          edit={edit}
          setEdit={setEdit}
        />
      )}
    </div>
  );
};
export default ProductDelivery;
